@import './product/index';
@import './planManagement/index';
@import './portfolio/index';
@import './orderList/index';
@import './analysisReport/index.scss';
.pinefolio-panel {
  height: calc(100vh - 160px);
}

.pinefolio {
  &-header {
    display: flex;
    justify-content: space-between;
    &__left {
    }
    &__right {
      display: flex;
      align-items: center;
    }
  }
  .iTooltip {
    width: 14px !important;
    height: 14px !important;
  }
}
