.notifications-modal-v2 {
  width: 468px;
  border-radius: 4px;

  .notifications-content-v2 {
    color: $light-color;
    .header {
      display: flex;
      position: relative;
      height: 32px;
      padding: 4px;
      justify-content: center;
      background-color: $dark2-color;
      color: $light-color;
      font-size: 16px;

      .right {
        position: absolute;
        right: 4px;
        margin: 4px;
        width: 16px;
        height: 16px;
        object-fit: contain;
        // background-color: #6d6e71;
      }

      .right:hover {
        cursor: pointer;
        background-color: $light-color;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 12px;
      align-items: center;
      gap: 12px;
      background-color: $dark1-color;
      transition: opacity 300ms ease-in-out;
      opacity: 1;

      img {
        margin-top: 12px;
        width: 80px;
        height: 80px;
      }

      .title {
        font-size: 16px;
      }

      .description {
        font-size: 13px;
        color: $light3-color;
        text-align: center;
      }
    }
    .action {
      display: flex;
      background-color: $dark1-color;
      gap: 12px;
      justify-content: center;

      .btn-primary {
        padding: 8px;
        border-radius: 4px;
        background: $dark-blue3;
        border-color: $dark-blue3;
      }

      .btn-primary:hover {
        background: $light-blue3;
        border-color: $light-blue3;
      }

      .btn-secondary {
        padding: 8px;
        border-radius: 4px;
        background: $dark3-color;
        border-color: $dark3-color;
        span {
          color: $light-color;
        }
      }

      .btn-secondary:hover {
        background: $light3-color;
        // border-color: $light3-color;
        span {
          color: $dark3-color;
        }
      }
    }

    .pagination {
      gap: 8px;
      display: flex;
      margin: 12px 12px 24px 12px;
      justify-content: center;

      .label {
        font-size: 13px;
        background: $dark3-color;
        color: $light-color;
        padding: 4px 6px;
        border-radius: 4px;
      }

      button {
        display: flex;
        border-radius: 4px;
        padding: 4px 6px;
        background: $dark3-color;
        border-color: $dark3-color;
        color: $light-color;
        align-items: center;
        span {
          width: 12px;
          height: 12px;
          object-fit: contain;
          background-color: $light-color;
        }
      }

      button:hover {
        background: $light3-color;
        // border-color: $light3-color;
        color: $dark3-color;
        span {
          background-color: $dark3-color;
        }
      }

      button:disabled,
      button[disabled] {
        cursor: not-allowed;
        background: var(--Opacity-Dark-3-20, rgba(59, 61, 64, 0.2));
        span {
          background-color: $dark3-color;
        }
      }
    }
  }
}

.notifications-modal {
  display: flex;
  flex-direction: column;
  width: 360px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;

    height: 40px;
    padding: 0 12px;
    border-bottom: 1px solid $dark2-color;

    .icon {
      padding: 4px;
      cursor: pointer;
      background-color: $light-color;
      &.iNone {
        cursor: default;
      }
    }
  }

  &-body {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .notifications {
    &-types {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      padding: 8px 16px;
      height: 44px;
      border-bottom: 1px solid $dark2-color;
    }

    &-type {
      padding: 4px 8px;
      height: 28px;
      border-radius: 14px;
      color: $light3-color;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      cursor: pointer;

      &.active {
        background-color: $active-color;
        color: $dark3-color;
      }
    }

    &-settings {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &-setting {
      display: flex;
      gap: 16px;
      flex-direction: column;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-alert-settings {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      height: 44px;

      &__body {
        padding: 16px;
        gap: 16px;
        height: calc(100vh - 188px);
        display: flex;
        flex-direction: column;

        .option-badges {
          display: flex;
          gap: 4px;
          flex-wrap: wrap;
          max-height: 160px;
          overflow-y: scroll;
        }

        .option-badge {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;

          background-color: $active-color;
          color: $dark-color;
          padding: 4px 8px;
          border-radius: 4px;
          font-weight: 500;

          .iClose {
            background-color: $dark-color;
            width: 12px;
            height: 12px;
          }
        }
      }

      &__footer {
        flex: 1;
        height: calc(100% + 50px);
        overflow-y: scroll;

        .settings-conditions-groups {
          display: flex;
          flex-direction: column;
        }

        .settings-conditions-group {
          padding-top: 16px;
          padding-bottom: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .settings-conditions {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      &__action {
        padding: 12px;
        .btn {
          height: 40px;
        }
      }
    }

    &-alert-setting {
      padding: 4px 8px;
      height: 28px;
      border-radius: 14px;
      color: $light3-color;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      cursor: pointer;
      position: relative;

      &.active {
        color: #397392;

        &::after {
          content: '';
          width: 60%;
          border-bottom: 2px solid #397392;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &-important {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      padding: 8px 16px;
      flex-shrink: 0;

      &__icon {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &--primary {
          background-color: rgba($color: $light-blue3, $alpha: 0.2);
        }

        &--danger {
          background-color: rgba($color: $down-color, $alpha: 0.2);
        }

        .iAlert {
          background-color: $down-color;
          width: 20px;
          height: 20px;
        }

        .iTrading {
          background-color: $light-blue3;
          transform: rotate(-45deg);
        }
      }

      &__description {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }

      &__actions {
        display: flex;
        align-items: center;
        gap: 8px;

        .circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $down-color;
        }
      }

      &-orders {
        border-bottom: 1px solid $dark2-color;
        min-height: 68px;
      }

      &-alerts {
        border-bottom: 3px solid $dark2-color;
        min-height: 72px;
      }
    }

    &-contents {
      flex-grow: 1;
      overflow-y: scroll;
      position: relative;
      // overflow-x: hidden;

      .ps__rail-x {
        display: none;
      }

      .ps__thumb-y {
        display: none !important;
        right: 0px;
      }

      // &:hover {
      //   overflow-y: scroll;
      // }

      &-items {
        display: flex;
        flex-direction: column;
      }

      &-item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        padding: 16px;
        padding-right: 0;
        border-bottom: 1px solid $dark2-color;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        &__tags {
          display: flex;
          gap: 8px;
        }

        &__tag {
          padding: 4px 12px;
          color: $light-color;
          background-color: $light-blue3;
          border-radius: 4px;
          font-weight: 500;
          font-size: 13px;

          &--primary {
            background-color: $dark-blue3;
            color: $light-color;
          }

          &--success {
            background-color: $up-color;
            color: $dark3-color;
          }

          &--danger {
            background-color: $down-color;
          }

          &--warning {
            background-color: $ref-color;
            color: $dark3-color;
          }

          &--secondary {
            background-color: $gray-color;
          }

          &--ceil {
            background-color: $ceil-color;
            color: $light-color;
          }
        }

        &__title {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $light-color;
        }

        &__desc {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: $light-color;
        }

        &__actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &--read {
          color: $gray-color !important;

          .notifications-contents-item {
            &__tag {
              background-color: rgba(
                $color: $gray-color,
                $alpha: 0.2
              ) !important;
              color: $gray-color !important;
            }

            &__title {
              color: $gray-color !important;
            }

            &__desc {
              color: $gray-color !important;
            }
          }
        }
      }

      .loading-animation {
        position: sticky;
        transform: translateX(-50%);
        left: 50%;
        top: 8px;
        padding: 2px 8px;
        background-color: rgba($color: $dark-color, $alpha: 0.4);
        border-radius: 8px;
      }
    }

    &-dropdown {
      position: relative;
      display: inline-block;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;

      &__action {
        cursor: pointer;
      }

      &__items {
        position: absolute;
        top: 24px;
        right: -4px;
        background-color: $dark-color;
        border: 1px solid $dark1-color;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(255, 255, 255, 0.038);
        z-index: 1000;
        width: 160px;
        display: none;

        &::after {
          position: absolute;
          top: -7px;
          right: 4px;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid $dark-color;
          content: '';
          display: block;
          z-index: 2;
          transition: all 200ms linear;
        }
      }

      &__item {
        padding: 8px 12px;
        cursor: pointer;
        text-align: start;
        width: auto;

        &.active {
          background-color: $dark-color;
          color: $light-color;
        }

        &:hover {
          background-color: $dark2-color;
          color: $light-color;
        }
      }
    }
  }

  .voc-management {
    transition: all 300ms linear;

    .voc-modal-body {
      height: calc(100vh - 80px);
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .voc-functions {
      display: flex;
      gap: 12px;
      padding: 12px;
      border-bottom: 2px solid $dark3-color;
    }

    .voc-function {
      display: flex;
      align-items: center;
      padding: 12px;
      gap: 12px;
      background-color: $dark2-color;
      border-radius: 6px;
    }

    .voc-faq {
      &--auth {
        max-height: 288px;
      }
    }

    .voc-inquiry {
      border-bottom: 2px solid $dark3-color;
      &--notauth {
        padding: 6px 12px;

        .btn {
          height: 40px;
          text-align: center;
        }
      }
    }

    .voc-items {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__header {
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $dark2-color;
        padding-right: 12px;
        padding-left: 12px;
      }

      &.expand {
        display: none;
      }
    }

    .voc-item {
      font-size: 13px;
      line-height: 20px;
      min-height: 44px;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;

      gap: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__header {
        display: flex;
        justify-content: space-between;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 6px;
        flex: 1;

        .text-truncate {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }

      &__actions {
        display: flex;
      }

      &:hover {
        background-color: $dark2-color;
      }
    }

    .voc-item:not(:last-child)::after {
      border-bottom: 1px solid $dark2-color;
      content: '';
      position: absolute;
      width: calc(100% - 24px);
      bottom: -6px;
    }

    .voc-detail {
      padding: 24px 12px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;

      &__items {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .logo {
          width: 40px;
          height: 40px;
          background-color: $white-color;
          border-radius: 50%;

          display: flex;
          justify-content: center;
          align-items: center;

          .iP {
            width: 15px;
            height: 22px;
            background-color: $dark-blue3 !important;
          }
        }
      }

      &__item {
        padding-top: 12px;
        padding-bottom: 12px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        position: relative;

        &:not(:last-child)::after {
          content: '';
          border-bottom: 1px solid $dark2-color;
          position: absolute;
          width: 100%;
          bottom: -6px;
        }
      }

      &__action {
        height: 36px;
        border-radius: 6px;
        background-color: $dark2-color;
        cursor: pointer;

        .iZalo {
          height: 24px;
          width: 24px;
        }

        &:hover {
          background-color: $dark3-color;
        }
      }
    }

    .voc-create {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      font-size: 13px;
      line-height: 20px;
      font-weight: 400;

      &__section {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .filter-control-select {
          &__control {
            width: 100%;
            background-color: $dark-color;
            height: 40px;
            min-height: 24px;
            outline: none;
            border: none;
            box-shadow: none;

            font-size: 13px;
            line-height: 36px;
          }

          &__menu {
            background-color: $dark-color;
            width: 100%;
            z-index: 200000000;
            position: absolute;
            margin-top: 5px;
          }

          &__single-value {
            font-size: 13px;
            padding: 2px;
            height: 40px;
            color: $light-color;
          }

          &__option {
            color: $light1-color;
            background-color: $dark-color;
            padding: 4px 8px;
            font-size: 13px;
            text-align: start;

            &--is-selected {
              color: #e2e2e3;
              background-color: $dark4-color;
            }

            &:hover {
              background-color: $dark3-color;
            }
          }

          &__value-container,
          &__indicators {
            height: 40px;
          }

          &__indicator-separator {
            display: none;
          }

          &__placeholder {
            width: 100%;
          }
        }

        .mult-select {
          &__control {
            width: 100%;
            background-color: $dark-color;
            outline: none;
            border: none;
            box-shadow: none;
          }

          &__menu {
            background-color: $dark-color;
            width: 100%;
            z-index: 200000000;
            position: absolute;
            margin-top: 5px;
          }

          &__option {
            color: $light1-color;
            background-color: $dark-color;
            padding: 4px 8px;
            font-size: 13px;
            text-align: start;

            &--is-selected {
              color: #e2e2e3;
              background-color: $dark4-color;
            }

            &:hover {
              background-color: $dark3-color;
            }
          }

          &__multi-value {
            height: 20px;
            background-color: $dark2-color;

            &__label {
              color: $light-color;
              line-height: 16px;
            }

            &__remove {
              background-color: $dark2-color;
              color: $light-color;
              cursor: pointer;

              &:hover {
                background-color: $dark3-color;
              }
            }
          }

          &__indicator-separator {
            display: none;
          }
        }

        textarea {
          background-color: $dark-color;
          border: none;
          outline: none;
          box-shadow: none;
          padding: 12px;
          border-radius: 4px;
          color: $light1-color;
        }

        .btn--primary {
          height: 40px;
        }
      }
    }
  }
}

.light {
  &.notifications-modal {
    .notifications-modal-header {
      border-bottom: 1px solid $light-color;
    }

    .notifications {
      &-types {
        border-bottom: 1px solid $light-color;
      }

      &-type {
        color: $gray-color;
        &.active {
          background-color: $active1-color;
          color: $dark3-color;
        }
      }

      &-important {
        &__icon {
          &--primary {
            background-color: rgba($color: $light-blue3, $alpha: 0.2);
          }

          &--danger {
            background-color: rgba($color: $down-color, $alpha: 0.2);
          }

          .iAlert {
            background-color: $down-color;
          }

          .iTrading {
            background-color: $light-blue3;
          }
        }

        &__actions {
          .circle {
            background-color: $down-color;
          }
        }

        &-orders {
          border-bottom: 1px solid $light-color;
        }

        &-alerts {
          border-bottom: 3px solid $light-color;
        }
      }

      &-contents {
        position: relative;
        &-item {
          border-bottom: 1px solid $light-color;

          &__tag {
            color: $dark3-color;
            background-color: $light-blue3;

            &--primary {
              background-color: $light-blue3;
              color: $light-color;
            }

            &--success {
              background-color: $up1-color;
            }

            &--danger {
              background-color: $down1-color;
              color: $light-color;
            }

            &--warning {
              background-color: $ref1-color;
            }

            &--secondary {
              background-color: $gray-color;
              color: $light-color;
            }

            &--ceil {
              background-color: $ceil1-color;
              color: $light-color;
            }
          }

          &__title {
            color: $dark3-color;
          }

          &__desc {
            color: $dark3-color;
          }
        }
      }

      &-alert-settings {
        &__body {
          .options-badge {
            background-color: $active1-color;
            color: $dark3-color;

            .iClose {
              background-color: $dark3-color;
            }
          }
        }
      }

      &-dropdown {
        &__items {
          background-color: $light-color;
          border: 1px solid $light1-color;
          box-shadow: 0 2px 10px rgba(255, 255, 255, 0.038);

          &::after {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $light-color;
          }
        }

        &__item {
          &.active {
            background-color: $light-color;
            color: $dark3-color;
          }

          &:hover {
            background-color: $light1-color;
            color: $dark3-color;
          }
        }
      }
    }

    .voc-management {
      height: calc(100vh - 40px);

      .voc-functions {
        border-bottom: 2px solid $light-color;
      }

      .voc-function {
        background-color: $light-color;
      }

      .voc-faq {
      }

      .voc-inquiry {
        border-bottom: 2px solid $light-color;
      }

      .voc-items {
        &__header {
          border-bottom: 1px solid $light-color;
        }
      }

      .voc-item {
        &:hover {
          background-color: $light-color;
        }
      }

      .voc-item:not(:last-child)::after {
        border-bottom: 1px solid $light-color;
      }

      .voc-detail {
        &__item {
          &:not(:last-child)::after {
            border-bottom: 1px solid $light-color;
          }
        }
      }

      .voc-create {
        &__section {
          .filter-control-select {
            &__control {
              background-color: $light-color;
            }

            &__menu {
              background-color: $light-color;
            }

            &__single-value {
              color: $dark-color;
            }

            &__option {
              color: $dark-color;
              background-color: $light-color;
              &--is-selected {
                color: $dark-color;
                background-color: $light1-color;
              }

              &:hover {
                background-color: $light2-color;
              }
            }
          }

          .mult-select {
            &__control {
              background-color: $light-color;
            }

            &__menu {
              background-color: $light-color;
            }

            &__option {
              color: $dark-color;
              background-color: $light-color;
              &--is-selected {
                color: $dark-color;
                background-color: $light1-color;
              }

              &:hover {
                background-color: $light2-color;
              }
            }

            &__multi-value {
              background-color: $light2-color;

              &__label {
                color: $dark-color;
              }

              &__remove {
                background-color: $light2-color;
                color: $dark3-color;

                &:hover {
                  background-color: $dark3-color;
                }
              }
            }
          }

          textarea {
            background-color: $light-color;
            color: $dark3-color;
          }
        }
      }
    }
  }
}
