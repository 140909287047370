.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-end;
  z-index: 111;

  &--right {
    justify-content: flex-start;
  }
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-end;
  z-index: 111;

  &--right {
    justify-content: flex-start;
  }
}

.react-modal-content {
  background-color: $dark1-color;
  color: $light-color;
  animation: slideLeft 300ms ease;
  height: 100%;
  border: none;
  border-radius: 0;

  &:focus {
    outline: 0;
  }

  // default is left side
  &--right {
    animation: slideRight 300ms ease !important;
  }

  a {
    color: $light-color;
  }
}

.mobile-menu {
  padding: 12px;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
    border-bottom: 1px solid $dark2-color;
  }

  .gap-4 {
    gap: 4px;
  }

  .gap-8 {
    gap: 8px;
  }

  a {
    color: $gray-color;
  }
}

.modal-user {
  height: calc(100% - 130px);
  padding: 12px;
  color: $light-color;
  @extend %text;

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 8px;
    background-color: $gray-color;
  }

  .btn {
    display: flex;
    height: 32px;
    padding: 2px 8px;
    align-items: center;
    font-weight: 500;
    .icon {
      margin-right: 8px;
    }
  }

  .iWarming {
    background-color: $down-color;
  }

  .text-desc {
    color: $light3-color;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .text-white {
    color: $light-color;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }

  &__header {
    display: flex;
    flex-direction: column;

    .user-infor {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      align-items: center;

      &__avatar {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background-color: $dark-color;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__desc {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .text-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: $light-color;
        }

        .text-desc {
          color: $light3-color;
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .authen-state {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 12px;

      &--unauthen {
        justify-content: center;
        height: 32px;
        border-radius: 32px;
        line-height: 24px;
      }

      &--authen {
        justify-content: center;
        height: 32px;
        border-radius: 32px;
        line-height: 24px;
      }
    }

    .sub-accounts {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .sub-account {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $gray-color;
      border-radius: 4px;

      &__title {
        height: 32px;
        padding: 0 8px;
        font-size: 13px;
        font-style: normal;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .radio-button {
          margin-right: 8px;
        }
      }

      &__actions {
        display: flex;
        align-items: center;
      }

      &.active {
        background-color: $dark3-color;
        color: $light-color;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    gap: 4px;

    .about {
      border-top: 1px solid $dark2-color;
      border-bottom: 1px solid $dark2-color;
    }

    .categories {
      display: flex;
      gap: 8px;
      padding: 6px 0px;
      flex-direction: column;
      border-top: 1px solid $dark2-color;
      .category {
        cursor: pointer;
        padding: 8px 0px 8px 8px;
        line-height: 16px;
        color: $light3-color;
        border-radius: 4px;

        a {
          color: $light3-color;
          text-decoration: none;
        }

        display: flex;
        align-items: center;
        color: $light-color;

        &:not(.category-account-booking):hover {
          background-color: $dark3-color;
          a {
            color: $light-color;
          }

          .icon {
            background-color: $light-color;
          }
        }

        .booking-status {
          .icon {
            background-color: $active-color !important;
            width: 16px;
            height: 16px;
          }
        }
      }

      .category-margin {
        padding: 4px 4px 4px 0;
        display: flex;
        gap: 8px;

        .icon {
          background-color: $light-color;
        }
        &:hover {
          background-color: unset !important;
        }
      }

      .category-account-booking {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 4px 4px 4px 0;
        border-radius: 4px;
        border: 1px solid $dark3-color;

        &__actions {
          .iClose {
            background-color: $down-color;
          }
        }

        &__infors {
          display: flex;
          gap: 8px;
          padding: 0 8px;
          align-items: center;
        }

        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .account-booking {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__type {
        }

        &__link {
          color: $light-blue3 !important;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
