@import '../../helpers/variables';

%action-btn {
  display: inline-block;
  text-align: right;
  flex: 1;
  font-size: 13px;
  line-height: 24px;

  .btn-action {
    cursor: pointer;
    padding: 4px 8px;
    color: $light2-color;
    background-color: $dark2-color;
    border-radius: 16px;

    &:hover {
      color: $light-color;
      background-color: $dark3-color;
      transition: all 0.15s ease-in-out;
    }
  }
}

%account-panel-items {
  .account-panel-items {
    padding-top: 12px;
    padding-bottom: 12px;

    & + .account-panel-items {
      border-top: 1px solid $dark2-color;
    }
  }
}

%account-panel-item {
  .account-panel-item {
    min-height: 24px;
    height: auto;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + .account-panel-item {
      margin-top: 12px;
    }

    &__name {
      color: $light3-color;
      flex: 2;
    }

    &__value {
      color: $light-color;
      flex: 3;
    }

    &__action {
      @extend %action-btn;

      .react-switch {
        vertical-align: middle;
      }
    }
  }
}

.address-change-modal {
  height: 236px;
  width: 348px;
}

.address-change-content {
  background-color: $dark1-color;
  color: $light-color;
  padding: 24px;
  gap: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  .order-text {
    background-color: $dark-color;
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 12px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    & + .order-text {
      margin-top: 12px;
    }

    input {
      height: 36px;
      padding: 12px;
      color: $light-color;
      background-color: $dark-color;
      border: none;
      outline: none;
      box-shadow: none;
      width: 100%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: $dark-color !important;
      color: $light-color !important;
      -webkit-box-shadow: 0 0 0 30px $dark-color inset !important;
      -webkit-text-fill-color: $light-color !important;
    }
  }

  .btn {
    height: 36px;
    width: 100%;
    padding: 8px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 4px;
  }

  .btn--disable {
  }

  .btn-submit {
    background-color: $dark-blue3;
    color: $light-color;
    margin-top: 24px;
  }

  .btn-lighter {
    background-color: $dark-blue3;
    color: $light-color;
    margin-top: 24px;
  }

  .btn-cancel {
    background-color: $dark3-color;
    color: $light-color;
    margin-top: 24px;
  }

  .btn-resend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    color: $light-color;
  }

  .desc {
    color: $light3-color;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }

  .help {
    color: $light3-color;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }

  a.text-highlight {
    color: $active-color;

    &:hover {
      font-weight: 600;
      text-decoration: none;
    }
  }

  .error {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-color: $light3-color;
  }

  .change-form {
    width: 100%;
  }

  .confirm-otp-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .order-text {
      width: 100%;
    }

    input {
      text-align: center;
      letter-spacing: 4px;
      font-size: 13px;
      line-height: 20px;
    }

    input::placeholder,
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        input:-ms-input-placeholder,
        /* Internet Explorer 10-11 */
        input::-ms-input-placeholder

        /* Microsoft Edge */ {
      letter-spacing: 2px;
    }
  }

  .personal-change-form {
    .btn {
      margin-top: 12px;
    }

    .btn-upload {
      display: flex;
      flex-direction: row;
      color: $light-blue3;
      background-color: $dark2-color;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .upload-desc {
      margin-top: 12px;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      display: block;
    }

    .file {
      .icon {
        background-color: $light-blue3;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $light-blue3;
      background-color: $dark2-color;
      gap: 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      margin-top: 12px;
      border-radius: 4px;
      height: 36px;

      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        width: 100%;
      }

      &--upload {
        background-color: rgba($light-blue3, 0.2);
        color: $light-blue3;
      }
    }

    input[type='file'] {
      display: none;
    }

    .desc {
      display: block;
      margin-top: 12px;
    }

    .profile-select {
      width: 100%;
      margin-left: 8px;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;

      &__control {
        display: flex;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        position: relative;
        box-shadow: none;
        min-height: 36px;

        &:hover {
          border-color: 'red';
        }
      }

      &__value-container {
        padding: 0px;
      }

      &__menu {
        background-color: $dark-color;
        width: 100%;
        border-top-left-radius: 5;
        border-top-right-radius: 5;
        z-index: 2;
        position: absolute;
        margin-top: -5px;

        /* Scroll Bar */
        &-list::-webkit-scrollbar {
          width: 4px;
          height: 0px;
        }

        &-list::-webkit-scrollbar-track {
          background: $dark4-color;
        }

        &-list::-webkit-scrollbar-thumb {
          background: $light4-color;
        }

        &-list::-webkit-scrollbar-thumb:hover {
          background: $light1-color;
        }
      }

      &__single-value {
        font-size: 13px;
        padding: 2px;
        color: $light-color;
      }

      &__option {
        color: $light1-color;
        background-color: $dark1-color;
        padding: 5px 8px;
        font-size: 13px;
        text-align: start;

        &--is-selected {
          color: #e2e2e3;
          background-color: $dark4-color;
        }

        &:hover {
          background-color: $dark3-color;
        }
      }

      &__input {
        input {
          color: $light-color;
          height: 28px;
        }
      }
    }
  }

  .authen-section {
    border: 1px solid $dark3-color;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 12px;
    font-size: 13px;
    line-height: 20px;

    .authen-type-switches {
      background-color: $dark-color;
      color: $light-color;
      border-radius: 20px;
      height: 36px;
      padding: 4px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      gap: 18px;

      .authen-type-switch {
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        padding: 4px 12px;
        border-radius: 16px;

        &.active {
          background-color: $active-color;
          color: $dark-color;
        }

        &:not(.active):hover {
          color: $active-color;
        }
      }
    }

    .order-text {
      input {
        text-align: center;
      }
    }

    .authen-input {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;

      input {
        width: 40px;
        height: 56px;
        padding: 8px;
        border-radius: 8px;
        border: none;
        outline: none;
        box-shadow: none;

        font-size: 28px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;

        background-color: $dark-color;
        color: $light-color;

        &:focus {
          border: 1px solid $gray-color;
        }
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      .matrix-section {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .matrix-label {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          height: 24px;
        }
      }

      .otp-section {
      }
    }
  }

  .confirm-btn-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;

    .btn {
      width: auto;
    }

    .btn-submit {
      margin-top: 0;
      width: 70%;
    }

    .btn-cancel {
      margin-top: 0;
      width: 30%;
    }
  }

  .personalID-review {
    width: 100%;
    text-align: start;

    @extend %account-panel-items;
    @extend %account-panel-item;

    .account-panel-item {
      & + .account-panel-item {
        margin-top: 4px;
        height: auto;
      }
    }

    .profile-image-section {
      margin-top: 24px;
    }

    .profile-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 96px;
        width: 128px;
      }

      .profile-image-desc {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }

      &--left {
        float: left;
      }

      &--right {
        float: right;
      }
    }
  }
}
