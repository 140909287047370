.depositToPortfolio-modal {
  .modal {
    &-header {
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .total-assets {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        gap: 12px;
        border-bottom: 1px dashed $dark3-color;

        .assets-item {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &__name {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
          }

          &__value {
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }

      .form-deposit {
        padding: 12px 0px;

        .input-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          &__label {
            font-size: 13px;
            width: 30%;
            font-weight: 400;
            line-height: 20px;
            padding: 6px 0px;
          }

          &__input {
            display: flex;
            gap: 8px;
            width: 70%;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            .input-text-search {
              input {
                width: 125px;
              }
            }

            .btn {
              min-width: 60px;
              max-width: 125px;
              display: flex;
              align-items: center;
              justify-content: center;
              &--amount {
                width: 125px;
              }
            }
          }
        }
      }

      .modal-body {
        &__icon {
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;
          }

          &.icon--success {
            .icon {
              background-color: rgba($color: $up1-color, $alpha: 1);
            }
          }

          &.icon--rebalance {
            .icon {
              background-color: $light-blue3;
            }
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .modal-body__title {
            font-size: 16px;
            text-align: center;
          }

          .modal-body__description {
            font-size: 13px;
            text-align: center;
          }

          .modal-body__rebalanceInfor {
            width: 100%;

            table {
              width: 100%;

              thead {
                tr {
                  td {
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 12px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    border-right: 12px solid $dark1-color;

                    .iIn {
                      background-color: $up-color;
                    }

                    .iOut {
                      background-color: $down-color;
                    }
                  }
                }
              }

              tbody {
                width: 100%;

                tr {
                  td {
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 400;
                    padding: 12px 0px;
                    border-top: 1px dashed $dark3-color;
                    border-right: 12px solid $dark1-color;
                  }
                }
              }
            }
          }
        }
      }
    }

    &--footer {
      border: none;
      background-color: $dark1-color;
      display: flex;
      gap: 8px;
      padding: 12px;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &--buy {
          width: 80%;
        }

        &--cancel {
          width: 20%;
        }
      }
    }
  }
}
.deposit-pinefolio-backdrop {
  z-index: 11112;
}
