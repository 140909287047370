.news-modal-backdrop {
}

.news-modal {
  .cbadge {
    display: inline-block;
    padding: 4px 12px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 4px;

    &-infor {
      background-color: $light-color;
      color: $dark-color;
    }
  }

  .event-detail {
    padding: 12px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
