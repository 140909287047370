@import '../assets/scss/helpers/variables';

.security-search {
  display: flex;
  align-items: center;
  background-color: $dark-color;
  color: $light-color;
  gap: 4px;
  border: none;
  border-radius: 24px;
  padding: 2px 8px;
  margin-left: 12px;
  margin-right: 12px;

  // font-size: 13px;
  // font-weight: 400;
  // line-height: 20px;

  &:focus-within {
    border: 1px solid $gray-color;
  }

  input {
    border: none;
    outline: none;
    background-color: transparent;
    color: $light-color;
    height: 24px;
    width: 110px;
    // text-transform: uppercase;

    &:focus {
      background-color: transparent;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $gray-color;
    }
  }
}
