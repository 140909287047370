@import '../assets/scss/helpers/variables';

.radio-button {
  width: 16px;
  height: 16px;
  background-color: $dark-color;
  border: 1px solid $dark3-color;
  border-radius: 50%;
  display: flex;
  padding: 4px;
  position: relative;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: $active-color;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }

  &.active {
    &::after {
      display: block;
    }
  }

  &--readonly {
    display: none;

    &.active {
      display: block;
      border: none;
      background-color: transparent;
      cursor: default;
    }
  }
}
