@import './accountProfile';
@import './marketIndex';
@import './transactionNotification';
@import './notificationPopup';
@import './competition';
@import './popupCustom';
@import './marginExtending';
@import './derivativeDetail';
@import './customModal';
@import './dc13';
@import './confirmModal';
@import './notificationModal';
@import './user';
@import './auth';
@import './bond';
@import './partnership';
@import './news';

.wts-modal {
  $self: &;
  display: flex;
  max-width: none;
  justify-content: center;

  &-backdrop {
    background-color: rgba($color: $black-color, $alpha: 1);
    height: calc(100% - 40px);
  }

  &-content {
    width: auto;
    background-color: $dark2-color;
    color: $light-color;

    &--xs2 {
      width: 348px;
    }

    &--xs {
      width: 352px;
    }

    &--sm {
      width: 428px;
    }

    &--sm2 {
      width: 468px;
    }

    &--sm3 {
      width: 552px;
    }

    &--md {
      width: 708px;
    }

    &--md2 {
      width: 848px;
    }

    &--lg {
      width: 856px;
    }

    &--lg2 {
      width: 948px;
    }

    &--lg3 {
      width: 1068px;
    }

    &--xl {
      width: 1284px;
    }

    &--xl2 {
      width: 1428px;
    }

    &--1\/5 {
      width: 20%;
    }

    &--2\/5 {
      width: 40%;
    }

    &--3\/5 {
      width: 60%;
    }

    &--4\/5 {
      width: 80%;
    }

    &--full {
      width: 100% !important;
      height: 100% !important;
    }

    &--auto {
      width: auto;
      height: auto;
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;

      .icon {
        width: 32px;
        height: 32px;
        &--r {
          background-color: $ref-color;
        }
      }

      &--circle {
        border-radius: 50%;
      }

      &--r {
        background-color: rgba($color: $ref-color, $alpha: 0.2);
      }

      &--d {
        background-color: rgba($color: $down-color, $alpha: 0.2);
      }

      &--i {
        background-color: rgba($color: $up-color, $alpha: 0.2);
      }

      &--c {
        background-color: rgba($color: $ceil-color, $alpha: 0.2);
      }

      &--f {
        background-color: rgba($color: $floor-color, $alpha: 0.2);
      }
    }
  }

  &-action {
    display: flex;
    justify-content: center;
    gap: 4px;

    .btn {
      height: 32px;
    }
  }

  &__header {
    padding: 4px;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $dark2-color;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .iClose {
      background: $light-color;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px 24px 12px;
    gap: 12px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &--boxShadow {
    box-shadow: $box-shadow;
    #{ $self }__header {
      width: calc(100% - 2px);
      margin: 1px;
    }
  }
}

.wts-modal2 {
  display: flex;
  flex-direction: column;
  @extend %text;

  &__header {
    height: 32px;
    background-color: $dark2-color;
    color: $light-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 4px;
    width: 100%;
    overflow: hidden;
  }

  &__body {
    padding: 12px;
  }

  .gap-2 {
    gap: 2px;
  }

  .gap-4 {
    gap: 4px;
  }

  .gap-8 {
    gap: 8px;
  }
}

.ReactModal__Overlay {
  z-index: 3;
}

.pinefolio-theme-detail-backdrop {
  z-index: 2;
}

.changeBank-modal,
.pinefolio-performance,
.pinefolio-buy,
.pinefolio-sell,
.pinefolio-open-account,
.pinefolio-rebalance-contract,
.auth-backdrop {
  &-backdrop {
    z-index: 11113;
  }
}

.confirm-order-modal,
.delete-order-modal,
.pinefolio-bond-modal {
  &-backdrop {
    z-index: 11114;
  }
}

.bond-demo,
.bond-detail,
.dwm-modal,
.bond-notification,
.open-account-booking-vc {
  &-backdrop {
    z-index: 11116;
  }
}

.pinefolio-order-confirm,
.login-modal,
.auth {
  &-backdrop {
    z-index: 11119;
  }
}

.z-index {
  &--lv1 {
    z-index: 11111;
  }

  &--lv2 {
    z-index: 11112;
  }

  &--lv3 {
    z-index: 11113;
  }

  &--lv4 {
    z-index: 11114;
  }

  &--lv5 {
    z-index: 11115;
  }

  &--lv6 {
    z-index: 11116;
  }

  &--lv7 {
    z-index: 11117;
  }

  &--lv8 {
    z-index: 11118;
  }

  &--lv9 {
    z-index: 11119;
  }
}
