.plan-modal {
  .pinefolio-buy {
    min-height: unset;
  }
  .buy-now-layout {
    background-color: $dark-color;
    padding: 0px;
    &__header {
      padding: 4px !important;
      background-color: $dark1-color;
      border: none !important;
    }
    &__body {
      padding: 0px 12px 12px 12px !important;
    }
  }
  .plan-modal-layout {
    .plan-modal {
      &__header {
        &--left {
        }
      }
      &--right {
      }
      &__body {
        &--left {
          gap: 0px;
          .pf-infor {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dashed $dark3-color;
            font-size: 13px;
            padding: 6px 0px;
          }
          .muti-select-box {
            padding: 12px 0px;
          }
        }
        &--right {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
          height: 100%;
          .reminder-block {
            display: flex;
            gap: 24px;
            flex-direction: column;
            width: 100%;
          }
          .bond-for-sell {
            border-bottom: 1px dashed $dark3-color;
            padding-bottom: 12px;
            .pf-infor {
              display: flex;
              justify-content: space-between;
              border-bottom: unset;
              font-size: 13px;
              padding: 6px 0px;
            }
          }
        }
      }
      &__footer {
        .cbb-box {
          width: 16px;
          height: 16px;
          min-width: 16px;
        }
      }
    }
  }

  .gap-4 {
    gap: 4px !important;
  }

  .gap-8 {
    gap: 8px !important;
  }

  .gap-12 {
    gap: 12px !important;
  }

  .gap-24 {
    gap: 24px !important;
  }

  .gap-48 {
    gap: 48px !important;
  }
}
