// deposite-withdraw confirm popup for margin (dwm)
.dwm-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  &-form {
    display: flex;
    gap: 12px;
    flex-direction: column;
    width: 100%;

    &-row {
      height: 32px;
      display: flex;
      align-items: center;

      > span,
      > input {
        flex: 1;
      }

      input {
        background-color: $dark-color;
        border: none;
        outline: none;
        height: 32px;
        color: $light-color;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        margin-left: -8px;
        &::placeholder {
          color: $gray-color;
        }
      }

      .text--value {
        padding: 6px 8px;
      }
    }

    button {
      height: 32px;
    }

    .btn--primary {
      width: 80%;
    }

    .btn--cancel {
      width: 20%;
    }
  }
}
