@import '../../assets/scss/helpers/variables';

.video__modal {
  z-index: 11112;
  .relatedVideos {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px;
    .relatedVideo {
      display: flex;
      height: 80px;
      gap: 12px;
      .relatedVideo__thumbnail {
        border-radius: 4px;
        display: flex;
        span {
          border-radius: 4px;
          background-color: $light1-color;
          background-size: contain;
        }
      }
    }
    .video__title {
      display: block; /* or inline-block */
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      text-align: justify;
      text-justify: inter-word;
      max-height: 120px;
      line-height: 20px;
    }
  }
  .btn--loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0px 16px 0px;
    text-decoration: underline;
    cursor: pointer;
  }
}
.news-container {
  height: calc(100vh - 128px);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 16px 12px;
  .card-panel {
    border-radius: 4px;

    .card-panel-header {
      &__title {
        height: 24px;
        border-radius: 16px !important;
        line-height: 20px;
        &:not(.active) {
          background-color: $dark2-color;
        }
        color: $light3-color;
        &.active {
          color: $light-color;
        }
      }
    }
  }

  .tabs {
    .card-panel-body {
      height: calc(100% - 28px) !important;
    }
  }
  .body-panel {
    height: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .card-panel-header {
      padding: 2px;
      align-items: center;
      &__right {
        flex-wrap: wrap;
      }
    }
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1;
      gap: 16px;
      width: calc((100vw - 56px) / 4);
      .left-top {
        flex: 1 1;
        height: calc(25% - 12px);
        .indicator-index {
          margin: 0px;
          padding: 0px;
          .market-index {
            padding: 0px;
            margin: 0px;
            .market-panel {
              min-width: none;
              gap: 0 !important;
            }
            .card-index-info-item {
              border-bottom: 1px solid $dark2-color;
            }
            .card-index-info {
              &__header {
                justify-content: flex-start;
                gap: 12px;
                .header__title {
                  padding: 2px 12px;
                  border-radius: 24px;
                  color: $light3-color;
                  &.active {
                    color: $light-color;
                  }
                  &:hover {
                    background-color: $dark1-color;
                  }
                }
              }
              .card-panel {
                &-header {
                  padding: 2px;
                  &__title {
                    height: 24px;
                    background-color: unset;
                    position: relative;
                    border-radius: 16px !important;
                    line-height: 20px;
                    &.active {
                      color: $light-color;
                      &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: calc(50% - 25px);
                        top: 24px;
                        width: 50px;
                        border-top: 1px solid $active-color;
                      }
                    }
                    &:not(.active) {
                      color: $light3-color;
                      &:hover {
                        background-color: $dark-color;
                      }
                    }
                  }
                }
              }
              &__body {
                font-size: 13px;
              }
            }
          }
        }
      }
      .left-bottom {
        flex: 3 1;
        height: calc(75% - 12px);
        .card-panel-body {
          display: flex;
          gap: 24px;
          border-radius: 4px;
          padding-bottom: 12px;
          .video-list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin: 12px;
            .video-container {
              display: flex;
              height: 80px;
              gap: 12px;
              .video__thumbnail {
                border-radius: 4px;
                display: flex;
                span {
                  border-radius: 4px;
                  background-color: $light1-color;
                  background-size: contain;
                }
              }
              .video__desc {
                display: flex;
                flex-direction: column;
                gap: 12px;
                .video__title {
                  display: block; /* or inline-block */
                  text-overflow: ellipsis;
                  word-wrap: break-word;
                  overflow: hidden;
                  max-height: 40px;
                  line-height: 20px;
                }
              }
            }
          }
          .brief-list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin: 12px;
            .brief-container {
              display: flex;
              gap: 12px;
              flex-direction: column;
              flex-wrap: wrap;

              .bief-content {
                display: flex;
                gap: 12px;
                width: 100%;
                justify-content: space-between;
                flex-wrap: wrap;
                .brief-item {
                  display: flex;
                  border-radius: 4px;
                  align-items: center;
                  // width: calc(50% - 6px);
                  width: 100%;

                  &.morning-brief {
                    color: $light-color;
                  }
                  &.market-brief {
                    color: $light-blue3;
                    background-color: rgba($color: $light-blue3, $alpha: 0.2);
                  }
                  .brief__img {
                    border-radius: 4px;
                    display: flex;
                    width: 25%;
                    background-size: cover;
                    span {
                      width: 100%;
                      min-height: 48px;
                      border-radius: 4px;
                      background-color: $light1-color;
                      background-size: cover;
                    }
                  }
                  .brief__title {
                    width: 50%;
                    padding: 4px 8px 4px 12px;
                    font-size: 13px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &--center {
      flex: 2 1;
      width: calc((100vw - 56px) / 2);
      .newsfeed-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        .news {
          display: flex;
          flex-direction: column;
          width: calc(33.3333% - 16px);
          &__img {
            height: 144px;
            background-color: $dark2-color;
            margin-bottom: 12px;
            border-radius: 4px;
            background-size: cover;
          }
          .content {
            &__title {
              line-height: 20px;
            }
            &__desc {
              text-align: justify;
              text-justify: inter-word;
              display: block; /* or inline-block */
              text-overflow: ellipsis;
              word-wrap: break-word;
              overflow: hidden;
              max-height: 60px;
              line-height: 20px;
              margin-top: 8px;
            }
          }
        }
      }
    }

    &--right {
      flex: 1 1;
      width: calc((100vw - 56px) / 4);

      .filter-datePicker {
        display: flex;
        justify-content: center;
        border-radius: 16px;
      }
    }

    .card-panel {
      margin: 0px;
      padding: 0px;
      &-header {
      }
    }
  }
  .input-text-search {
    input {
      width: 120px;
      background-color: $dark-color;
      border-radius: 16px;
      border: 1px solid $dark-color;
      color: $light-color;
      padding: 1px 12px 1px 28px;
      height: 24px;

      &:focus-visible {
        outline: none;
      }
    }

    span {
      position: absolute;
      margin-left: 8px;
    }
  }

  .filter-control-select {
    font-size: 13px;
    font-weight: 500;
    min-width: 150px;

    &__control {
      background-color: $dark-color;
      box-shadow: 1px 3px green;
      height: 24px;
      min-height: 24px;
      outline: none;
      border: none;
      box-shadow: none;
      border-radius: 24px;
    }

    &__menu {
      background-color: $dark1-color;
      width: 100%;
      border-top-left-radius: 5;
      border-top-right-radius: 5;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
    }

    &__single-value {
      font-size: 13px;
      padding: 2px;
      color: $light-color;
    }

    &__option {
      color: $light1-color;
      background-color: $dark1-color;
      padding: 5px 8px;
      font-size: 13px;
      text-align: start;

      &--is-selected {
        color: #e2e2e3;
        background-color: $dark4-color;
      }

      &:hover {
        background-color: $dark3-color;
      }
    }

    &__value-container,
    &__indicators {
      height: 24px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $light-color;
    }
  }

  .btn--loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.event-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .event-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .event-time {
      display: flex;
      gap: 4px;
      align-items: center;
    }
    .event__content {
      display: flex;
      flex-direction: column;
      background-color: $dark-color;
      gap: 6px;
      padding: 12px;
      border-radius: 4px;
      .content__title {
        display: flex;
        gap: 4px;
        align-items: center;
        .symbol {
          line-height: 20px;
          padding: 2px 8px;
          background-color: rgba($color: $light-color, $alpha: 0.2);
          border-radius: 4px;
          &:hover {
            background-color: rgba($color: $light-color, $alpha: 1);
            color: $dark-color !important;
          }
        }
      }
      .content__desc {
        line-height: 20px;
      }
    }
  }
}
