.sellPortfolio-modal {
  @extend %text;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: 620px;
  }

  .gap-4 {
    gap: 4px;
  }

  .order-type {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .order-type-switches {
    background-color: $dark-color;
    color: $light-color;
    border-radius: 20px;
    height: 36px;
    padding: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .order-type-switch {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      padding: 4px 12px;
      border-radius: 16px;
      min-width: 80px;
      text-align: center;
      &.active {
        background-color: $active-color;
        color: $dark-color;
      }

      &:not(.active):hover {
        color: $active-color;
      }
    }
  }

  .order-odd-price {
    display: flex;
    align-items: center;
  }

  .order-actions {
    display: flex;
    gap: 12px;

    .btn {
      height: 32px;
      line-height: 24px;
    }

    .btn--buy {
      flex: 1;

      &:hover {
        background-color: $up1-color;
      }
    }

    .btn--sell {
      flex: 1;

      &:hover {
        background-color: $down1-color;
      }
    }

    .btn--cancel {
      width: 20%;
    }
  }

  .order-action {
    display: flex;
    gap: 8px;

    .btn {
      height: 32px;
      line-height: 24px;
    }

    .btn--sell {
      width: 80%;
    }

    .btn--cancel {
      width: 20%;
    }
  }

  .order-summaries {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 150px;
  }

  .order-summary {
    display: flex;
    gap: 6px;
  }

  .odd-order {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .symbol-section {
      display: flex;
      justify-content: space-between;
    }

    .symbol-information {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    .symbol-price {
      display: flex;
      gap: 12px;

      &__current {
        display: flex;
        gap: 12px;
        align-items: center;
      }

      &__open {
        .icon {
          width: 8px;
          height: 8px;
        }

        .iFloor {
          background-color: $floor-color;
        }

        .iRef {
          background-color: $ref-color;
        }

        .iCeil {
          background-color: $ceil-color;
        }

        display: flex;
        gap: 12px;
        align-items: center;
      }
    }

    .price-list-step {
      height: 20px;
      display: flex;
      justify-content: space-between;
      color: $light1-color;
      line-height: 20px;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;

      + .price-list-step {
        margin-top: 3px;
      }

      &__buy {
        flex: 1;
        margin-right: 2px;
        padding: 4px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: black;
        position: relative;

        .value {
          height: 100%;
          background-color: rgba($color: $up-color, $alpha: 0.2);
          position: absolute;
          top: 0;
          right: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          -webkit-transition: width 0.6s ease-in-out;
          -moz-transition: width 0.6s ease-in-out;
          -o-transition: width 0.6s ease-in-out;
          transition: width 0.6s ease-in-out;
        }

        .price {
          position: absolute;
          z-index: 1;
          top: 1px;
          right: 4px;
        }
      }

      &__sell {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 2px;
        padding: 4px;
        border-radius: 4px;
        background-color: black;
        position: relative;

        .overlay-text {
          color: black;
          z-index: 100 !important;
          position: absolute;
          left: 4px;
          top: 1px;
        }

        .display-text {
          z-index: 102 !important;
          left: 4px;
          top: 1px;
        }

        .value {
          height: 100%;
          background-color: rgba($color: $down-color, $alpha: 0.2);
          position: absolute;
          top: 0;
          left: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          -webkit-transition: width 0.6s ease-in-out;
          -moz-transition: width 0.6s ease-in-out;
          -o-transition: width 0.6s ease-in-out;
          transition: width 0.6s ease-in-out;
        }

        .price {
          position: absolute;
          z-index: 1;
          top: 1px;
          left: 4px;
        }
      }
    }

    .price-list-total {
      position: relative;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 4px;
      color: $dark-color;
      margin-top: 12px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      gap: 2px;

      &__buy {
        text-align: left;
        background-color: $up-color;
        padding-left: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        -webkit-transition: width 0.6s ease-in-out;
        -moz-transition: width 0.6s ease-in-out;
        -o-transition: width 0.6s ease-in-out;
        transition: width 0.6s ease-in-out;
        height: 4px;
      }

      &__sell {
        text-align: right;
        background-color: $down-color;
        padding-left: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        -webkit-transition: width 0.6s ease-in-out;
        -moz-transition: width 0.6s ease-in-out;
        -o-transition: width 0.6s ease-in-out;
        transition: width 0.6s ease-in-out;
        height: 4px;
        flex: 1;
      }
    }

    .info-section {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .info-rows {
        display: flex;
        flex-direction: column;
      }

      .info-row {
        height: 32px;
        display: flex;
        align-items: center;

        &__key {
          flex: 1;
        }

        &__value {
          flex: 2;
        }
      }
    }

    .enter-section {
      display: flex;
      flex-direction: row;
      gap: 6px;
      height: 32px;

      .order-button-group {
        display: flex;
      }
    }

    .order-text {
      background-color: $dark-color;
      border-radius: 4px;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 8px;
      padding-right: 2px;
      flex: 1;

      input {
        height: 32px;
        padding: 8px;
        color: $light-color;
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        width: 100%;
        font-size: 13px;
      }

      .filter-control-select {
        font-size: 13px;
        font-weight: 500;
        width: 100%;

        &__control {
          background-color: $dark-color;
          height: 24px;
          min-height: 24px;
          outline: none;
          border: none;
          box-shadow: none;
        }

        &__menu {
          background-color: $dark-color;
          width: 100%;
          border-top-left-radius: 5;
          border-top-right-radius: 5;
          z-index: 2;
          position: absolute;
          margin-top: 5px;
        }

        &__single-value {
          font-size: 13px;
          padding: 2px;
          color: $light-color;
        }

        &__option {
          color: $light1-color;
          background-color: $dark-color;
          padding: 5px 8px;
          font-size: 13px;
          text-align: start;

          &--is-selected {
            color: #e2e2e3;
            background-color: $dark4-color;
          }

          &:hover {
            background-color: $dark3-color;
          }
        }

        &__value-container,
        &__indicators {
          height: 24px;
          padding: 0;
        }

        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: $light-color;
        }
      }
    }

    &__empty {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background-color: rgba($color: $gray-color, $alpha: 0.2);

      .icon {
        width: 32px;
        height: 32px;
      }
    }
  }
}
