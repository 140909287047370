@import '../../../assets/scss/helpers/variables';

.delete-order-modal {
  width: 350px;
}

.delete-order-modal-content {
  background-color: transparent;
  border: none;
  border-radius: none;
}

.delete-order {
  background-color: $dark3-color;
  &-header {
    height: 40px;
    background-color: $dark3-color;
    color: $light-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &-body {
    background-color: $dark1-color;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $light-color;

    &__infor {
      display: flex;
      gap: 20px;

      &-title {
        color: $light2-color;
      }

      &-value {
        color: $light-color;
      }
    }

    &__detail {
      background-color: $dark2-color;
      border-radius: 4px;
      border: none;
      height: 32px;
      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .order {
        padding: 8px 16px;

        & + .order {
          border-left: 1px solid $dark-color;
        }
      }

      .order-type {
        .cbadge {
          padding: 2px 10px;
          border-radius: 2px;
          width: 40px;

          &.i {
            background-color: $up-color;
            color: $dark-color !important;
          }

          &.d {
            background-color: $down-color;
            color: $light-color !important;
          }
        }
      }
    }

    &__action {
      button {
        & + button {
          margin-left: 4px;
        }

        color: $light-color;

        outline: none;
        border: none;
        box-shadow: none;
        border-radius: 4px;

        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        padding: 8px 16px;
      }

      .btn-confirm {
        background-color: $dark-blue3;
      }

      .btn-cancel {
        background-color: $gray-color;
      }
    }
  }
}
