.card-panel {
  min-height: 212px;
  z-index: 1;
  padding: 6px;
  background-color: $panel-bg-dark;

  font-size: 13px;
  font-style: normal;
  line-height: 16px;

  display: flex;
  flex-direction: column;

  &-header {
    padding: 4px;
    background-color: $dark2-color;
    font-size: 13px;
    line-height: 16px;
    border-radius: 4px 4px 0px 0px;
    height: 28px;
    display: flex;
    align-items: center;

    &__bound {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }

    &__left {
      align-items: stretch;
    }

    &__right {
      display: flex;
      align-content: stretch;
      align-items: center;
      margin-left: 1rem;
      gap: 4px;
    }

    &__title {
      background-color: $panel-bg-dark;
      color: $white-color;
      height: 20px;
      border-radius: 10px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 2px;
      padding-bottom: 2px;
      margin-right: 4px;
      text-align: center;
      font-weight: 500;

      &.active {
        color: $active-color;
      }

      &.inactive {
        color: $light4-color;
      }

      &:hover {
        color: $white-color;
        transition: all 200ms ease-in;
        cursor: pointer;
      }
    }

    &__input {
      background-color: $panel-bg-dark;
      color: $white-color;
      border-radius: 10px;
      box-shadow: none;
      border: none;
      padding: 2px 8px;
      outline: $active-color;
      margin-right: 4px;
      // max-width: 100px;
    }

    &__select {
      height: 20px;
      border-radius: 10px;
      border: none;
      color: $gray2-color;
      background: $panel-bg-dark;
      text-align: left;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 400;
      cursor: pointer;
      margin-right: 4px;
      outline: none;
    }

    &__label {
      color: #8a8b8d;
    }
  }

  &-body {
    align-items: stretch;
    height: calc(100% - 28px);
    border: 1px solid $dark2-color;
  }

  &:focus-visible {
    outline: none !important;
  }

  &--fullSize {
    height: calc(100% - 115px) !important;
    bottom: 45px !important;
    z-index: 11110 !important;
  }
}

.card-panel-2 {
  display: flex;
  flex-direction: column;
  background-color: $dark1-color;
  color: $light3-color;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;

  &__tab {
    height: 100%;
    padding: 2px 8px;
    border-radius: 24px;
    display: inline-block;
    cursor: pointer;

    & + & {
      margin-left: 16px;
    }

    &.active {
      background-color: $active-color;
      color: $dark3-color;
    }

    &:not(.active):hover {
      color: $light-color;
    }
  }

  &-header {
    font-weight: 500;
    background-color: $dark2-color;
    color: $light3-color;
    width: 100%;
    height: 40px;
    padding: 8px;
    display: flex;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &-body {
    padding: 0px 12px;
  }
}
