.pinefolio-change-plan-ticket {
  background-color: $dark-color !important;
  .pinefolio-order-confirm {
    &__left {
      width: 25%;
      flex: none;
      .header {
        border-bottom: 1px solid $dark3-color;
        padding-bottom: 12px;
      }
    }
    &__right {
      background-color: $dark1-color;
      width: 75%;
      max-width: 75%;
    }
  }
}
