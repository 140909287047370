.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 32px;
  width: 450px;
  /* border: 1px solid rgb(83, 90, 134); */
  background-color: #2f3134;
  font-weight: 300;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 250px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .stockSuggest {
    &__left {
      .stockSuggest {
        &__symbol {
          font-weight: 500;
        }
        &__exchange {
          font-weight: 500;
        }
      }
    }

    &__right {
      font-weight: 400;
    }
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #585a5d;
}

/* #region webkit-scroll */
/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: #1d2022;
}

/* Track */
::-webkit-scrollbar-track {
  display: block;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  right: 2px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  background-color: #555;
  border-radius: 6px;
  position: absolute;
  top: 0px;
  height: 85px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* # end region webkit-scroll */
