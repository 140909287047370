.open-account-booking-vc {
  &__header {
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    font-size: 13px;
    line-height: 16px;
    color: $light-color;
    background-color: $dark1-color;

    .bookingMargin-section {
      .mr-24 {
        margin-right: 24px;
      }

      .ml-24 {
        margin-left: 24px;
      }

      .disabled {
        background-color: rgba($color: $dark3-color, $alpha: 0.2) !important;
        color: $dark3-color;
      }

      .icon-disabled {
        background-color: rgba($color: $dark3-color, $alpha: 0.2) !important;
        color: $dark3-color;
      }

      .activated {
        background-color: $active-color !important;
        color: $dark3-color;
      }

      .btn {
        line-height: 20px;
        padding: 5px;
        width: 228px;
      }

      .section__content {
        display: flex;
        margin-bottom: 32px;
        gap: 6px;
        .date-item {
          display: flex;
          flex-direction: column;
          width: fit-content;
          align-items: center;
          .date-item {
            &__date {
              user-select: none;
              display: flex;
              width: 48px;
              height: 48px;
              background-color: rgba($light-color, 0.2);
              border-radius: 100%;
              justify-content: center;
              align-items: center;
              &:hover {
                color: $dark3-color;
                background-color: $light-color;
              }
            }
            &__day {
              margin-bottom: 4px;
              color: $gray-color;
            }
          }
        }
      }

      &.pickDate-section {
        .section__content {
          min-width: 626px;
          justify-content: space-between;
        }
      }

      &.pickTime-section {
        width: 50%;
        .section__content {
          flex-wrap: wrap;
          width: 100%;
          .timeFrame-item {
            display: flex;
            flex: 1 0 calc(20% - 12px);
            background-color: rgba($color: $gray-color, $alpha: 0.2);
            padding: 14px 12px;
            border-radius: 4px;
            max-width: calc(20% - 5px);
            .item-time {
              line-height: 20px;
              margin: auto;
              user-select: none;
            }
            &:hover {
              color: $dark3-color;
              background-color: $light-color;
            }
          }
        }
      }

      .section__title {
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }
}
