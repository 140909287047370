.pinefolio-order-confirm {
  @extend %text;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  min-height: 600px;
  max-height: 70vh;

  &__header {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    flex: 1;
    display: flex;
    gap: 12px;
    height: 60vh;
  }

  &__left {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__right {
    max-width: 60%;
    flex: 3;
  }

  &__actions {
    display: flex;
    gap: 12px;
    width: 100%;

    .btn {
      height: 32px;
    }

    .btn--buy,
    .btn--sell {
      flex: 1;
    }

    .btn--cancel {
      width: 20%;
    }
  }

  &__orders {
    display: flex;
    flex-direction: column;
  }

  &__order {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    border: 1px solid $dark2-color;
    border-top: none;
    cursor: pointer;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &--title {
      background-color: $dark2-color;
      justify-content: center;
      border-radius: 4px 4px 0 0;
    }

    &:not(.pinefolio-order-confirm__order--title):hover {
      background-color: $dark3-color;
    }
  }

  .checkbox-button {
    .cbb-box {
      min-width: 16px;
      height: 16px;

      &::after {
        width: 7px;
        height: 5px;
      }
    }

    .cbb-text {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .contract {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__name {
      color: $light-color;

      &.active {
        color: $active-color;
      }
    }

    &__actions {
      display: flex;
      gap: 16px;

      .i {
        background-color: $up-color;
      }
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}
