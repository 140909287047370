.add-customer-modal {
}

.change-fee-modal {
  width: 560px !important;

  &__header {
    background-color: $dark2-color !important;
    .iClose {
      background-color: $light-color !important;
      width: 24px;
      height: 24px;
    }
  }

  &__body {
    background-color: $dark1-color !important;
    padding: 0 !important;
    gap: 0 !important;
    align-items: start !important;

    .general-infor {
      padding: 12px;
      border-bottom: 1px solid $dark2-color;
    }

    .service-group {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .service-group:last-of-type {
      border-bottom: 1px solid $dark2-color;
    }

    .service-type {
      padding-top: 2px;
      padding-bottom: 2px;

      .filter-control-select {
        width: 135px;

        &__control {
          width: 100%;
          background-color: $dark-color;
          height: 24px;
          min-height: 24px;
          outline: none;
          border: none;
          box-shadow: none;

          font-size: 13px;
          line-height: 20px;
        }

        &__menu {
          background-color: $dark-color;
          width: 100%;
          z-index: 200000000;
          position: absolute;
          margin-top: 5px;
        }

        &__single-value {
          font-size: 13px;
          padding: 2px;
          height: 24px;
          color: $light-color;
        }

        &__option {
          color: $light1-color;
          background-color: $dark-color;
          padding: 4px 8px;
          font-size: 13px;
          text-align: start;

          &--is-selected {
            color: #e2e2e3;
            background-color: $dark4-color;
          }

          &:hover {
            background-color: $dark3-color;
          }
        }

        &__value-container,
        &__indicators {
          height: 24px;
        }

        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: $light-color;
        }
      }

      .group-control {
        display: flex;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: $dark-color;
        font-size: 13px;
        line-height: 20px;
        border-radius: 4px;

        input {
          color: $light-color;
          background-color: $dark-color;
          width: 100px;
          text-align: center;
          border: none;
          outline: none;
          box-shadow: none;
        }

        .icon {
          background-color: $light3-color;
        }

        .btn-icon:hover {
          .icon {
            background-color: $dark-color;
          }
        }
      }
    }

    .service-actions {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 12px;
    }

    .service-action {
      flex: 1;

      .btn {
        height: 32px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
      }

      .btn-danger-secondary {
        background-color: rgba($color: $down-color, $alpha: 0.2);
        color: $down-color;
      }
    }
  }
}

.light .change-fee-modal {
  background-color: $white-color !important;

  &__header {
    background-color: $light-color !important;
  }

  &__body {
    background-color: $white-color !important;

    .general-infor {
      border-bottom: 1px solid $light-color;
    }

    .service-type {
      .filter-control-select {
        &__control {
          background-color: $light-color;
        }

        &__menu {
          background-color: $light-color;
        }

        &__single-value {
          color: $dark3-color;
        }

        &__option {
          color: $dark3-color;
          background-color: $light-color;

          &--is-selected {
            color: $dark3-color;
            background-color: $light2-color;
          }

          &:hover {
            background-color: $light1-color;
          }
        }

        &__placeholder {
          color: $light3-color;
        }
      }
    }
  }
}
