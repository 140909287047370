.pinefolio-rebalance {
  @extend %text;

  .gap-4 {
    gap: 4px;
  }

  .gap-8 {
    gap: 8px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .iIn {
      background-color: $up-color;
    }

    .iOut {
      background-color: $down-color;
    }

    .rebalance-icon {
      display: flex;
      justify-content: center;
      background-color: gbra($light-blue3, 0.2);

      .iRebalance {
        width: 80px;
        height: 80px;

        background-color: $light-blue3;
      }
    }

    .rebalance-infor {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
    }

    .rebalance-content {
      display: flex;
      gap: 12px;
      margin-bottom: 12px;

      &__infors {
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex: 1;
      }

      &__infor {
        padding-top: 12px;

        & + .rebalance-content__infor {
          border-top: 1px dashed $dark3-color;
        }
      }

      &__symbol {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    .rebalance-actions {
      display: flex;
      gap: 12px;

      .btn {
        width: 100%;
        height: 32px;
      }

      .btn--cancel {
        width: 20%;
      }
    }
  }
}
