.layout-page {
  position: relative;
  &__footer {
    .note--star {
      display: flex;
      align-items: center;
      .icon {
        background-color: $active-color;
      }
    }
  }
}

.analysis-report {
  position: relative;
  height: 100%;
  padding: 12px;
  td,
  th {
    padding: 6px;
    line-height: 20px;
  }
  .icon--sm {
    width: 13px;
    height: 13px;
    object-fit: contain;
  }

  .icon--tiny {
    width: 8px;
    height: 8px;
    object-fit: contain;
  }

  .bg--active {
    background-color: $active-color !important;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    height: calc(100% - 64px);
    .video-section {
      width: 75%;
      display: flex;
      flex-wrap: wrap;
      .video-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        .video {
          flex-basis: calc(20% - 24px);
          display: flex;
          gap: 12px;
          flex-direction: column;
          min-width: 165px;
          &__thumbnail {
            padding-top: 56.25%;
            background-size: cover;
            border-radius: 4px;
          }
          &__title {
            display: -webkit-box;
            -webkit-line-clamp: 3; /* Number of lines to show */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
          }
          &__desc {
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: 13px;
            line-height: 20px;
            &--category {
              padding: 2px 8px;
              border-radius: 4px;
              display: block;
              background: rgba($light-blue3, 0.2);
              color: $light-blue3;
            }
          }
          div:last-of-type {
            margin-top: auto;
          }
        }
      }
    }
    .document-section {
      width: 25%;
      min-width: 348px;
      height: calc(100% - 44px);
    }
  }
  .note--star {
    width: 25%;
    min-width: 348px;
  }
}
