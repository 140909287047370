@import '../../helpers/variables';

.custom-modal {
  display: flex;
  gap: 12px;

  .popup-header {
    background-color: $dark2-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;

    &__title {
      text-align: center;
      padding: 4px 28px;
      font-size: 16px;
      width: 100%;
      color: $light-color;
    }

    .icon {
      background-color: $light-color;
    }
  }

  .popup-body {
    background-color: $dark1-color;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    padding: 12px 12px;

    &__icon {
      display: flex;
      width: 80px;
      height: 80px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;

      .icon {
        width: 32px;
        height: 32px;
      }

      &.icon--notificaton {
        background-color: rgba($color: $active-color, $alpha: 0.2);

        .icon {
          background-color: $active1-color;
        }
      }

      &.icon--warning {
        background-color: rgba($color: $down-color, $alpha: 0.2);

        .icon {
          background-color: $down-color;
        }
      }

      &.icon--success {
        background-color: rgba($color: $up-color, $alpha: 0.2);

        .icon {
          background-color: $up-color;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .popup-body__title {
        font-size: 16px;
        text-align: center;
      }

      .popup-body__description {
        font-size: 13px;
        text-align: center;
      }
    }
  }

  .popup-footer {
    &__button {
      display: flex;
      justify-content: center;
      gap: 12px;
      padding-bottom: 12px;
    }
  }
}
