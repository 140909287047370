@import '../helpers/variables';

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $bg-dark;
  @extend %text;

  .app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;

    .main {
      margin-left: 0px;
      width: 100%;
    }

    .sidebar {
      position: fixed;
      z-index: 1019;
      width: 00px;
      height: calc(100vh - 45px);
      display: flex;
      flex-direction: column;
      padding: 0;
      color: #fff;
      background: #2f353a;
    }

    .sidebar a.nav-link {
      color: #fff;
      position: relative;
    }
  }

  + .sidebar-lg-show {
    .app-body {
      .main {
        margin-left: 200px;
      }

      .sidebar {
        width: 200px;
      }
    }

    .app-footer {
      margin-left: 200px;
    }
  }

  .app-footer {
    margin-left: 0px;
  }
}

// .color-lightning,
// .app-footer .footer-menu span.active .lightning-fill {
//   color: #589dc0;
// }
