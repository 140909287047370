$bg-dark: #0d1214;
$panel-bg-dark: #1d2022;
$border-dark: #434648;

$active-color: #ffe70b;
$active1-color: #f0ae03;
$white-color: #ffffff;

// price color (dark)
$up-color: #00ff57;
$ref-color: #ffe70b;
$down-color: #ff233d;
$floor-color: #22f2ff;
$ceil-color: #da1ef9;

// price color (light)
$up1-color: #2db35b;
$ref1-color: #f0ae03;
$down1-color: #f15555;
$floor1-color: #11b5d9;
$ceil1-color: #aa3fff;

// color
$gray-color: #6d6e71;
$gray2-color: #c5c5c6;
$black-color: #0d1214;
$black2-color: #2f3134;

$light-color: #f2f2f3;
$light1-color: #e5e6e6;
$light2-color: #d8d9da;
$light3-color: #b3b4b5;
$light4-color: #8f9092;

$dark-color: #16191b;
$dark1-color: #1d2022;
$dark2-color: #242729;
$dark3-color: #3b3d40;
$dark4-color: #535558;

// sub color
$light-blue: #f8f9fa;
$light-blue1: #e1eaee;
$light-blue2: #afc6d3;
$light-blue3: #5f8ea7;
$light-blue4: #0068ff;

$dark-blue: #0b2838;
$dark-blue1: #0c374d;
$dark-blue2: #0d4664;
$dark-blue3: #0f557b;
$dark-blue4: #0068ff;

// text
$thead-color: #c5c5c6;

// authen color
$unAuthen-color: #e0a800;

// mq
$breakpoints: (
  'xs': 480px,
  'sm': 768px,
  'md': 1024px,
  'lg': 1200px,
  'xl': 1600px,
);

$box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
  /* 2. shadow ring 👇 */ 0 0 0 1px hsla(230, 13%, 9%, 0.075),
  /* 3. multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
  0 0.9px 1.5px hsla(230, 13%, 9%, 0.045), 0 3.5px 6px hsla(230, 13%, 9%, 0.09);
