.pinefolio-rebalance-contract {
  @extend %text;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px;
  height: 560px;

  &__title {
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  &__left {
    width: 40%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__right {
    width: 60%;
    background-color: $dark2-color;
  }

  &__header {
    flex: 1;
  }

  &__body {
    flex: 1;
  }

  &__actions {
    margin-top: 12px;
    display: flex;
    gap: 12px;
    width: 100%;

    .btn {
      height: 32px;
      line-height: 28px;
      cursor: pointer;
    }

    .btn--buy {
      width: 80%;
    }

    .btn--cancel {
      width: 20%;
    }
  }

  .ccb-text {
    font-size: 13px;
    line-height: 20px;
  }

  .cbb-box {
    min-width: 18px;
    height: 18px;

    &::after {
      width: 7px;
      height: 5px;
    }
  }
}
