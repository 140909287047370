@import '../../../../assets/scss/helpers/variables';

.section-overview {
  border-bottom: 1px solid $dark2-color;
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .overview-metric {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    &__key {
      font-size: 13px;
      font-weight: 400;
      font-style: normal;
      line-height: 20px;
      color: $light3-color;
    }

    &__value {
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 24px;
      color: $light-color;
    }
  }
}
