.market-index-modal {
  iframe {
    height: 100%;
  }

  &__header {
    background-color: $dark2-color;
    color: $light3-color;
    padding: 4px 8px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }

  &__body {
    height: 100%;
    padding-left: 4px;
    background-color: $dark2-color;
  }

  .icon {
    &:hover {
      background-color: $light-color;
    }
  }

  .iZoomIn,
  .iZoomOut {
    width: 13px;
    height: 13px;
  }

  .gap-8 {
    gap: 8px;
  }
}
