.pinefolio-bond-list {
  @extend %text;

  .pinefolio-bond-table {
    td {
      line-height: 20px;
    }
  }

  .investor-note {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 4px;
  }

  .icon--xs {
    height: 8px;
    width: 8px;
  }

  .icon--sm {
    height: 12px;
    width: 12px;
  }

  .iStar {
    background-color: $active-color;
  }
}
