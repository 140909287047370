.bond-detail {
  max-width: 1428px;
  font-size: 13px;
  background-color: $dark1-color;
  display: flex;
  flex-direction: row;

  &.light {
    background-color: $white-color;
  }

  // width: 100%;
  .bond-buy-order-container {
    display: flex;
    flex: 2 1;
  }

  .text-bigger {
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
  }

  .text-big {
    font-size: 16px;
    line-height: 24px;
    font-size: 500;
  }

  .text-light-3 {
    color: $light3-color;
  }

  .text-error {
    color: $down-color;
    font-size: 10px;
    line-height: 14px;
    float: left;
  }

  .text-blue-2 {
    color: $light-blue3;
  }

  .input-error {
    // border: 1px solid rgba($color: $down-color, $alpha: .5);
    box-shadow: 0 0 5px rgba($color: $down-color, $alpha: 1);
  }

  .font-weigh-bold {
    font-size: 700;
  }

  .bond-layout {
    &__header {
      display: flex;
    }

    &__body {
      display: flex;
      padding-top: 12px;
    }

    &__filters {
      display: flex;
      gap: 10px;
    }

    &__filter {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      &.button-group {
        flex: 3;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
      }

      input {
        height: 28px;
        background-color: $dark-color;
        border: none;
        outline: none;
        border-radius: 4px;
        color: $light-color;
        padding: 0 8px;
        width: 100%;

        &:disabled {
          background-color: $gray-color;
        }
      }

      .filter-control-select {
        font-size: 13px;
        font-weight: 500;

        &__control {
          background-color: $dark-color;
          outline: none;
          border: none;
          box-shadow: none;
        }

        &__menu {
          background-color: $dark-color;
          width: 100%;
          border-top-left-radius: 5;
          border-top-right-radius: 5;
          z-index: 2;
          position: absolute;
          margin-top: 5px;
        }

        &__single-value {
          font-size: 13px;
          padding: 2px;
          color: $light-color;
        }

        &__option {
          color: $light1-color;
          background-color: $dark-color;
          padding: 5px 8px;
          font-size: 13px;
          text-align: start;

          &--is-selected {
            color: #e2e2e3;
            background-color: $dark4-color;
          }

          &:hover {
            background-color: $dark3-color;
          }
        }
      }
    }
  }

  .modal-close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $dark-color;
    cursor: pointer;

    &:hover {
      background-color: $dark3-color;
    }
  }

  .flex-1 {
    flex: 1;
  }

  .w-32p {
    width: 32px;
  }

  .gap-8 {
    gap: 8px;
  }

  .iCheck {
    background-color: $up-color;
  }

  .checkbox-button {
    position: relative;
    display: flex;
    gap: 12px;
    cursor: pointer;
    padding: 12px 0;

    .cbb-box {
      background: transparent;
      border: 1px solid $gray-color;
      min-width: 24px;
      height: 24px;
      display: inline-block;
      border-radius: 4px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        border: 2px solid $up-color;
        border-top: 0;
        border-right: 0;
        background: 0;
        width: 10px;
        height: 7px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -80%) rotate(-45deg);
        display: none;
      }

      &.checked {
        &::after {
          display: block;
        }
      }
    }

    .cbb-text {
      color: $light-blue3;
    }
  }

  input {
    height: 32px;
    background-color: $dark-color;
    border: none;
    outline: none;
    border-radius: 4px;
    color: $light-color;
    padding: 0 8px;
    width: 100%;

    &:disabled {
      background-color: rgba($color: $dark-color, $alpha: 0.7);
      cursor: not-allowed;
    }
  }

  .filter-control-calendar {
    position: relative;
    display: flex;
    align-items: center;

    .react-datepicker-wrapper {
      width: 100%;
      position: relative;
    }

    .iCalendar {
      position: absolute;
      right: 8px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 12px;
  }

  table {
    tr {
      height: 32px;
    }

    td {
      padding: 6px 8px;
      line-height: 20px;
    }
  }

  .product-infor {
    margin-top: 12px;
  }

  .bond-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;

    .btn {
      width: 96px;
      height: 32px;
    }
  }

  .bond-detail-panel {
    @extend %text;
    flex: 1;
    padding: 12px;

    .bond-detail-body {
      box-sizing: border-box;
      align-items: stretch;
      align-content: stretch;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .bond-demo-overview {
        display: flex;
        width: 100%;
        height: 500px;
        .filter-control-select__control {
          width: 150px;
        }
        .dark-select {
          .filter-control-select__input {
            input {
              color: $light-color !important;
            }
          }
        }
        .light-select {
          .filter-control-select__input {
            input {
              color: $dark-color !important;
            }
          }
        }

        .bond-chart {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: calc(100% - 348px);
          padding-right: 12px;

          .chart-content {
            margin: 12px 0px 0px 0px;
            height: calc(100% - 98px);
            background-color: $dark3-color;
            border: 1px solid $dark2-color;
            border-radius: 4px;

            .highcharts-background {
              background-color: $dark-color;
              fill: $dark1-color;
            }

            .highcharts-axis {
              display: none;
            }

            .highcharts-xaxis-grid {
              .highcharts-grid-line {
                stroke-width: 1px;
                stroke: $dark3-color;
                stroke-dasharray: 4, 4;
              }
            }

            .highcharts-credits {
              display: none;
            }

            .highcharts-axis-labels {
              text {
                fill: $light-color !important;
              }
            }

            .highcharts-data-label {
              tspan {
                font-family: 'Roboto';
                stroke: none !important;
                font-size: 13px !important;
                line-height: 20px !important;
                font-weight: 400 !important;
              }
            }
          }

          .chart-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;

            .chart-title {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
        }

        .bond-money-flow {
          padding-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 348px;

          label {
            margin-right: 6px;
            color: $light3-color;
          }

          .row {
            margin: 0px;
            padding: 8px 0px;
            display: flex;
            justify-content: space-between;

            .btn--primary {
              width: 100%;
              height: 32px;
              border: none;
              border-radius: 4px;

              &:focus {
                outline: none;
              }
            }
          }

          .purchase-date {
            height: 32px;
            line-height: 32px;
            padding: 0px 8px;
            background-color: $dark1-color;
            border-radius: 4px;
          }

          .table {
            td {
              border: none;
            }

            label {
              margin: 4px 0px;
            }
          }
        }
      }

      .deal-money-flow {
        background-color: $dark2-color;
      }

      .bond-detai-moneyFlow {
        height: calc(100% - 60px);

        table {
          tr {
            td {
              border: none;
            }
          }
        }
      }

      .deal-for-sale {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 463px;
      }
    }
  }

  .bond-detail-order {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &__infor {
      padding: 12px;
      border: 1px solid $dark2-color;
      border-radius: 4px;
      width: 100%;
      height: 100%;

      .border-top-dashed {
        border-top: 1px dashed $dark4-color;
      }

      .border-bottom-dashed {
        border-bottom: 1px dashed $dark4-color;
      }

      .order-infor {
        display: flex;
        flex-direction: row;
        min-height: 32px;
        align-items: center;

        &__title {
          flex: 1;
          color: $light3-color;
          line-height: 32px;
        }

        &__desc {
          flex: 1;
          line-height: 32px;

          .min-quantity {
            position: absolute;
            right: 8px;
            margin: 0;
            color: $light3-color;
          }
        }

        .allocation-group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .allocation-radio {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
            cursor: pointer;

            label {
              margin-left: 8px;
              cursor: pointer;
            }
          }
        }
      }
    }

    &__orderPlace {
      margin-top: 12px;

      .asset-avaiable {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        span:first-child {
          text-align: start;
        }

        span:last-child {
          text-align: end;
        }

        & + .asset-avaiable {
          margin-top: 12px;
        }
      }

      .preview-money-flow {
        height: 32px;
        width: 100%;
        margin-top: 12px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .btn--primary {
          width: 100%;
          height: 32px;
        }
      }

      .order-actions {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        height: 32px;

        .btn {
          &--cancel {
            width: 96px;
          }

          &--buy {
            width: 100%;
          }

          &--sell {
            width: 100%;
          }
        }
      }
    }
  }

  .bond-contract {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 500px;
    max-height: calc(100vh - 200px);
    flex-direction: row;
    padding: 12px;
    gap: 12px;
    background-color: $dark1-color;

    .text-active {
      color: $active-color;
    }

    &-rec {
      background-color: $dark2-color;
      color: $light-color;
      padding: 12px 0;
      text-align: center;
      border-radius: 4px 4px 0 0;
    }

    &-infor {
      flex: 1;
      gap: 12px;
      min-width: 30%;
    }

    &-steps {
      display: flex;
      flex-direction: column;
      border: 1px solid $dark2-color;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &-step {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      padding: 12px;
      cursor: pointer;

      &:hover {
        background-color: $dark3-color;
      }

      & + .bond-contract-step {
        border-top: 1px solid $dark2-color;
      }

      &__actions {
        display: flex;
        gap: 17px;
      }
    }

    .confirm-term {
      display: flex;
    }

    &-preview {
      flex: 2;
      display: flex;
      flex-direction: column;
      max-width: 70%;

      &__title {
        height: 36px;
        color: $light-color;
        text-align: center;
        padding: 4px;
      }

      &__body {
        height: calc(100% - 36px);
        background-color: $dark2-color;
        border-radius: 4px;
        width: 100%;
        display: flex;
        justify-content: center;

        iframe {
          height: 100%;
          width: 100%;
        }
      }

      &__scroll {
        display: flex;
        justify-content: center;
        width: 100%;

        .pdf-page + .pdf-page {
          margin-top: 10px;
        }
      }
    }

    .order-actions {
      display: flex;
      gap: 12px;
      margin-top: 12px;
      height: 32px;

      .btn {
        font-weight: 600;
      }

      .btn--sell,
      .btn--buy {
        width: 100%;
      }

      .btn--cancel {
        width: 20%;
      }
    }
  }
}
