.margin-competition {
  .qa-layout {
    padding-bottom: 1rem;
  }

  .qa-page {
    display: flex;
    flex-direction: column;
    // text-align: center;
    padding: 1rem 15rem;

    &__content {
      display: block;
      width: 100%;
      height: 100%;
    }

    &__header {
      text-align: center;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__text {
      p {
        margin: 0;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.light {
  .qa-page {
    p {
      color: $gray-color;
    }
  }
}
