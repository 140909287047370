.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;

  .notification {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    background-color: $dark-blue3;
    transition: 0.3s ease;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 12px;
    margin-bottom: 6px;
    width: 300px;
    max-height: 200px;
    border-radius: 3px 3px 3px 3px;
    color: #000;
    opacity: 1;
    background-position: 15px;
    background-repeat: no-repeat;

    &:hover {
      opacity: 1;
    }

    .toast-body {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      padding: 0px;
      .toast-icon {
        .icon {
          width: 48px;
          height: 48px;
        }

        .iWarning {
          background-color: $down-color;
        }

        .iSuccess {
          background-color: $up-color;
        }

        .iFail {
          background-color: $down-color;
        }

        .iNotice {
          background-color: $active-color;
        }
      }

      .toast-content {
        display: flex;
        flex-direction: column;

        .toast-title {
          color: $light-color;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          margin-bottom: 4px;
        }

        .toast-description {
          color: $light1-color;
          font-size: 13px;
          line-height: 20px;
          text-align: left;
        }
      }
    }
    .toast-action {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .icon {
        cursor: pointer;
        width: 12px;
        height: 12px;
        background-color: $light1-color;
      }
      .btn {
        cursor: pointer;
        width: 58px;
        height: 32px;
        line-height: 28px;
        padding: 0;
      }
    }
  }

  .top-right {
    top: 28px;
    right: 6px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  .bottom-right {
    bottom: 12px;
    right: 6px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  .top-left {
    top: 28px;
    left: 6px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  .bottom-left {
    bottom: 12px;
    left: 6px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes toast-in-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
}
