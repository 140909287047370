@import '../../assets/scss/helpers/_variables.scss';

.stockSuggest {
  display: flex;

  & + .stockSuggest {
    border-top: 1px solid $dark1-color;
  }

  &__left {
    flex: 1;
    margin-right: 8px;
  }

  &__right {
    flex: 3;
    color: $light2-color;
    line-height: 1.5;
  }

  &__symbol {
    margin-right: 0.5rem;
  }

  &__exchange {
    background-color: $dark-color;
    color: $light2-color;
    padding: 0px 4px 0px 4px;
    border-radius: 8px;
    font-size: 10px;
    line-height: 16px;
  }
}

.react-autosuggest__suggestion {
  & + .react-autosuggest__suggestion {
    border-top: 1px solid rgba($color: $dark4-color, $alpha: 0.25);
  }
}
