@import '../../helpers/variables';

.transaction-notification {
  padding: 24px;
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 12px;
  background-color: $dark1-color;

  .icon {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  .iClose {
    background-color: $down-color;
  }

  .iCheck {
    background-color: $up-color;
  }

  .text-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $light-color;
  }

  .text-desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $light3-color;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark1-color;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba($color: $up-color, $alpha: 0.2);

    &--error {
      background-color: rgba($color: $down-color, $alpha: 0.2);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 32px;

    .btn {
      height: 100%;
      padding: 6px 16px !important;

      &--cancel {
        &:hover {
          background-color: $light-color;
          color: $dark-color;
        }
      }
    }
  }
}
