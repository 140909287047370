@import '../helpers/variables';
@import '../helpers/animation';

.btn {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  @include button();
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px !important;
  cursor: pointer;
  @include button();
}

.disabled {
  opacity: 0.5;
  // pointer-events: none;
  cursor: not-allowed;
}
