.br-1 {
  border-radius: 0.25rem !important;
}

.br-2 {
  border-radius: 0.5rem !important;
}

.br-3 {
  border-radius: 1rem !important;
}

.br-4 {
  border-radius: 1.5rem !important;
}

.br-5 {
  border-radius: 2rem !important;
}

.border-bottom-dark2 {
  border-bottom: 1px solid $dark2-color;
}
