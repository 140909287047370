.wts-modal {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  &-backdrop2 {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: $dark-color, $alpha: 0.5);
  }
  &__header {
    width: 100%;
    height: 32px;
    border-radius: 4px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background-color: $dark1-color;
    .header {
      &__right {
        display: flex;
        .btn--icon {
          display: flex;
          padding: 4px;
          border-radius: 4px;
          cursor: pointer;
          .icon {
            width: 16px;
            height: 16px;
          }
          &:hover {
            background-color: $light3-color;
            .icon {
              background-color: $dark3-color;
            }
          }
        }
      }
    }
  }
  &__body {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 4px 4px;
    background-color: $dark2-color;
    padding-top: 12px;
    .body {
      &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        align-items: center;
        .actions {
          margin-top: 8px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 12px;
          > div {
            width: 120px;
          }
        }
      }
    }
  }
  &--confirm-edit {
    .wts-modal {
      &__header {
        background-color: $dark-color;
        .header {
          &__right {
            .btn--icon {
              &:hover {
                background-color: $light3-color;
                .icon {
                  background-color: $dark3-color;
                }
              }
            }
          }
        }
      }
      &__body {
        background-color: $dark1-color;
        .body {
          &__content {
          }
        }
      }
    }
  }
}

.d-order-confirm-modal {
  .wts-modal {
    &__header {
      background-color: $dark3-color;
    }
    &__body {
      background-color: $dark1-color;
    }
    &--confirm-edit {
      .wts-modal {
        &__header {
          background-color: $dark3-color;
        }
        &__body {
          background-color: $dark1-color;
        }
      }
    }
  }
}
