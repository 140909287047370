.dc13 {
  @extend %text;
  padding: 12px;
  position: relative;

  .btn-icon {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .checkbox-button {
    align-items: center;

    .cbb-box {
      min-width: 16px !important;
      width: 16px !important;
      height: 16px !important;
    }

    .cbb-text {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      color: $light3-color;
    }
  }

  &-actions {
    width: 100%;
    margin-top: 12px;

    button {
      width: 100%;
      height: 32px;
    }
  }
}
