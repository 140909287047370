.pinefolio-sell,
.pinefolio-buy,
.sellPortfolio-modal,
.bond-detail-order {
  .product-info {
    display: flex;
    flex-direction: column;

    &__infors {
      display: flex;
      padding: 12px 0;
      width: 100%;
    }

    &__infor {
      display: flex;
      gap: 8px;
      flex: 1;
      width: 50%;
    }

    &__allocation {
      margin-top: 12px;
      margin-bottom: 36px;
    }

    &__order {
      display: flex;
      flex-direction: column;

      .infor-row {
        min-height: 36px;
        display: flex;
        align-items: center;

        &__name {
          flex: 3;
        }

        &__value {
          flex: 5;
        }

        .iWarning {
          background-color: $active-color;
        }
      }
    }

    &__ticket {
      margin-top: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .btn {
        height: 32px;
        line-height: 24px;
      }
    }

    &__summaries {
      display: flex;
      justify-content: space-between;
    }

    &__summary {
      display: flex;
      gap: 6px;
    }

    &__cashTransfer {
      min-height: 172px;
      margin-bottom: 12px;
    }

    .order-quantity {
      margin-left: -8px;
      display: flex;
      gap: 8px;

      input {
        height: 28px;
        background-color: $dark-color;
        border: none;
        outline: none;
        border-radius: 4px;
        color: $light-color;
        padding: 0 8px;
        width: 50%;
        flex: 1;
      }

      .input-error {
        // border: 1px solid rgba($color: $down-color, $alpha: .5);
        box-shadow: 0 0 5px rgba($color: $down-color, $alpha: 1);
      }
    }

    .text-error {
      color: $down-color;
      font-size: 10px;
      line-height: 14px;
      float: left;
    }

    .cbb-text {
      font-size: 13px;
      color: $gray-color;
    }

    .cbb-box {
      min-width: 16px;
      height: 16px;

      &::after {
        width: 8px;
        height: 5px;
      }
    }

    .dash-line {
      margin: 12px 0;
      border-bottom: 1px dashed $dark3-color;
      height: 1px;
    }
  }

  .pinefolio-icash-transfer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__items {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    &__item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .pinefolio-icash-transfer__value {
        height: 28px;
        padding: 4px 0;
      }
    }

    &__actions {
      display: block;
      width: 100%;

      .btn {
        width: 100%;
      }
    }

    &__select {
      font-size: 13px;
      font-weight: 500;
      width: 90%;

      &__control {
        background-color: $dark-color;
        height: 28px;
        min-height: 28px;
        outline: none;
        border: none;
        box-shadow: none;
      }

      &__menu {
        background-color: $dark-color;
        width: 100%;
        border-top-left-radius: 5;
        border-top-right-radius: 5;
        z-index: 2;
        position: absolute;
        margin-top: 5px;
      }

      &__single-value {
        font-size: 13px;
        padding: 2px;
        color: $light-color;
      }

      &__option {
        color: $light1-color;
        background-color: $dark-color;
        padding: 5px 8px;
        font-size: 13px;
        text-align: start;

        &--is-selected {
          color: #e2e2e3;
          background-color: $dark4-color;
        }

        &:hover {
          background-color: $dark3-color;
        }
      }

      &__value-container,
      &__indicators {
        height: 28px;
        padding: 0;
      }

      &__indicator-separator {
        display: none;
      }

      &__placeholder {
        color: $light-color;
      }
    }

    &__value {
      .transfer-value {
        display: flex;
        justify-content: start;
        gap: 8px;
        align-items: center;
        position: relative;

        input {
          height: 28px;
          background-color: $dark-color;
          border: none;
          outline: none;
          color: $light-color;
          border-radius: 2px;
          padding: 2px 4px;
          width: 100%;
          padding-right: 36px;
        }

        .icon {
          width: 12px;
          height: 12px;
        }

        &__actions {
          position: absolute;
          right: 0;
          display: flex;

          .icon {
            :hover {
              cursor: pointer;
            }
          }
        }

        &__action {
          padding: 4px;
          display: flex;

          &:hover {
            cursor: pointer;
            border-radius: 4px;

            &:has(.iCheck) {
              background-color: $dark-blue3;
            }

            &:has(.iClose) {
              background-color: $down-color;
            }

            .icon {
              background-color: $light-color;
            }
          }
        }
      }
    }

    .icon {
      width: 12px;
      height: 12px;
    }

    .input-error {
      box-shadow: 0 0 5px rgba($color: $down-color, $alpha: 1);
    }
  }

  .order-actions {
    display: flex;
    gap: 8px;
    margin-top: 12px;

    .btn {
      height: 32px;
      line-height: 24px;
    }

    .btn--buy {
      flex: 1;

      &:hover {
        background-color: $up1-color;
      }
    }

    .btn--sell {
      flex: 1;

      &:hover {
        background-color: $down1-color;
      }
    }

    .btn--cancel {
      width: 20%;
    }
  }
}
