.leader-board {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    .round-tab {
      display: flex;
      gap: 88px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      color: $gray-color;
      .active {
        color: $light-color;
      }
    }

    .groups-rank {
      display: flex;
      gap: 48px;
      &__item {
        padding: 12px;
        .group-infor {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: center;
          .icon {
            width: 88px;
            height: 88px;
            border-radius: 100%;
            background-color: $dark3-color;
            align-items: center;
            justify-content: center;
            span {
              background-size: cover;
            }
          }

          &.whale-group {
            .groupName {
              color: #3279af;
            }
            .icon {
              span {
                width: 64px;
                height: 64px;
              }
            }
          }

          &.koi-group {
            .groupName {
              color: #f47b2a;
            }
            .icon {
              span {
                width: 60px;
                height: 64px;
              }
            }
          }

          &.carp-group {
            .groupName {
              color: #a5c6b8;
            }
            .icon {
              span {
                width: 64px;
                height: 44.66px;
              }
            }
          }
        }
        .groupName {
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          text-align: center;
        }
        .group-board {
          font-size: 13px;
          line-height: 20px;
          table {
            thead {
              font-weight: 500;
              tr:nth-child(1) {
                &::before {
                  display: block;
                  content: '';
                }
              }
            }
            tbody {
              tr {
                position: relative;
                &::before {
                  content: '';
                  display: block;
                }
              }
              tr:nth-child(1),
              tr:nth-child(2),
              tr:nth-child(3),
              tr:nth-child(4) {
                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  top: 0px;
                  width: 100%;
                  left: auto;
                  height: 1px;
                  background: linear-gradient(
                    to left,
                    rgba(255, 231, 11, 0) 0%,
                    #ffe70b 50.52%,
                    rgba(255, 231, 11, 0) 100%
                  );
                }
              }
              tr:nth-child(1),
              tr:nth-child(2),
              tr:nth-child(3) {
                .stock-rank {
                  background-color: rgba($color: $active-color, $alpha: 0.2);
                }
              }
            }
            td,
            th {
              padding: 6px;
              &:not(:first-child) {
                padding-left: 8px;
              }
            }
          }

          .icon {
            width: 8px;
            height: 8px;
          }
          .iArrowUp {
            background-color: $up-color;
          }
          .iArrowDown {
            background-color: $down-color;
          }
        }
      }
    }

    .top-trading {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .list-symbol {
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
        .stock-infor {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 8px;
          .stock-symbol {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 42px;
            border-radius: 100%;
            width: 42px;
            height: 42px;
            background-color: $gray-color;
          }
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            .stock-rank {
              background-color: rgba($color: $active-color, $alpha: 0.2);
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            .stock-symbol {
              background-color: rgba($color: $active-color, $alpha: 0.2);
            }
          }
        }
      }
    }
  }

  .stock-rank {
    display: flex;
    width: 20px;
    justify-content: center;
    border-radius: 4px;
    background-color: rgba($color: $dark4-color, $alpha: 1);
    margin: 0 auto;
  }
}
