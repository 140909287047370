@import './detail';
@import './notification';

.bond-page {
  @extend %text;
  color: $light3-color;
  padding: 12px;

  .table-group {
    &__nav {
      background-color: $dark2-color;
      // border-left: 2px solid $active-color;
      padding: 4px;
      height: 32px;
      user-select: none;

      .icon {
        background-color: $light-color;
      }
    }
  }

  table {
    td {
      line-height: 20px;
      word-wrap: break-word;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;

    &--sm {
      width: 12px;
      height: 12px;
      object-fit: contain;
    }

    &--sx {
      width: 8px;
      height: 8px;
      object-fit: contain;
    }
  }

  .iStar {
    background-color: $active-color;
  }

  .iRefresh {
    background-color: $light3-color;
  }

  .text-active {
    color: $active-color;
  }

  .text-light {
    color: $light-color;
  }

  .text-light-3 {
    color: $light3-color;
  }

  .text-title {
    text-transform: capitalize;
    font-weight: 500;
    line-height: 20px;
  }

  .text-desc {
    font-weight: 400;
    line-height: 20px;
  }

  .w-100p {
    width: 100px;
  }

  .w-200p {
    width: 200px;
  }

  .btn {
    height: 24px;
    width: auto;
    padding: 2px 16px;
    outline: none;

    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;

    & + .btn {
      margin-left: 8px;
    }

    &--demo {
      border: 1px solid $light-blue3;
      border-radius: 4px;
      color: $light-blue3;
      background-color: transparent;

      a {
        color: $light-blue3;
        text-decoration: none;
      }
    }

    &--buy {
      border: none;
      border-radius: 4px;
      color: $dark3-color;
      background-color: $up-color;
    }

    &--sell {
      border: none;
      border-radius: 4px;
      color: $light-color;
      background-color: $down-color;
    }
  }

  .bond {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__right {
        display: flex;
        align-items: center;
      }
    }

    &-body {
      padding: 12px;
      height: calc(100vh - 160px);
    }
  }

  .bond-layout {
    &__header {
      display: flex;
    }

    &__body {
      display: flex;
      padding-top: 12px;
    }

    &__filters {
      display: flex;
      gap: 10px;
      width: 100%;
    }

    &__filter {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      &.button-group {
        flex: 3;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
      }

      button {
        border: none;

        &:focus {
          outline: none;
        }
      }

      input {
        height: 28px;
        background-color: $dark-color;
        border: none;
        outline: none;
        border-radius: 4px;
        color: $light-color;
        padding: 0 8px;
        width: 100%;
      }

      .input-text-search {
        padding-left: 28px;
        position: relative;
      }

      .input-text-search-outer {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          background: white;
          width: 12px;
          height: 12px;
          top: 50%;
          left: 8px;
          background: url('../../../img/icons/header/Search.svg');
          background-size: contain;
          background-repeat: no-repeat;

          transform: translateY(-50%);
          z-index: 1;
        }
      }

      .filter-control-select {
        font-size: 13px;
        font-weight: 500;

        &__control {
          background-color: $dark-color;
          height: 28px;
          min-height: 28px;
          outline: none;
          border: none;
          box-shadow: none;
        }

        &__menu {
          background-color: $dark-color;
          width: 100%;
          border-top-left-radius: 5;
          border-top-right-radius: 5;
          z-index: 2;
          position: absolute;
          margin-top: 5px;
        }

        &__single-value {
          font-size: 13px;
          padding: 2px;
          color: $light-color;
        }

        &__option {
          color: $light1-color;
          background-color: $dark-color;
          padding: 5px 8px;
          font-size: 13px;
          text-align: start;

          &--is-selected {
            color: #e2e2e3;
            background-color: $dark4-color;
          }

          &:hover {
            background-color: $dark3-color;
          }
        }

        &__value-container,
        &__indicators {
          height: 28px;
        }

        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: $light-color;
        }
      }

      .filter-control-calendar {
        position: relative;
        display: flex;
        align-items: center;

        .react-datepicker-wrapper {
          width: 100%;
          position: relative;
        }

        .iCalendar {
          position: absolute;
          right: 8px;
        }
      }

      .iBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-color;
        height: 28px;
        min-width: 48px;
        border-radius: 4px;
        cursor: pointer;

        &--primary {
          background-color: $dark-blue3;
          width: 100%;
        }

        &--secondary {
          background-color: $dark3-color;
        }
      }
    }
  }

  .show-data {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    table {
      th {
        line-height: 20px;
      }

      tr {
        height: 32px;
      }
    }
  }

  .bond-product {
    font-size: 13px;
    line-height: 20px;
    height: 100%;

    .show-data {
      height: calc(100% - 135px);
    }

    .gap-4 {
      gap: 4px;
    }

    .gap-8 {
      gap: 8px;
    }

    &-type {
      padding: 2px 8px;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      color: $light3-color;
      text-wrap: nowrap;
      position: relative;
      gap: 8px;

      &:hover {
        color: $light-color;
      }

      &.active {
        color: $light-color;
      }

      &.active::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 40px;
        left: 25%;
        border-bottom: 1px solid $active-color;
      }
    }

    &__header {
      gap: 12px;
    }

    &__body {
      flex-direction: row;
      gap: 12px;
      height: calc(100% - 24px);

      &--left {
        flex: 3;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      &--right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .investor-infors {
      margin-top: 24px;
      margin-bottom: 24px;
      gap: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .investor-infor {
        display: flex;
        gap: 36px;
      }

      .line {
        display: block;
        height: 1px;
        border: none;
        border-top: 1px solid $dark2-color;
      }
    }

    .investor-note {
      display: flex;
      align-items: center;
    }

    .bond-introduce {
      &--faq {
        border-top: 1px solid $dark2-color;
        padding-top: 16px;
        padding-bottom: 16px;
      }

      &--faq:last-of-type {
        border-bottom: 1px solid $dark2-color;
      }
    }

    .bond-faq {
      table {
        th {
          height: 32px;
        }

        tr {
          height: 28px;
        }
      }
    }

    a {
      text-decoration: none;
      color: $light-color;
    }

    .more-infor {
      color: $light-blue3;
      text-decoration: underline;
    }
  }

  .bond-deal-list,
  .bond-order-list {
    font-size: 13px;
    line-height: 20px;
    height: 100%;

    .show-data {
      height: calc(100% - 90px);
    }

    &__body {
      flex-direction: column;
      display: flex;
      overflow: hidden;
      height: 100%;
    }
  }

  .bond-portfolio {
    font-size: 13px;
    line-height: 20px;
    height: 100%;

    .show-data {
      height: 100%;
      margin-top: 0;
    }

    &__body {
      flex-direction: column;
      display: flex;
      overflow: hidden;
      height: 100%;
    }
  }
}

.table-border-radius {
  border-collapse: collapse;
  border-radius: 4px;
  border-style: hidden;
  box-shadow: 0 0 0 1px $black2-color;
  overflow: hidden;
  border: none !important;
}

.react-datepicker-popper {
  z-index: 99999;
}

.tool-tip {
  .icon {
    width: 14px;
    height: 14px;
    background-color: $dark-blue3;
  }
}

.popover {
  z-index: 11111111 !important;
}
