.bond-otc-notice {
  @extend %text;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .wts-modal-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
