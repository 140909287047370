@import '../../../assets/scss/helpers/variables';
.switch-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &--rtl {
    flex-direction: row-reverse;
  }
}

section {
  float: left;
  width: 24px;
  height: 16px;
}

section label {
  margin: 0;
}

/*=====================*/
.checkbox {
  position: relative;
  display: inline-block;
}
.checkbox:after,
.checkbox:before {
  font-family: FontAwesome;
  font-feature-settings: normal;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  font-language-override: normal;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}
.checkbox label {
  width: 24px;
  height: 16px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 16px;
  transition: 0.4s;
}
.checkbox label:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  left: 0;
  top: -5px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}
.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}
.checkbox input:hover + label:after {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
}
.checkbox input:checked + label:after {
  left: 40px;
}

.model-2 .checkbox label {
  width: 24pxpx;
}
.model-2 .checkbox label:after {
  top: 0;
  width: 16px;
  height: 16px;
}
.model-2 .checkbox input:checked + label {
  background: $up1-color;
}
.model-2 .checkbox input:checked + label:after {
  left: 8px;
}
