.plan-modal {
  &--md {
    height: 660px;
  }
  &--hsm {
    height: 600px;
  }
  .plan-modal-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    .plan-modal {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0px;
        margin: 0px 12px;
        border-bottom: 1px solid $dark3-color;
        &--left {
          display: flex;
          gap: 12px;
          flex-direction: column;
          .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;
          }
        }
        &--right {
          display: flex;
          align-items: flex-start;
          height: 100%;
          .table-interest-rate {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 12px;
            border: 1px dashed $dark3-color;
            align-self: center;
            justify-content: center;
          }

          tr {
            td {
              border: 1px solid $dark2-color;
              line-height: 20px;
              padding: 0px 16px;
              text-align: center;
            }
          }
        }
        .btn--icon {
          border-radius: 100%;
          padding: 4px;
          .icon {
            background-color: $light-color;
            cursor: pointer;
          }
          &:hover {
            background-color: $light1-color;
            .icon {
              background-color: $dark3-color;
            }
          }
        }
      }
      &__body {
        display: flex;
        padding: 12px;
        overflow: hidden;
        gap: 48px;
        align-items: flex-start;
        position: relative;
        height: 100%;
        &--left {
          display: flex;
          gap: 48px;
          flex-direction: column;
          min-width: 532px;
          height: 100%;
          .pack-item {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .invest-calendar {
              flex: 1 1;
              display: flex;
              justify-content: space-between;
              &__item {
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 13px;
                &--label {
                  display: flex;
                  gap: 4px;
                  align-items: center;
                }
              }
            }
          }
          .header-tabs {
            display: flex;
            gap: 24px;
            margin-bottom: 32px;
            .header-tab {
              cursor: pointer;
              position: relative;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              padding: 1px;

              &.active {
                &::after {
                  content: '';
                  border-bottom: 1px solid $active-color;
                  width: 25px;
                  position: absolute;
                  left: 50%;
                  bottom: 0%;
                  transform: translate(-50%, 0%);
                  transition: transform 0.3 ease-in;
                }
              }
            }
          }
          .product-info__infors {
            border: none;
          }
          .muti-select-box {
            flex-wrap: wrap;
            .checkbox-button {
              .cbb-box {
                min-width: 16px;
                height: 16px;
                border: 2px solid $dark3-color;
                background-color: $dark-color;
              }
              &.active {
                .cbb-box {
                  border: 2px solid $dark3-color;
                  background-color: $active-color;
                }
              }
            }
          }
        }
        &--right {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
          height: 100%;
          .reminder-block {
            display: flex;
            gap: 24px;
            flex-direction: column;
            width: 100%;
          }
        }
        .pack-detail--lg {
          max-width: 600px;
          min-width: 600px;
          height: inherit;
        }
        .pack-detail--sm {
          max-width: 540px;
          min-width: 540px;
          height: inherit;
        }
        .pack-relative {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
          margin: 0px 0px 12px 0px;
          gap: 36px;
          transition: all 0.2s;
          transform: scale(0.98);

          .active {
            cursor: grabbing;
            cursor: -webkit-grabbing;
          }

          .next-pack {
            background-color: rgba($color: $light-color, $alpha: 0.2);
            border-radius: 100%;
            height: fit-content;
            padding: 8px;
            display: flex;
            position: relative;
            flex-direction: column;
            &::before {
              content: '';
              display: block;
              position: absolute;
              height: 200px;
              left: 50%;
              bottom: 32px;
              transform: translateX(-50%);
              border-left: 1px dashed $gray-color;
            }
            &::after {
              content: '';
              display: block;
              position: absolute;
              height: 200px;
              left: 50%;
              top: 32px;
              transform: translateX(-50%);
              border-left: 1px dashed $gray-color;
            }
            .icon {
              background-color: $light1-color;
            }
          }
          .pack-action {
            display: flex;
            flex-direction: column;
            gap: 24px;
            height: 100%;
            width: 280px;
            white-space: normal;
            height: inherit;
            .w-272 {
              width: 272px;
            }
            .checkbox-button {
              padding: 0;
              .cbb-box {
                width: 16px;
                height: 16px;
                min-width: auto;
              }
              .cbb-text {
                color: $light3-color;
              }
            }
          }
          .pack-detail {
            display: flex;
            flex-direction: column;
            gap: 12px;
            height: 100%;
            width: 100%;
            height: inherit;

            &--fullPack {
              max-width: none;
              min-width: auto;
            }
            .viewPlan {
              display: flex;
              padding-top: 12px;
              gap: 24px;
              flex: 1 1;
              &-setting {
                display: flex;
                flex-direction: column;
                flex: 2 5;
                gap: 8px;
                &__item {
                  display: flex;
                  gap: 4px;
                  align-items: center;
                  width: 258px;
                  &--name {
                    flex: 1 1;
                    font-size: 13px;
                    font-weight: 500;
                    color: $light3-color;
                    line-height: 20px;
                    .icon {
                      width: 16px;
                      height: 16px;
                    }
                  }
                  &--value {
                    flex: 1 1;
                    color: $light3-color;
                    line-height: 20px;

                    .btn {
                      height: 32px;
                      line-height: 30px;

                      &--hidden {
                        display: none !important;
                      }
                    }
                  }
                }
              }

              &-step {
                display: flex;
                flex-direction: column;
                gap: 2px;
                flex: 3 5;
                overflow: hidden;

                .ps__rail-x {
                  display: none !important;
                }
                .input-text-search {
                  input {
                    background-color: $dark-color;
                    padding: 6px 6px;
                    border-radius: 4px;
                    border: none;
                    outline: none;
                    color: $light-color;
                    &::placeholder {
                      color: $gray-color;
                      opacity: 1;
                    }
                  }
                }
                tr {
                  position: relative;
                  height: 36px;
                  &:not(:first-child) {
                    .step-num {
                      span {
                        font-size: 13px;
                        color: $up-color;
                        padding: 2px 8px;
                        line-height: 20px;
                      }
                      &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -10px;
                        width: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-top: 8px solid $dark3-color;
                      }
                      &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -4px;
                        width: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-top: 2px solid $dark3-color;
                      }
                    }
                  }
                  &:first-child {
                    .step-num {
                      &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -4px;
                        width: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-top: 2px solid $dark3-color;
                      }
                      &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -4px;
                        width: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-top: 2px solid $dark3-color;
                      }
                    }
                  }
                  .btn--editPlan {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 6px;
                    .icon {
                      width: 10px;
                      height: 10px;
                      background-color: $light-color;
                    }
                  }
                }
                .step-num {
                  position: relative;
                  display: flex;
                  width: 24px;
                  height: 24px;
                  border-radius: 100%;
                  background-color: rgba($color: $up-color, $alpha: 0.2);
                  align-items: center;
                  justify-content: center;
                  border: 1px solid $dark2-color;
                  color: $up-color;
                  &--edit {
                    background-color: rgba($color: $light-blue3, $alpha: 0.2);
                    color: $light-blue3;
                  }
                  span {
                    font-size: 13px;
                    padding: 2px 8px;
                    line-height: 20px;
                  }
                }
                table {
                  tr {
                    font-size: 13px;
                    padding: 6px;
                    line-height: 20px;
                    font-size: 500;
                    td {
                      padding: 0px 3px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &__footer {
        display: flex;
        gap: 8px;
        padding: 12px;
        .btn {
          line-height: 20px;
          text-align: center;
          padding: 6px 0px;
          &--order {
            width: 16.6666%;
          }
          &--deposit {
            width: 66.6666%;
          }
          &--stopPlan {
            width: 16.6666%;
          }
        }
        .note {
          display: flex;
          flex-direction: column;
          gap: 8px;
          span {
            font-size: 13px;
            position: relative;
            margin-left: 16px;
            line-height: 20px;
            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 6px;
              width: 4px;
              height: 4px;
              border-radius: 100%;
              left: -10px;
              background-color: $gray-color;
            }
          }
        }
      }
    }
  }

  .gap-4 {
    gap: 4px !important;
  }

  .gap-6 {
    gap: 6px !important;
  }

  .gap-8 {
    gap: 8px !important;
  }

  .gap-12 {
    gap: 12px !important;
  }

  .gap-24 {
    gap: 24px !important;
  }

  .gap-48 {
    gap: 48px !important;
  }
  .bg--light {
    background-color: $light-color !important;
  }
  .break-line--verticle {
    height: 100%;
    border: 1px dashed $dark3-color;
  }
  .input-text-search {
    input {
      font-size: 13px;
      background-color: $dark-color;
      padding: 6px 6px 6px 8px;
      border-radius: 4px;
      border: none;
      outline: none;
      color: $light-color;
      &::placeholder {
        font-size: 13px;
        color: $gray-color;
        opacity: 1;
      }
    }
    &--readOnly {
      input {
        color: $gray-color;
      }
    }
  }
  .filter-control-calendar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 128px;
    input {
      background-color: $dark-color;
      border: none;
      outline: none;
      border-radius: 4px;
      color: $light-color;
      padding: 6px 8px 6px 24px;
      line-height: 20px;
      font-size: 13px;
      font-weight: 400px;
      width: 100%;
      &::placeholder {
        color: $light-color;
      }
    }
    .icon {
      width: 12px;
      height: 12px;
    }
    .iCalendar {
      position: absolute;
      left: 8px;
    }
    .input-group-prepend {
      height: 24px;
    }
    &--readOnly {
      input {
        color: $gray-color;
      }
    }
  }
  .filter-control-select {
    font-size: 13px;
    font-weight: 500;
    width: 100%;

    &__control {
      background-color: $dark-color;
      height: 32px;
      min-height: 24px;
      outline: none;
      border: none;
      box-shadow: none;
    }

    &__menu {
      background-color: $dark-color;
      width: 100%;
      border-top-left-radius: 5;
      border-top-right-radius: 5;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
      &-list {
        max-height: 200px;
      }
    }

    &__single-value {
      font-size: 13px;
      padding: 2px;
      color: $light-color;
    }

    &__option {
      color: $light1-color;
      background-color: $dark-color;
      padding: 5px 8px;
      font-size: 13px;
      text-align: start;

      &--is-selected {
        color: #e2e2e3;
        background-color: $dark4-color;
      }

      &:hover {
        background-color: $dark3-color;
      }
    }

    &__value-container,
    &__indicators {
      height: 32px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $light-color;
    }
  }
  .pinefolio-actions {
    display: flex;
    gap: 8px;
    .pinefolio-action {
      padding: 6px 12px;
      font-size: 13px;
      line-height: 20px;
    }
  }
}
