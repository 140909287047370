@import '../../../assets/scss/helpers/variables';

.paging-custom {
  font-weight: 500 !important;
  .button-paging {
    background-color: $dark3-color !important;
    color: $light-color !important;
    height: 24px !important;
    width: 28px !important;
    border: none !important;
    padding: 0px !important;
    margin-left: 8px;
  }

  .page-count {
    font-size: 13px;
    display: flex;
    width: 49px !important;
    height: 24px !important;
    background-color: $dark3-color;
    color: $light-color !important;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 12px !important;
    height: 12px !important;
    &.disabled {
      background-color: $light3-color;
    }
  }

  .pagingSize-control-select__single-value {
    overflow: initial;
  }
}
