.pinefolio-open-account {
  @extend %text;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px;
  height: 560px;

  &__title {
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  &__left {
    width: 40%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__right {
    width: 60%;
    background-color: $dark2-color;
  }

  &__header {
    flex: 1;
  }

  &__body {
    flex: 1;
  }

  &__actions {
    margin-top: 12px;
    display: flex;
    gap: 12px;
    width: 100%;

    .btn {
      height: 32px;
      line-height: 28px;
      cursor: pointer;
    }

    .btn--buy {
      width: 80%;
    }

    .btn--cancel {
      width: 20%;
    }
  }

  .ccb-text {
    font-size: 13px;
    line-height: 20px;
  }

  .cbb-box {
    min-width: 18px;
    height: 18px;

    &::after {
      width: 7px;
      height: 5px;
    }
  }
}

.open-account {
  @extend %text;
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 560px;

  .open-account-contract {
    padding: 12px;
    display: flex;
    width: 100%;
    gap: 12px;

    &__title {
      text-align: center;
      display: inline-block;
      width: 100%;
    }

    &__left {
      width: 320px;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__right {
      flex: 1;
      background-color: $dark2-color;
    }

    &__header {
      flex: 1;

      .checkbox-button {
        .cbb-text {
          font-size: 13px;
          line-height: 20px;
          color: $light1-color;
        }

        .cbb-box {
          min-width: 18px;
          height: 18px;

          &::after {
            width: 7px;
            height: 5px;
          }
        }
      }
    }

    &__body {
      flex: 1;
    }

    &__actions {
      margin-top: 12px;
      display: flex;
      gap: 12px;
      width: 100%;

      .btn {
        height: 32px;
        line-height: 28px;
        cursor: pointer;
      }

      .btn--buy {
        width: 80%;
      }

      .btn--cancel {
        width: 20%;
      }
    }
  }

  .open-account-success {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__header {
      padding: 4px;
      height: 32px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $dark2-color;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    &__body {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 12px 24px 12px;
      gap: 12px;
    }

    &__icon {
      margin-top: 12px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: rgba($color: $up-color, $alpha: 0.2);
      display: flex;
      justify-content: center;
      align-items: center;

      .iCheck {
        background-color: $up-color;
        width: 32px;
        height: 32px;
      }
    }

    &__cards {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 24px;
      padding: 24px 0;
    }

    &__card {
      display: flex;
      flex-direction: column;
      padding: 24px;
      background-color: $dark2-color;
      align-items: center;
      border-radius: 4px;
      width: 360px;
      gap: 12px;

      .oas-card {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__infor {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &__actions {
          width: 100%;
          text-align: center;
        }
      }
    }

    .btn {
      width: 100%;
      height: 32px;
      border-radius: 32px;
    }

    .btn--reset {
      border-radius: 4px;
    }

    &__actions {
      .btn {
        border-radius: 4px;
      }
    }
  }
}
