@import '../helpers/variables';
@import '../helpers/animation';

.navbar-priceboard {
  padding: 8px;
  background-color: $panel-bg-dark;
  border-radius: 4px 4px 0px 0px;

  .special-badge {
    position: absolute;
    top: -14px;
    right: -8px;
    background: #d32f2f;
    color: $light-color;
    border-radius: 2px;
    font-size: 10px;
    line-height: 1.5;
    padding: 1px 6px;
    text-align: center;
  }

  .line {
    height: 16px;
    width: 2px;
    border-radius: 1px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: $dark3-color;
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-color: $gray-color;
    cursor: pointer;

    &:not(.active):hover {
      background-color: $light-color;
    }
  }

  .iStar {
    cursor: pointer;
    width: 12px;
    height: 12px;

    &.active {
      background-color: $active-color;
      cursor: pointer;
      width: 12px;
      height: 12px;
    }
  }

  .iEdit {
    width: 16px;
    height: 14px;
  }

  .iBin {
    width: 14px;
    height: 14px;
  }

  .navbar-collapse {
    padding: 8px;
    border-radius: 4px 4px 0px 0px;
  }

  .nav-link {
    height: 24px;
    border-radius: 13px;
    color: $light3-color;
    padding: 2px 0.5rem;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;

    &.active {
      background-color: $active-color !important;
      color: $black2-color !important;
    }

    &:not(.active):hover {
      color: $light-color !important;
    }
  }

  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown:not(.watchlist-menu) {
    .dropdown-menu {
      .nav-link {
        &:not(.dropdown-toggle) {
          position: relative;
          height: 36px;
          line-height: 32px;
          float: left;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;

          &:not(:last-child)::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            border-bottom: 1px solid $dark1-color;
          }

          &.active {
            background-color: $black2-color !important;
            color: $active-color !important;
          }
        }
      }
    }
  }

  .dropdown-menu {
    background-color: $dark1-color;
    z-index: 99999;
    margin-top: 2px !important;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -9px;
      width: 100%;
      left: 0;
      border-top: 9px solid rgba(255, 255, 255, 0);
    }

    .nav-link {
      border-radius: 0;
      color: $light3-color !important;

      :hover {
        background-color: $active-color;
        color: $light-color !important;
      }

      &.active {
        background-color: $active-color;
        color: $black2-color !important;
      }
    }
  }

  .industry-menu {
    .dropdown-menu {
      width: 450px;
      right: 0;
      left: auto;

      a {
        margin: 0px 8px;
        width: calc(50% - 16px) !important;
      }
    }
  }

  .watchlist-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    .dropdown-menu {
      width: 200px;
      padding: 0;
    }

    &.active {
      .dropdown-toggle {
        background-color: $active-color;
        color: $dark-color;

        &:hover {
          color: $dark4-color !important;
        }
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 6px 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      height: auto;

      & + .watchlist-menu__item {
        border-top: 1px solid $dark1-color;
      }
    }

    &__left {
      display: flex;
      align-items: stretch;
      width: 100%;
      gap: 8px;

      a {
        align-items: stretch;
        width: 100%;
      }

      input {
        background-color: $dark-color;
        border: none;
        outline: none;
        color: $light-color;
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 2px;
        height: 28px;

        &.input-wl-add {
          width: calc(100% - 25px);
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      right: 8px;

      .btn {
        display: inline-block;
        color: $light-color;
        text-align: center;
        background-color: transparent;
        font-size: 0.75rem;
        outline: none;
        box-shadow: none;
      }

      .btn-wl-add {
        background: url('../../img/icons/price/Plus.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px 16px;
        height: 24px;
      }
    }

    &__pin {
      display: flex;
      align-items: center;
    }

    &__form {
      border-top: 1px solid $dark1-color;

      .error {
        font-size: 11px;
        color: $down-color;
        padding: 4px 8px;
      }
    }

    .nav-link {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;

      &.active {
        background-color: transparent !important;
        color: $active-color !important;
      }

      span {
        &:hover {
          background-color: transparent;
          color: $light-color !important;
        }

        &.active {
          color: $active-color;
        }
      }
    }
  }

  .watchlist-item {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: inherit;
  }

  .cw-search {
    &__form {
      width: 348px;
      display: flex;
      flex-direction: column;
      padding: 12px;
      gap: 12px;
      border-radius: 8px;
    }

    &__row {
      display: flex;
      gap: 6px;
      width: 100%;
    }

    &__input {
      background-color: $dark-color;
      height: 32px;

      font-size: 13px;
      font-weight: 400;
      line-height: 20px;

      display: flex;
      gap: 4px;
      align-items: center;
      border-radius: 4px;
      padding: 6px 8px;
      min-width: 0;

      input {
        border: none;
        outline: none;
        color: $light-color;
        background-color: transparent;
        padding: 0;
        width: 100%;
      }
    }

    &__icon {
      display: flex;

      .icon {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.light .navbar-priceboard {
  background-color: $white-color;

  .cw-search {
    &__input {
      background-color: $light-color;
      input {
        color: $dark3-color;
        background-color: transparent;
      }
    }
  }
}
