@import '../../helpers/variables';
@import '../../helpers/animation';
@import '../../components/card';

.indicator-index {
  width: calc(100% - 12px);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 6px;
  margin-right: 6px;

  .icon {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }

  .iFloor {
    background-color: $floor-color;
    margin-right: 2px;
  }

  .iDown {
    margin-right: 2px;
    background-color: $down-color;
  }

  .iRef {
    margin-right: 2px;
    background-color: $ref-color;
  }

  .iUp {
    margin-right: 2px;
    background-color: $up-color;
  }

  .iCeil {
    margin-right: 2px;
    background-color: $ceil-color;
  }

  .market-index {
    display: flex;
    flex-direction: row;
    margin: 12px 0;
    width: 100%;
    overflow: hidden;

    .paging {
      display: flex;
      flex-direction: row;
      width: 100%;
      animation: slideLeft 100ms ease;
    }

    .market-panel {
      color: $light-color;
      min-width: 360px;
      background-color: $panel-bg-dark;
      border-radius: 4px;
      padding: 12px;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      gap: 8px;
      position: relative;
      font-weight: 400;

      &.card-index-info {
        padding: 0;
      }

      & + .market-panel {
        margin-left: 12px;
      }

      &.market-panel--small {
        height: 48px;
        font-size: 14px;
        line-height: 20px;
        background-color: transparent;
        min-width: 100px;
        width: auto;
        align-items: center;
      }

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;

        &__name {
          font-weight: 500;
          line-height: 20px;
          margin-right: 12px;
        }

        &__index {
          font-weight: 500;
          margin-right: 4px;
        }

        &__change,
        &__changePercent {
          font-weight: 400;
          margin-left: 8px;
        }
      }

      &-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 14px;
        margin-bottom: 14px;

        .value {
          margin-left: 4px;
          margin-right: 4px;
        }

        .unit {
          color: $light-color;
        }
      }

      &-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;

        .market-session-numbers {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .market-session-number {
            .icon {
              width: 8px !important;
              height: 8px !important;
            }

            font-weight: 500;

            .plus {
              margin-left: 2px;
            }
          }
        }
      }

      &-infor {
        width: 225px;
        position: relative;
      }

      &-chart {
        width: calc(100% - 225px);
      }

      &__overlay {
        border-radius: 4px;
        position: absolute;
        top: 3px;
        right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: 1;
          transition: ease-in-out 0.2s;
        }

        .icon {
          width: 10px;
          height: 10px;
        }
      }

      @media screen and (min-width: 2048px) {
        &-infor {
          width: 45%;
        }

        &-chart {
          width: 55%;
        }
      }
    }

    &--small {
      background-color: $dark1-color;
      border-radius: 4px;
      justify-content: space-between;

      .market-panel {
        & + .market-panel {
          margin-left: 0;
        }
      }
    }
  }

  .card-chart {
    width: 25vw;
    border-radius: 4px;
    background-color: $panel-bg-dark;
    margin: 5px 0 5px 5px;

    & + .type-index-2 {
      height: 115px;
    }

    & + .type-index-3 {
      height: 33px;
    }

    .card-body {
      padding: 5px;

      .card-header {
        padding: 0 5px;
        border-bottom: 0;
        background-color: transparent;

        .btn-card-close {
          position: absolute;
          right: 0;
          top: 0;
          height: 14px;
          width: 14px;
          display: none;
        }
      }
    }

    &:hover {
      display: block;
    }
  }

  .card-index-info {
    flex-direction: column;

    & + .card-index-info {
      margin-left: 12px;
    }

    &__header {
      background-color: $dark2-color;
      height: 28px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: $white-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 8px;
      padding-top: 4px;
      padding-bottom: 4px;

      .icon {
        &:hover {
          background-color: $white-color;
        }
      }
    }

    &__body {
      padding: 4px 8px;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      flex: 1;
    }

    .card-index-info-item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-content: center;
      font-weight: 500;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;

      & + .card-index-info-item {
        border-top: 1px solid $dark2-color;
        align-items: center;
      }

      &__name {
        color: $light2-color;
        width: 40%;
      }

      &__value {
        flex: 3;
        display: flex;
        text-align: right;

        span {
          flex: 1;
        }
      }
    }
  }

  .switch-view {
    // width: 24px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    background-color: $panel-bg-dark;
    justify-content: center;
    border-radius: 4px;
    margin: 12px 0 12px 12px;
  }

  .switch-view-button {
    flex: 1;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:hover {
      .icon {
        background-color: $white-color;
      }
    }

    &--left {
      margin-left: 12px;
      height: 100%;
    }

    &--right {
      margin-right: 12px;
      height: 100%;
    }
  }
}
