@import '../helpers/variables';

.user-select {
  position: relative;
  box-sizing: border-box;
  z-index: 10000;

  &__control {
    display: flex;
    height: 28px;
    min-height: 28px;
    background-color: $dark3-color;
    border-radius: 4px;
    position: relative;
    width: 195px;

    &:hover {
      border-color: red;
    }
  }

  &__option {
    background-color: $dark3-color !important;
    color: $light-color;
    cursor: default;
    display: block;
    font-size: 14px;
    padding: 5px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;

    &--is-selected {
      background-color: $dark1-color !important;
    }

    &:hover {
      background-color: $dark2-color !important;
    }
  }

  &__value-container {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  &__single-value {
    padding: 2px 5px;
    font-size: 14px;
    color: $white-color;
  }

  // &__input {}

  &__menu {
    // background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    // box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;

    top: -90px !important;
    position: absolute;
    bottom: 30px;
    background-color: $dark3-color !important;
    width: 195px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 2;

    &-list {
      max-height: 300px;
      overflow-y: auto;
      padding-bottom: 4px;
      padding-top: 4px;
      position: relative;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;
    }
  }
}

.filter-control {
  .filter-control-select {
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    padding: 0px 12px;

    &__control {
      width: 100%;
      background-color: $dark-color;
      height: 24px;
      min-height: 24px;
      outline: none;
      border: none;
      box-shadow: none;
      border-radius: 24px;
    }

    &__menu {
      background-color: $dark-color;
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
    }

    &__single-value {
      font-size: 13px;
      padding: 2px;
      color: $light-color;
    }

    &__option {
      color: $light1-color;
      background-color: $dark-color;
      padding: 5px 8px;
      font-size: 13px;
      text-align: start;

      &--is-selected {
        color: #e2e2e3;
        background-color: $dark4-color;
      }

      &:hover {
        background-color: $dark3-color;
      }
    }

    &__value-container,
    &__indicators {
      height: 24px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $light-color;
    }
  }
}
