@import './modal/sellModal.scss';
@import './modal/rebalance.scss';

.portfolio-section {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;

  .gap-4 {
    gap: 4px;
  }

  .icon {
    &.i {
      background-color: $up-color;
    }

    &.d {
      background-color: $down-color;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__body {
    table:not(.child) {
      thead {
        height: 48px;
      }

      th {
        height: 40px;
      }

      td.no-hover {
        background-color: transparent;
      }

      td,
      th {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .expand-row {
      &__content {
        margin-left: 40px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }

    .tbl-detail {
      td {
        height: 32px;
      }

      th,
      td {
        background-color: $dark3-color;
      }
    }
  }
}

.stockout-section {
  .table {
    tr {
      height: 32px;
    }
  }
}
