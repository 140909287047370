@import '../../../assets/scss/helpers/variables';

%group-control {
  .group-control {
    width: 136px;
    display: flex;
    justify-content: space-between;
    background-color: $dark-color;
    border-radius: 4px;
    height: 28px;

    &--left {
      display: flex;
      width: 20%;
    }

    &--right {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 8px;
    }

    .select {
      // width: 100%;
      width: 70px;
      min-width: 50px;
    }
    input {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: $light-color;
      text-align: center;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;

      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }

    .iMoney,
    .iVolume {
      width: 12px;
      height: 12px;
    }
  }

  ._input {
    background-color: $dark-color;
    border: none;
    outline: none;
    box-shadow: none;
    color: $light-color;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    border-radius: 4px;
    height: 28px;

    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
}

.bank-account-item-list {
  flex-wrap: wrap;
  width: 748px;

  .bank-item-container {
    max-width: 350px;
    display: flex;
    height: 184px;
    flex-direction: row;
    background-color: $dark1-color;
    margin: 0px 6px 12px 6px;

    .bank-item-content {
      padding: 12px;
      width: 350px;
      display: flex;
      align-items: center;
      justify-content: center;

      .delete-account {
        margin-top: 8px;
        width: 16px;
        height: 16px;
      }

      .register-account {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .btn-register-bank {
          background-color: rgba($color: $light-color, $alpha: 0.2);
          display: flex;
          justify-content: center;
          width: fit-content;
          align-items: center;
          padding: 6px 12px;
          gap: 7px;
          border-radius: 4px;
        }
      }

      .bank-infor {
        width: 100%;
        display: flex;
        flex-direction: column;

        .bank-logo {
          width: 32px;
          height: 32px;
          border-radius: 24px;
          background-color: $dark3-color;
          background-position: center;
          background-size: cover;
        }

        .bank-name {
          margin-top: 4px;
          font-size: 13px;
          line-height: 20px;
        }

        .bank-branch {
          margin-top: 4px;
          font-size: 11px;
          text-transform: uppercase;
          color: $light3-color;
        }

        .bank-account-no {
          margin-top: 24px;
          font-size: 24px;
          line-height: 32px;
        }

        .bank-user-name {
          margin-top: 4px;
          color: $light3-color;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.changeBank-modal {
  padding: 24px 0px;
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 12px;

  .form-bank-register {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: $dark-color !important;
      color: $light-color !important;
      -webkit-box-shadow: 0 0 0 30px $dark-color inset !important;
      -webkit-text-fill-color: $light-color !important;
    }
    .input-with-icon {
      background-color: $dark-color;
      display: flex;
      align-items: center;
      border-radius: 4px;

      .form-group {
        margin: 0;
        width: 100%;
      }

      .icon-block {
        padding: 0px 8px;
      }

      .form-control {
        height: 36px;
        background-color: $dark-color;
        padding: 0px;
        border: none;
        color: $light-color;

        :focus {
          box-shadow: none;
        }
      }
    }
  }
  .h-32 {
    height: 32px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #1d2022;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    display: block;
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    right: 2px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 6px;
    background-color: #555;
    border-radius: 6px;
    position: absolute;
    top: 0px;
    height: 85px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

.confirm-icon {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: rgba($down-color, 0.2);
  justify-content: center;
  align-items: center;
  .icon {
    width: 32px;
    height: 32px;
    background-color: rgba($down-color, 1);
  }
}

.page-partner-list {
  .filter-datePicker {
    height: 28px;
  }
  .search__input {
    background-color: $dark-color;
    border: none;
    outline: none;
    box-shadow: none;
    color: $light-color;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    border-radius: 4px;
    height: 28px;

    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
  .partner-checkbox {
    .custom-checkbox ~ .custom-checkbox-span {
      border: 2px solid;
      border-color: $dark3-color;
      color: $active-color;
      background: unset;
    }
    .custom-checkbox ~ .custom-checkbox-span::after {
      content: '';
      position: absolute;
      display: none;
    }
    .custom-checkbox:checked ~ .custom-checkbox-span {
      background: $active-color;
    }
  }

  .item-total {
    width: 189px;
    height: 44px;
    text-align: center;
  }
  .w-160 {
    max-width: 160px;
  }

  .w-200 {
    max-width: 200px;
  }

  .activeTab {
    border-bottom: 1px solid $ref-color;
  }

  .btn-danger-secondary {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #ff233d33;
    color: $down-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-brand-secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #5f8ea733;
    color: $light-blue3;
    span {
      background-color: $light-blue3;
    }
  }

  .btn-success-secondary {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #00ff5733;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.scroll-table {
  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.referrer-information {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 88px;
  height: fit-content;

  .icon {
    display: block;
    width: 16px;
    height: 16px;
  }

  .section {
    display: flex;
    flex-direction: column;
    width: 640px;
    padding: 12px 0;
    gap: 6px;
    flex-grow: 0;
    flex-shrink: 1;

    .title {
      padding: 12px 0;
    }
  }

  .border-bottom {
    border-bottom: 1px solid $dark2-color !important;
  }

  .referrer {
    &_item {
      display: grid;
      align-items: center;
      position: relative;
      grid-template-columns: 120px 1fr;
      width: 100%;
      height: 32px;
    }

    &_action {
      position: absolute;
      right: 0;
      display: flex;
      gap: 12px;
    }
  }

  .service {
    table {
      border: none;
      thead {
        th {
          text-align: left;
          border: none;
        }
      }

      tbody {
        tr {
          height: 32px;

          td {
            border: none;
          }
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 0;
    border: none;
    gap: 6px;

    &-edit {
      background-color: rgba(179, 180, 181, 0.2);
      color: $light3-color;

      .icon {
        background-color: $light3-color;
      }
    }

    &-register {
      width: 100%;
      background-color: rgba(95, 142, 167, 0.2);
      color: $light-blue3;

      .icon {
        background-color: $light-blue3;
      }
    }

    &-cancel {
      width: 100%;
      background-color: rgba(255, 35, 61, 0.2);
      color: $down-color;

      .icon {
        background-color: $down-color;
      }
    }
  }

  .w-28 {
    width: 28px;
  }

  .w-full {
    width: 100%;
  }
}

.modal-add-customer-confirm {
  max-width: 80vw !important;
  width: 1354px !important;
}

.modal-cancel-request {
  max-width: 80vw !important;
  width: 300px !important;
}

.modal-service {
  display: flex;
}

.modal-add-customer {
  width: 540px;
  max-width: 100vw;
  border-radius: 8px;
  .view-pdf {
    max-height: calc(100vh - 150px);
    overflow: auto;
  }
  .partner-border-bottom {
    border-bottom: 1px dashed $dark3-color;
  }
  .w-120 {
    min-width: 120px;
    width: 120px;
  }
  &__header {
    background: $dark2-color;
    .icon-close {
      cursor: pointer;
      width: 16px;
      height: 16px;
      display: flex;
      right: 12px;
      top: 12px;
    }
  }

  .none-modal {
    max-height: calc(100vh - 210px);
  }

  &__body {
    min-width: 540px;
    max-width: 540px;
    overflow: auto;
    max-height: calc(100vh - 150px);
    .h-32 {
      height: 32px;
    }

    .filter-control-select {
      font-size: 13px;
      font-weight: 500;
      width: 100%;
      &__control {
        background-color: $dark-color;
        height: 28px;
        min-height: 24px;
        outline: none;
        border: none;
        box-shadow: none;
      }

      &__menu {
        background-color: $dark-color;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        z-index: 2;
        position: absolute;
        margin-top: 5px;
      }

      &__single-value {
        font-size: 13px;
        padding: 2px;
        color: $light-color;
      }

      &__option {
        color: $light1-color;
        background-color: $dark-color;
        padding: 5px 8px;
        font-size: 13px;
        text-align: start;

        &--is-selected {
          color: #e2e2e3;
          background-color: $dark4-color;
        }

        &:hover {
          background-color: $dark3-color;
        }
      }

      &__value-container,
      &__indicators {
        height: 24px;
      }

      &__indicator-separator {
        display: none;
      }

      &__placeholder {
        color: $light-color;
      }
    }

    .group-control {
      width: 136px;
      display: flex;
      justify-content: space-between;
      background-color: $dark-color;
      border-radius: 4px;
      height: 28px;

      &--left {
        display: flex;
        width: 20%;
      }

      &--right {
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 8px;
      }

      .select {
        // width: 100%;
        width: 70px;
        min-width: 50px;
      }
      input {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        color: $light-color;
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;
        width: 100%;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }

      .iMoney,
      .iVolume {
        width: 12px;
        height: 12px;
      }
    }

    ._input {
      background-color: $dark-color;
      border: none;
      outline: none;
      box-shadow: none;
      color: $light-color;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
      border-radius: 4px;
      height: 28px;

      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.modal-partnership-service {
  &__head {
    position: relative;
    height: 32px;
    padding: 4px;
    background-color: $dark2-color;

    .title {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      line-height: 24px;
      color: $light-color;
    }

    .right-section {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: 0;
      right: 0;
      position: absolute;
      height: 100%;
      aspect-ratio: 1 / 1;
    }

    .right-section:hover {
      .icon {
        background-color: $light-color;
      }
    }
  }
  &__body {
    @extend %group-control;
    background-color: $dark1-color;
    .partner {
      width: 100%;
      padding: 12px;
      border-bottom: 1px solid $dark3-color;

      &__item {
        display: grid;
        grid-template-columns: 120px auto;

        &:not(:first-child) {
          margin-top: 12px;
        }
      }
    }
    .service {
      width: 100%;
      padding: 12px;
      border-bottom: 1px solid $dark3-color;

      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &__item {
        display: flex;
        justify-content: space-between;

        &:not(:first-child) {
          margin-top: 6px;
        }
      }
    }
    .action {
      width: 100%;
      padding: 12px;
    }
  }
}

.light {
  .search__input {
    background-color: $light-color !important;
    color: $dark-color !important;
  }
  .modal-partnership-service {
    &__head {
      background-color: $light1-color;
      .title {
        color: $dark3-color;
      }

      .right-section:hover {
        .icon {
          background-color: $dark3-color;
        }
      }
    }
    &__body {
      @extend %group-control;
      background-color: $light-color;
      .partner {
        border-bottom: 1px solid $light1-color;
      }
      .service {
        border-bottom: 1px solid $light1-color;
      }
    }
  }
  .authen-section {
    border: 1px solid $light-color;

    .authen-type-switches {
      background-color: $light1-color;

      .authen-type-switch {
        color: $gray-color;

        &:not(.active):hover {
          color: $active1-color;
        }
      }

      .active {
        background-color: $active1-color;
        color: $dark3-color;
      }
    }

    .otp-section {
      input {
        background-color: rgba($color: $dark3-color, $alpha: 0.2);
        color: $dark3-color;
      }
    }

    .matrix-section {
      input {
        background-color: rgba($color: $dark3-color, $alpha: 0.2);
        color: $dark3-color;
      }
    }
  }
  .page-partner-list {
    .partner-checkbox {
      .custom-checkbox ~ .custom-checkbox-span {
        color: $active1-color;
        border-color: $light2-color;
      }
      .custom-checkbox:checked ~ .custom-checkbox-span {
        background: $active1-color;
      }
    }

    .activeTab {
      border-bottom: 2px solid $ref1-color;
    }

    .btn-danger-secondary {
      background-color: #f42f5233;
    }

    .btn-brand-secondary {
      background-color: #0f557b33;
      color: $dark-blue3;
      span {
        background-color: $dark-blue3;
      }
    }

    .btn-success-secondary {
      background-color: #2cc56933;
    }
  }

  .modal-add-customer__header {
    background: $light2-color;
  }
  .modal-add-customer__body {
    .partner-border-bottom {
      border-bottom: 1px dashed $light1-color;
    }
    .group-control {
      background-color: $light-color;
      .select {
        .sDerivative {
          &__control {
            background-color: transparent;
          }
          &__option {
            color: $dark-color;
            background-color: $light2-color;
            &--is-selected {
              color: $dark-color;
              background-color: $white-color;
            }

            &:hover {
              background-color: $light-color;
            }
          }
          &__input {
            color: $dark-color;
          }
          &__single-value {
            color: $dark3-color;
          }
          &__menu {
            background-color: $light2-color;
          }
        }
      }

      input {
        background-color: $light-color;
        color: $dark3-color;
      }
    }
    ._input {
      background-color: $light-color;
      color: $dark-color;
    }
  }

  .referrer-information {
    .border-bottom {
      border-bottom: 1px solid $light-color !important;
    }

    .btn {
      padding: 0;
      border: none;

      &-edit {
        background-color: rgba(109, 110, 113, 0.2);
        color: $gray-color;

        .icon {
          background-color: $gray-color;
        }
      }

      &-register {
        background-color: rgba(95, 142, 167, 0.2);
        color: $light-blue3;

        .icon {
          background-color: $light-blue3;
        }
      }

      &-cancel {
        background-color: rgba(255, 35, 61, 0.2);
        color: $down-color;

        .icon {
          background-color: $down-color;
        }
      }
    }
  }
}

.btn {
  z-index: 0 !important;
}
