@import '../../assets/scss/helpers/variables';

.vertical-nav {
  width: 234px;
  height: calc(100vh - 100px);
  background-color: $dark1-color;
  border-top: 1px solid $dark2-color;

  color: $light-color;
  font-weight: 400;
  font-size: 13px;
  font-style: normal;
  line-height: 20px;

  .nav-item {
    height: 36px;
    padding: 8px 8px 8px 32px;
  }

  .categories {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    & + .categories {
      border-top: 1px solid $dark2-color;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      padding: 8px;

      a {
        color: $light3-color;
        text-decoration: none;
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      &:hover {
        background-color: $dark2-color;
        transition: background-color 0.75 ease;

        a {
          color: $light-color;
        }
      }

      &.active {
        background-color: $dark2-color;
        border-right: 2px solid $active-color;

        a {
          color: $light-color;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .category {
      display: flex;

      a {
        color: $light3-color;
        text-decoration: none;
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      &:hover {
        background-color: $dark2-color;
        transition: background-color 0.75 ease;

        a {
          color: $light-color;
        }
      }

      &.active {
        background-color: $dark2-color;
        border-right: 2px solid $active-color;

        a {
          color: $light-color;
        }
      }
    }
  }

  .gap-8 {
    gap: 8px;
  }
}
