@import '../../assets/scss/helpers/variables';

.DW {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  height: calc(100vh - 97px);

  .text-name {
    color: $light-color;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .text-title {
    color: $light-color;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .text-desc {
    color: $light3-color;
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    &--bigger {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .text-value {
    color: $up-color;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .text-highlight {
    color: $active-color;
  }

  .btn-copy {
    color: $light2-color;
    background-color: $dark2-color;
    padding: 2px 8px;
    border-radius: 16px;
    cursor: pointer;
    height: 30px;
  }

  .gap-4 {
    gap: 4px;
  }

  .bg-info--custom {
    background-color: $dark-blue3;
  }

  .bg-warning--custom {
    background-color: $active-color;
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  .iMore {
    cursor: pointer;
  }

  &-header {
    height: 56px;
    padding: 12px;
    background-color: $dark1-color;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-metric {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
    }

    &__left {
      display: flex;
      flex-direction: row;
      gap: 40px;
    }

    &__right {
      color: $light-color;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        height: 32px;
        border-radius: 16px;
        padding: 6px 16px;
        gap: 4px;

        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  &-main {
    height: 100%;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 36px;
      padding: 12px;
      background-color: $dark2-color;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &-body {
      display: flex;
      flex-direction: column;
      color: $light-color;
      background-color: $dark1-color;
      padding: 12px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      align-items: stretch;
      height: 100%;
      max-height: calc(100vh - 245px);
    }

    &-tab {
      color: $light3-color;

      &.active {
        color: $dark3-color;
        background-color: $active-color;
        padding: 2px 8px;
        border-radius: 16px;
        height: 32px;
      }

      &:not(.active):hover {
        color: $light-color;
      }
    }
  }

  &-main-bankGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    &__body {
      display: flex;
      flex-direction: row;
      column-gap: 12px;
    }

    &__items {
      gap: 12px;
      display: flex;
      flex-direction: column;
      flex: 7;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 12px;
      flex: 4;
      background-color: $dark-color;
      height: 100%;
      border-radius: 4px;
    }
  }

  &-main-bankItem {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px;
    background-color: $dark-color;
    border-radius: 4px;

    .bank-infor {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      flex: 3;

      .bank-logo {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: $light-color;
        background-image: url('../../assets/img/icons/utilities/nologo.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .bank-desc {
        display: flex;
        flex-direction: column;
      }
    }

    .bank-beneficiary {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 4;
    }

    .account-beneficiary {
      display: flex;
      flex-direction: row;
      gap: 12px;
      flex: 3;
    }

    &-showmore {
      background-color: $dark-color;
      height: 44px;
      color: $light3-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;

      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
}
