.pinefolio-theme-detail {
  @extend %text;
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 620px;
  .flex-3 {
    flex: 3 !important;
  }

  &__body {
    min-height: 420px;
  }

  .theme-intro {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__contents {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 32px);
    }

    .intro-tabs {
      display: flex;
      gap: 24px;

      .intro-tab {
        cursor: pointer;
        position: relative;
        height: 24px;
        font-weight: 500;

        &.active {
          &::after {
            content: '';
            border-bottom: 2px solid $active-color;
            width: 25px;
            position: absolute;
            left: 50%;
            bottom: 0%;
            transform: translate(-50%, 0%);
            transition: transform 0.3 ease-in;
          }
        }
      }
    }
  }

  .theme-infors {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;

    &__desc {
      padding-top: 12px;
    }

    &__attachments {
      display: flex;
      gap: 12px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      &.drag-scroll {
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        transition: all 0.2s;
        will-change: transform;
        user-select: none;
        cursor: pointer;
      }
    }

    &__metrics {
      display: flex;
      flex-direction: row;
      gap: 24px;
      padding-top: 12px;
    }

    &__metric {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    &__pinefolios {
      display: flex;
      flex-direction: column;
    }

    .pinefolio-navs {
      display: flex;
      flex-direction: column;
      border-bottom: 1px dashed $dark3-color;
    }

    .pinefolio-overview {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-bottom: 1px dashed $dark3-color;
      padding: 12px 0;
      gap: 12px;

      .pinefolio-metrics {
        display: flex;
        flex-direction: row;
        gap: 24px;
        width: 100%;
      }

      .pinefolio-metric {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__performance {
          display: flex;
          gap: 8px;
        }
      }
    }

    .pinefolio-allocations {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 12px;
      gap: 24px;

      .pinefolio-allocation {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &.slide-right {
        animation: slideRight 100ms ease-in-out;
      }
    }
  }

  .pinefolio-actions {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 22px;
    width: 100%;

    .pinefolio-action {
      flex: 1;
      height: 32px;
      line-height: 27px;
    }
  }

  .theme-reports {
    padding: 0px 0;
    position: relative;
    height: calc(100% - 32px);
    .content-container {
      padding: 0px;
    }
    .analysis-report {
      padding: 0px;
      .document-section {
        height: calc(100%);
      }
      .video-section {
        height: (calc(100% + 32px));
      }
    }
    .table {
      tr {
        height: 32px;
      }
    }
  }
}
