.pinefolio-performance {
  padding: 12px;
  @extend %text;
  background-color: $dark2-color !important;

  &__title {
    flex: 1;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pinefolio-levels {
      flex: 1;
      flex-direction: row-reverse;
    }
  }

  &__body {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .performance-chart {
    padding-top: 12px;
    padding-bottom: 12px;

    &__empty {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background-color: rgba($color: $gray-color, $alpha: 0.2);

      .icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  .performance-index {
    background-color: $dark1-color;
    gap: 12px;
    border-radius: 4px;
  }

  .highcharts-background {
    // background-color: $dark3-color;
    fill: transparent;
  }

  .highcharts-yaxis-grid {
    .highcharts-grid-line {
      stroke-width: 1px;
      stroke: $dark3-color;
      stroke-dasharray: 1, 1;
    }
  }

  .chart-explain {
    display: flex;
    justify-content: space-between;

    &__navigations {
      display: flex;
      gap: 4px;
    }

    &__navigation {
      &.active {
        background-color: rgba($color: $active-color, $alpha: 0.2);
        color: $active-color;
      }
    }

    &__desc {
      display: flex;
      gap: 16px;
    }

    &__items {
      display: flex;
    }

    &__item {
      display: flex;
      gap: 4px;
      align-items: center;
      color: $light3-color;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $light3-color;
      display: inline-block;
    }

    .i {
      .dot {
        background-color: $up-color;
      }
    }
  }
}
