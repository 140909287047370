.derivative {
  .total-asset-content {
    div {
      &:not(:last-child) {
        border-bottom: 1px solid $dark2-color;
      }
    }
  }

  .divider {
    border-top: 1px solid $dark2-color;
  }

  .asset-container {
    display: grid;
    grid-template-columns: 288px auto;
    gap: 24px;
    padding: 12px;

    &--right {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }

    .detail-asset,
    .margin-asset {
      min-width: 534px;
      flex-grow: 1;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $dark2-color;
    background-color: $dark-color;
    font-size: 13px;
    font-weight: 400;
  }

  .popup-fee-tax {
    width: 228px;
    background-color: $dark1-color;
  }

  .iInfor {
    background-color: $dark-blue3;
  }

  thead {
    th {
      padding: 4px 12px;
    }
    tr {
      background-color: $dark2-color;
    }
  }

  tbody {
    td {
      padding: 4px 12px;
      line-height: 20px;
    }
  }

  .warning-threshold {
    display: block;
    position: relative;
    height: 30px;
    background-color: rgba($color: $light3-color, $alpha: 0.2);
    width: 100%;
    .current-ratio {
      position: absolute;
      height: 100%;
      background-color: $up-color;
      z-index: 1;
    }
    .warning-ratio {
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: $ref-color;
      z-index: 2;
    }
    .forcesell-ratio {
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: $down-color;
      z-index: 3;
    }
  }

  .warning-explain {
    display: flex;
    // padding: 12px;
    gap: 12px;
    > div {
      display: flex;
      gap: 6px;
      align-items: center;
    }
    .square {
      display: block;
      width: 6px;
      height: 6px;
    }

    .current-ratio {
      .square {
        background-color: $up-color;
      }
    }

    .warning-ratio {
      .square {
        background-color: $ref-color;
      }
    }

    .forcesell-ratio {
      .square {
        background-color: $down-color;
      }
    }
  }

  .infor-group {
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $dark2-color;
    }

    .infor-item {
      font-size: 13px;
      line-height: 20px;
      display: flex;
      width: 100%;

      &--column {
        flex-direction: column;
        gap: 4px;
      }

      &--label {
        font-weight: 400px;
        color: $light3-color;
      }

      &--value {
        font-weight: 500px;
        color: $light-color;
      }
    }
  }

  .expand-row {
    & > td {
      padding: 0px 40px;
    }

    table {
      th,
      td {
        background-color: $dark3-color;
        border: 1px solid $dark4-color;
      }
    }
  }

  .input-text-search {
    input {
      background-color: $dark-color;
      border-radius: 24px;
      border: 1px solid $dark-color;
      color: $light-color;
      padding: 0px 12px 0px 28px;
      height: 24px;
      width: 102px;
      font-size: 13px;
      &:focus-visible {
        outline: none;
      }
    }
    .icon {
      width: 12px;
      height: 12px;
    }

    span {
      position: absolute;
      margin-left: 8px;
    }
  }

  .filter-datePicker {
    border-radius: 16px;
  }

  &__asset {
    tbody {
      tr:nth-child(even) {
        background-color: $dark2-color;
      }
    }
  }

  &.order-position {
    position: absolute;
    width: 100%;
    bottom: 36px;
    right: 0;
    display: flex;
    flex-direction: row;
    min-height: 300px;
    height: 300px;
    animation: slideUp 100ms ease;
    z-index: 4;

    &.has-order {
      bottom: 392px;
    }
  }
}

.derivative_top-index {
  &--horizontal {
    .top-index {
      height: 100%;
      padding: 12px 8px;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 4px;
      }
      &__item {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: rgba($color: $dark3-color, $alpha: 0.2);

        &.increase {
          justify-content: end;
        }
        &.decrease {
          justify-content: start;
        }

        &--increase {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 11px;
          line-height: 16px;
          justify-content: end;
          .value {
            min-height: 1px;
            display: block;
            width: 100%;
            height: calc(100% - 16px);
            background-color: $up-color;
          }
        }
        &--decrease {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          font-size: 11px;
          line-height: 16px;
          .value {
            min-height: 1px;
            display: block;
            width: 100%;
            height: calc(100% - 16px);
            background-color: $down-color;
          }
        }
      }
    }
  }
  &--vertical {
    .top-index {
      width: 100%;
      padding: 12px 8px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      > div {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: calc(5% - 16px);
        gap: 4px;
      }
      &__item {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        background-color: rgba($color: $dark3-color, $alpha: 0.2);

        &.increase {
          justify-content: end;
        }
        &.decrease {
          justify-content: start;
        }

        &--increase {
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;
          font-size: 11px;
          line-height: 16px;
          gap: 2px;
          .value {
            min-height: 1px;
            display: block;
            width: 100%;
            height: 100%;
            background-color: $up-color;
          }
        }
        &--decrease {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: end;
          font-size: 11px;
          line-height: 16px;
          gap: 2px;
          .value {
            min-height: 1px;
            display: block;
            width: 100%;
            height: 100%;
            background-color: $down-color;
          }
        }
      }
    }
  }
}

.derivative-pl-report {
  td {
    padding: 6px 2px;
  }

  table {
    thead {
      tr {
        &:not(:first-child) {
          td {
            border-top: 1px solid $dark2-color;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          pointer-events: none;
        }

        &:nth-child(4n + 1),
        &:nth-child(4n + 2) {
          td {
            background-color: $dark-color !important;
          }
        }

        &:nth-child(4n),
        &:nth-child(4n + 3) {
          td {
            background-color: $dark1-color !important;
          }
        }
      }
    }

    .bg-dark3 {
      background-color: $dark3-color;
    }
  }
}

.w-20 {
  width: 20% !important;
}

.no-hover {
  pointer-events: none;
}

.w-65 {
  width: 65% !important;
}

.mw-32 {
  min-width: 32px;
}

.w-35 {
  width: 35% !important;
}

.derivative-mpa {
  display: flex;

  .price-list {
    padding: 6px 12px;

    &__title {
      margin-bottom: 6px;
    }

    .price-list-step {
      height: 24px;

      .price {
        top: 5px;
      }
    }
  }

  .match-analystic {
    .grid-table-header {
      padding: 4px 12px;
      height: 28px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      font-style: normal;

      div {
        background-color: transparent;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .grid-table-body {
      // grid-template-rows: auto auto auto;
      // grid-template-columns: 25% 25% 25% 25%;
    }

    .grid-table-rows {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 4px 12px;
    }

    .grid-table-row {
      display: flex;

      &.odd {
        background-color: $dark-color;
      }

      div {
        flex: 1;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }

  .price-analystic {
    padding: 12px;
    font-size: 13px;
    line-height: 20px;
  }
}

.place-order {
  position: absolute;
  width: 100%;
  bottom: 36px;
  right: 0;
  display: flex;
  flex-direction: row;
  min-height: 356px;
  height: 356px;
  animation: slideUp 100ms ease;
  gap: 12px;
  background-color: $dark1-color;
  padding: 8px !important;
  &:focus-visible {
    outline: none;
  }
}

.w-120px {
  width: 120px;
}
.w-150px {
  width: 150px;
}
.w-180px {
  width: 180px;
}

.switch-order-side {
  button {
    height: 32px;
    width: 90px;
    border-radius: unset !important;
    font-weight: 500;
  }
  border-radius: 4px;
  overflow: hidden;
}
