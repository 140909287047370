@import '../assets/scss/helpers/variables';

.paging-size {
  span {
    display: none;
  }

  .Select-arrow-zone {
    display: none;
  }
}
