.checkbox-button {
  position: relative;
  display: flex;
  gap: 12px;
  cursor: pointer;
  padding: 12px 0;

  .cbb-box {
    background: transparent;
    border: 1px solid $gray-color;
    min-width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 4px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      border: 2px solid $up-color;
      border-top: 0;
      border-right: 0;
      background: 0;
      width: 10px;
      height: 7px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -80%) rotate(-45deg);
      display: none;
    }

    &.checked {
      &::after {
        display: block;
      }
    }
  }

  .cbb-text {
    color: $light-blue3;
  }
}

.checkbox2 {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  &__status {
    width: 16px;
    height: 16px;
    border: 2px solid $dark3-color;
    border-radius: 4px;
    background-color: $dark-color;
    display: inline-block;
    position: relative;
  }

  &__status--checked {
    position: absolute;
    border-radius: 2px;
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: $active-color;
    margin: 1px;
  }

  &__text {
    color: $light-color;
    font-size: 13px;
    line-height: 20px;
    font-size: 400;
  }
}

.light .checkbox2 {
  &__status {
    border: 2px solid $light2-color;
    background-color: transparent;
  }

  &__status--checked {
    background-color: $active1-color;
  }

  &__text {
    color: $dark3-color;
  }
}
