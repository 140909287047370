.auth-modal {
  display: flex;
  justify-content: center;

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    background-color: $light3-color;

    &.loading {
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .iResend {
    width: 16px !important;
    height: 16px !important;
  }
}

.auth-content {
  width: 348px;
  min-width: 348px;
  background-color: $dark2-color;
  color: $light-color;
  padding: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  .logo {
    margin-bottom: 24px;
  }

  .iLogo {
    width: 84px;
    height: 32px;
    margin-bottom: 12px;
  }

  .text-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 4px;
    color: $light-color;
  }

  .text-desc {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: $light3-color;
    margin-bottom: 4px;
  }

  .text-otp {
    background-color: $dark-color;
    color: $light-color;
    height: 64px;
    width: 45px;
    border-radius: 8px;
    font-size: 24px;
    border: none;

    & + .text-otp {
      margin-left: 6px;
    }
  }

  .text-error {
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .text-resend {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 36px;
  }

  .text-refresh {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 24px 0;
  }

  .text-learnmore {
    color: $light-blue3;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }

  .download-app {
    margin-top: 16px;
  }

  .otp-section {
    margin-top: 44px;
  }

  .btn {
    height: 48px;
    padding: 12px;
    border-radius: 4px;
    width: 100%;
  }

  .btn-primary {
    margin-top: 24px;
  }

  .change-method-section {
    margin-top: 24px;
  }

  .list-group {
    width: 100%;
    margin-top: 36px;

    .list-group-item {
      background-color: $dark-color !important;
      display: flex;
      gap: 12px;
      padding: 12px;

      & + .list-group-item {
        margin-top: 24px;
      }

      &__icon {
        display: flex;
      }

      &__desc {
        display: flex;
        flex-direction: column;
      }

      &__next {
        display: flex;
      }
    }
  }

  .hotline {
    color: $light3-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 36px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loading-backdrop {
  width: 24px;
  height: 24px;
  margin: 24px auto 0;
  border: solid 4px rgba($light3-color, 0.2);
  border-radius: 50%;
  .circle-loading {
    width: 24px;
    height: 24px;
    margin: -4px 0px 0px -4px;
    border: solid 4px $light3-color;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
