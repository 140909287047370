@import '../../../assets/scss/helpers/variables';

.authen-section {
  //border: 1px solid $dark3-color;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 12px;
  font-size: 13px;
  line-height: 20px;
  text-align: center;

  .authen-type-switches {
    background-color: $dark-color;
    color: $light-color;
    border-radius: 20px;
    height: 36px;
    padding: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 18px;

    .authen-type-switch {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      padding: 4px 12px;
      border-radius: 16px;

      &.active {
        background-color: $active-color;
        color: $dark-color;
      }

      &:not(.active):hover {
        color: $active-color;
      }
    }
  }

  .order-text {
    input {
      text-align: center;
    }
  }

  .authen-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;

    input {
      width: 40px;
      height: 56px;
      padding: 8px;
      border-radius: 8px;
      border: none;
      outline: none;
      box-shadow: none;

      font-size: 28px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;

      background-color: $dark-color;
      color: $light-color;

      &:focus {
        border: 1px solid $gray-color;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .matrix-section {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .matrix-label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        height: 24px;
      }
    }

    .otp-section {
    }
  }

  .text-error {
    padding: 12px 30px;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .resend-section {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    gap: 8px;
    // margin-top: 36px;

    .icon {
      display: block;
      width: 24px;
      height: 24px;
      object-fit: contain;
      background-color: $light3-color;

      &.loading {
        animation: rotate 1s linear infinite;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .iResend {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.border-auth {
  border: 1px solid $dark3-color;
}
