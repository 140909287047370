@import './modal/createPlan';
@import './modal/editPlan';
@import './modal/depositPinefolio';
@import './modal/buyPinefolio';

.pinefolio-page {
  padding: 12px;

  @extend %text;

  .mt-24 {
    margin-top: 24px;
  }

  .planManagement-page {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 12px;
    justify-content: space-between;

    .planProgress {
      margin: 12px 0px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      height: 40%;

      &__header {
        display: flex;
        width: 100%;

        .packInfo {
          padding: 12px;
          background-color: $dark3-color;
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 15%;

          &-item {
            display: flex;
            gap: 8px;
            justify-content: space-between;

            &__name {
              font-weight: 500;
              color: $light3-color;
            }

            &__value {
              font-weight: 500;
              color: $light-color;
            }
          }
        }

        .planCalendar {
          display: flex;
          gap: 12px;
          background-color: $dark2-color;
          flex-direction: column;
          flex: 6 7;

          &-viewPicker {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            align-items: center;
            line-height: 32px;
            padding: 0 12px;

            &--left {
              flex: 1 1;
            }

            &--right {
              display: flex;
              flex: 1 1;
              gap: 2px;
              justify-content: end;
              width: 100%;

              .planCalendar-viewPicker__tab {
                height: 20px;
                line-height: 20px;
                padding: 0px 8px;
                border-radius: 24px;
                align-items: center;

                &.active {
                  background-color: rgba($color: $active-color, $alpha: 0.2);
                  color: $active-color;
                }

                &:not(.active) {
                  cursor: pointer;
                }
              }
            }
          }
          &-timeFrame {
            display: flex;
            gap: 6px;
            align-items: center;
            justify-content: space-around;
            padding: 0;
            .btn {
              display: flex;
              padding: 8px;
              border: none;
            }
            .list-day {
              display: flex;
              gap: 6px;
              justify-content: space-around;
              width: 100%;
              &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                line-height: 20px;
                font-weight: 500px;

                &--date {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 32px;
                  border-radius: 100%;
                  padding: 4px;

                  &:hover {
                    background-color: $light-color;

                    span {
                      color: $dark-color !important;
                    }
                  }
                  &.disabled {
                    span {
                      color: $gray-color !important;
                    }
                    &:hover {
                      background-color: $dark3-color !important;
                    }
                  }
                  &.active {
                    span {
                      color: $active-color !important;
                    }
                    &:hover {
                      background-color: $active-color !important;
                      span {
                        color: $dark-color !important;
                      }
                    }
                  }
                }
              }
            }

            .list-month {
              display: flex;
              gap: 4px;
              width: 100%;

              &__item {
                background-color: $dark1-color;
                padding: 14px 0px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                &--3 {
                  width: calc(33.33333% - 4px);
                }
                &--6 {
                  width: calc(16.66666% - 4px);
                }
                &--9 {
                  width: calc(11.11111% - 4px);
                }
                &--12 {
                  width: calc(8.33333% - 4px);
                }
              }
            }
          }
        }
      }

      &__body {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        gap: 4px;

        .planProgress-item {
          display: flex;

          .portfolio-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $light-color;
            background-color: rgba($color: $light-color, $alpha: 0.2);
            width: 15%;
            gap: 11px;
            padding: 2px 12px 2px 12px;
            span {
              overflow: hidden;
              white-space: nowrap;
            }
            &--rebalance {
              background-color: rgba(
                $color: $down-color,
                $alpha: 0.2
              ) !important;
              color: $down-color !important;
              .icon {
                background-color: $down-color !important;
              }
            }
            &--rebalance {
              background-color: rgba(
                $color: $down-color,
                $alpha: 0.2
              ) !important;
              color: $down-color !important;

              .icon {
                background-color: $down-color !important;
              }
            }

            .icon {
              width: 12px;
              height: 12px;
              background-color: $light-color;
            }
          }

          .portfolio-progress {
            display: flex;
            flex: 6 7;
            background-color: $dark2-color;
            position: relative;
            overflow: hidden;
            width: 100%;

            .pack-progress {
              display: flex;
              position: absolute;
              height: 24px;
              font-weight: 500;
              &--completed {
                background-color: rgba($color: $up-color, $alpha: 0.2);
                color: $up-color;
              }
              &--new {
                background-color: rgba($color: $light-blue3, $alpha: 0.2);
                color: $light-blue3;
              }
              &--executing {
                background-color: rgba($color: $light-blue3, $alpha: 0.2);
                color: $light-blue3;
              }

              &--rebalance {
                background-color: rgba($color: $down-color, $alpha: 0.2);
                color: $down-color;
              }
              &--inprocess {
                border-radius: 32px 32px 32px 32px;
              }
              &--started {
                border-radius: 0px 32px 32px 0px;
              }
              &--unfinished {
                border-radius: 32px 0px 0px 32px;
              }
              &--x {
                border-radius: 0px 0px 0px 0px;
              }
              &--hidden {
                display: none !important;
              }
              .pack-name {
                text-overflow: clip;
                overflow: hidden;
              }
              span {
                padding: 2px 12px;
              }
            }
          }
          &__buffer {
            display: none;
            width: 32px;
            height: 100%;
            position: absolute !important;

            &--completed {
              background-color: rgba($color: $up-color, $alpha: 0.2);
              color: $up-color;
            }

            &--new {
              background-color: rgba($color: $light-blue3, $alpha: 0.2);
              color: $light-blue3;
            }
            &--rebalance {
              background-color: rgba($color: $down-color, $alpha: 0.2);
              color: $down-color;
            }
            &--executing {
              background-color: rgba($color: $light-blue3, $alpha: 0.2);
              color: $light-blue3;
            }
            &--started {
              border-radius: 0px 32px 32px 0px;
            }
            &--unfinished {
              border-radius: 32px 0px 0px 32px;
            }
            &--process {
              border-radius: 0px 0px 0px 0px;
            }
          }
        }
      }
    }

    .planHistory {
      display: flex;
      flex-direction: column;
      padding: 12px 0px;
      gap: 12px;
      min-height: 60%;
      max-height: 60%;
      &__header {
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--left {
          display: flex;
          gap: 60px;
          justify-content: flex-start;
          .header-tab {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
          }
        }

        &--right {
          display: flex;
          justify-content: flex-end;
        }
      }
      &__body {
        height: calc(100% - 28px);
      }
    }
  }

  .filter-control-select {
    font-size: 13px;
    font-weight: 500;
    width: 100%;

    &__control {
      background-color: $dark-color;
      height: 24px;
      min-height: 24px;
      outline: none;
      border: none;
      box-shadow: none;
    }

    &__menu {
      background-color: $dark-color;
      width: 100%;
      border-top-left-radius: 5;
      border-top-right-radius: 5;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
    }

    &__single-value {
      font-size: 13px;
      padding: 2px;
      color: $light-color;
    }

    &__option {
      color: $light1-color;
      background-color: $dark-color;
      padding: 5px 8px;
      font-size: 13px;
      text-align: start;

      &--is-selected {
        color: #e2e2e3;
        background-color: $dark4-color;
      }

      &:hover {
        background-color: $dark3-color;
      }
    }

    &__value-container,
    &__indicators {
      height: 24px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $light-color;
    }
  }

  tr {
    td,
    th {
      line-height: 20px;
      height: 32px;
      span {
        align-items: center;
      }
    }
  }
}

.pinefolio-product {
  height: calc(100vh - 160px);
  padding-bottom: 12px;

  &-header {
    margin-top: 24px;
    margin-bottom: 12px;
    margin-left: 12px;
  }

  .theme {
    height: 380px;
    background-color: $dark2-color;
    border-radius: 4px;
    overflow: hidden;

    &-header {
      position: relative;
      height: 80%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &__name {
        position: absolute;
        left: 12px;
        bottom: 12px;
        right: 12px;

        color: $light-color;
        font-size: 20px;
        line-height: 32px;
      }
    }

    &-body {
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-infors {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 12px;
      gap: 12px;
    }

    &-infor {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__name {
      position: absolute;
    }
  }

  .pinefolio-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .pinefolio-card {
    padding: 12px;
    width: 25%;
    position: relative;

    &__header {
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        top: 60%;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(
          to bottom,
          transparent,
          rgba(0, 0, 0, 0.5)
        );
        z-index: 1;
      }
    }

    span {
      z-index: 2;
    }
  }
}

.group--btn {
  display: flex;
  gap: 8px;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    &--hug {
      height: 32px;
    }
  }
}

.gap-3 {
  gap: 12px;
}

.w-280 {
  width: 280px;
}

.w-328 {
  width: 328px;
}

.w-60 {
  width: 60%;
}

.plan-detail-modal {
  width: 948px !important;
}

.header__tabs {
  display: flex;
  background-color: $dark-color;
  justify-content: flex-start;
  width: fit-content;

  &--type1 {
    border-radius: 32px;
  }

  .header__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &.active {
      background-color: $active-color;
      color: $dark3-color;
    }

    &--hug {
      border-radius: 32px;
      width: 120px;
      height: 32px;
    }
  }
}
.buffer-end {
  left: 100%;
  transform: translateX(-100%);
}

.buffer-start {
  left: 0%;
}
