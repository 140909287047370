.checkbox-group {
  position: relative;
}

.checkbox-group .custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  top: 7px;
}

.custom-checkbox ~ .custom-checkbox-span {
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #005581;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
}

.custom-checkbox:checked ~ .custom-checkbox-span {
  background-color: #005581;
}

.custom-checkbox:checked ~ .custom-checkbox-span:after {
  content: '\2713';
  font-size: 0.75rem;
  font-weight: 500;
  position: relative;
  top: -6px;
  left: 2px;

  color: rgb(255, 255, 255);
}
