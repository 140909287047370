%group-control {
  .group-control {
    display: flex;
    justify-content: space-between;
    background-color: $dark-color;
    border-radius: 4px;
    height: 32px;

    &--left {
      display: flex;
      width: 20%;
    }

    &--right {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 8px;
    }

    .select {
      // width: 100%;
      width: 70px;
      min-width: 50px;

      .sDerivative {
        &__control {
          background-color: transparent;
          border: none;
          min-height: 32px;
          height: 32px;
        }

        &__option {
          color: $light1-color;
          background-color: $dark-color;
          padding: 5px 8px;
          font-size: 13px;
          text-align: start;

          &--is-selected {
            color: $light-color;
            background-color: $dark4-color;
          }

          &:hover {
            background-color: $dark3-color;
          }
        }

        &__input {
          color: $light-color;
        }

        &__menu {
          background-color: $dark-color;
          width: 100%;
          border-top-left-radius: 5;
          border-top-right-radius: 5;
          z-index: 2;
          position: absolute;
          margin-top: 5px;
          max-height: 200px !important;
          &-list {
            max-height: 200px !important;
          }
        }

        &__single-value {
          color: $light-color;
          font-size: 13px;
          line-height: 20px;
        }

        &__value-container,
        &__indicators {
          height: 32px;
        }

        &__indicator-separator {
          display: none;
        }
      }
    }

    input {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: $light-color;
      text-align: center;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;

      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }

    .iMoney,
    .iVolume {
      width: 12px;
      height: 12px;
    }
  }
}

.derivative-order {
  @extend %text;
  display: flex;
  height: 100%;

  &__normal {
    overflow: auto;
    padding: 12px;
    // background-color: $dark1-color;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .iEdit {
      display: block;
      margin-bottom: 3px;
      width: 12px;
      height: 12px;
      color: $light3-color;
      object-fit: contain;
    }

    .derivative-dropdown {
      position: relative;
      background-color: $dark1-color;
      color: $light-color;
      max-height: 190px;
      overflow-y: auto;
      padding: 0px;
      width: 160px;
      border: 1px solid $dark2-color;

      .list-unstyled {
        margin-bottom: 0;
      }

      .dropdown-item {
        color: $light-color;
        padding: 8px 12px;
        font-size: 13px;
      }

      .form-control:focus {
        box-shadow: none;
        border: 1px solid $light-blue3;
      }

      .dropdown-item:focus,
      .dropdown-item:hover {
        background-color: #585a5d;
      }

      .dropdown-item.active,
      .dropdown-item:active {
        text-decoration: none;
        background-color: rgba(255, 231, 11, 0.2);
      }
    }

    .search-container {
      display: flex;
      justify-content: center;
      background-color: $dark1-color;
      align-items: center;
      position: sticky;
      padding: 6px 12px;
      top: 0;
      border-bottom: 1px solid;
      border-color: $dark2-color;
    }

    .contract-form {
      border-radius: 4px;
      background-color: #05090b;
      border-color: transparent;
      color: $light-color;
      // text-transform: uppercase;
      height: 28px;
      padding: 10px;
      font-size: 13px;
    }

    .symbol {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__infor {
        color: $light-color;
        display: flex;
        gap: 6px;
        font-size: 16px;
        align-items: center;
        cursor: pointer;
      }

      &__price {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &__change {
        display: flex;
        gap: 8px;
      }
    }

    @extend %group-control;

    .max {
      display: flex;
      justify-content: space-between;
    }

    .actions {
      display: flex;
      gap: 12px;
      position: absolute;
      bottom: 12px;
      right: 12px;
      left: 12px;
      z-index: 0;

      .btn {
        flex: 1;
        height: 32px;
      }
    }
  }

  &__quick {
    @extend %group-control;

    padding: 12px;
    // background-color: $dark1-color;
    position: relative;
    display: flex;
    height: 100%;
    gap: 24px;
    flex-direction: row;

    &--left,
    &--right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;
    }

    .max {
      display: flex;
      justify-content: space-between;
    }

    .iEdit {
      display: block;
      margin-bottom: 3px;
      width: 12px;
      height: 12px;
      color: $light3-color;
      object-fit: contain;
    }

    .symbol {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      &__infor {
        color: $light-color;
        display: flex;
        gap: 6px;
        font-size: 16px;
        align-items: center;
        cursor: pointer;
      }
    }

    .derivative-dropdown {
      position: relative;
      background-color: $dark1-color;
      color: $light-color;
      max-height: 190px;
      overflow-y: auto;
      padding: 0px;
      width: 160px;
      border: 1px solid $dark2-color;

      .list-unstyled {
        margin-bottom: 0;
      }

      .dropdown-item {
        color: $light-color;
        padding: 8px 12px;
        font-size: 13px;
      }

      .form-control:focus {
        box-shadow: none;
        border: 1px solid $light-blue3;
      }

      .dropdown-item:focus,
      .dropdown-item:hover {
        background-color: #585a5d;
      }

      .dropdown-item.active,
      .dropdown-item:active {
        text-decoration: none;
        background-color: rgba(255, 231, 11, 0.2);
      }
    }

    .search-container {
      display: flex;
      justify-content: center;
      background-color: $dark1-color;
      align-items: center;
      position: sticky;
      padding: 6px 12px;
      top: 0;
      border-bottom: 1px solid;
      border-color: $dark2-color;
    }

    .contract-form {
      border-radius: 4px;
      background-color: #05090b;
      text-align: left !important;
      border-color: transparent;
      color: $light-color;
      // text-transform: uppercase;
      height: 28px;
      padding: 10px;
      font-size: 13px;
    }

    .quantity-pc {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      justify-content: space-between;

      .btn {
        width: calc(25% - 4px);
        padding: 2px 4px;
      }
    }

    .actions {
      display: flex;
      gap: 12px;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;

      .btn {
        display: block;
        width: 100%;
        height: 32px;
        font-weight: 600;
      }
    }
  }

  .types {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 6px;
    flex-wrap: wrap;

    .btn {
      padding: 2px 6px;
    }
  }

  .checkbox-derivative {
    .custom-checkbox ~ .custom-checkbox-span {
      border: 2px solid;
      border-color: $dark3-color;
      color: $active-color;
      background: unset;
    }
    .custom-checkbox ~ .custom-checkbox-span::after {
      content: '';
      position: absolute;
      display: none;
    }
    .custom-checkbox:checked ~ .custom-checkbox-span {
      background: $active-color;
    }
  }

  .type {
    flex: 1 0 40px;

    &.active {
      background-color: $active-color;
      color: $dark3-color;
    }
  }

  .iCeil2,
  .iRef2,
  .iFloor2 {
    width: 13px;
    height: 13px;
  }

  .iCeil2 {
    background-color: $ceil-color !important;
  }

  .iRef2 {
    background-color: $ref-color !important;
  }

  .iFloor2 {
    background-color: $floor-color !important;
  }

  .popup-setting {
    position: absolute;
    width: max-content;
    border-radius: 4px;
    padding: 12px 12px 12px 0px;
    background-color: $dark2-color;
    right: 8px;
    top: 16px;
    z-index: 2;
    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .options {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .title {
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
      }
      .option {
        display: flex;
        gap: 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        align-items: center;
      }
    }
  }
}

.chart-layout {
  display: grid;
  height: 100%;
  width: 100%;
  background-color: $dark-color;

  .section {
    box-sizing: border-box;

    &-border {
      border: 2px solid $light-blue3;
    }
  }

  &-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  &-2 {
    gap: 12px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  &-2-vertical {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  &-4 {
    gap: 6px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.light {
  .chart-layout {
    background-color: $light-color;
    .section {
      &-border {
        border: 1px solid $dark-blue3;
      }
    }
  }
  .derivative-order {
    .group-control {
      background-color: $light-color;

      .select {
        .sDerivative {
          &__control {
            background-color: transparent;
          }
          &__option {
            color: $dark-color;
            background-color: $light2-color;
            &--is-selected {
              color: $dark-color;
              background-color: $white-color;
            }

            &:hover {
              background-color: $light-color;
            }
          }
          &__input {
            color: $dark-color;
          }
          &__single-value {
            color: $dark3-color;
          }
          &__menu {
            background-color: $light2-color;
          }
        }
      }

      input {
        background-color: $light-color;
        color: $dark3-color;
      }
    }

    .checkbox-derivative {
      .custom-checkbox ~ .custom-checkbox-span {
        color: $active1-color;
        border-color: $light2-color;
      }
      .custom-checkbox:checked ~ .custom-checkbox-span {
        background: $active1-color;
      }
    }

    .types {
      .type {
        &.active {
          background-color: $active1-color;
          color: $dark3-color;
        }
      }
    }

    // .quantity-pc {
    // }

    &__normal {
      .symbol {
        &__infor {
          color: $dark3-color;
        }
      }
      .derivative-dropdown {
        background-color: white;
        color: $dark3-color;

        .iEdit {
          color: $gray-color;
        }

        .dropdown-item {
          color: $dark3-color;
        }

        .dropdown-item:focus,
        .dropdown-item:hover {
          background-color: $light-color;
        }

        .dropdown-item.active,
        .dropdown-item:active {
          text-decoration: none;
          background-color: rgba(255, 231, 11, 0.2);
        }
      }

      .search-container {
        background-color: white;
        border-bottom: 1px solid;
        border-color: $light-color;
      }

      .contract-form {
        background-color: #f2f2f3;
        color: $dark3-color;
      }
    }

    &__quick {
      .symbol {
        &__infor {
          color: $dark3-color;
        }
      }
      .derivative-dropdown {
        background-color: white;
        color: $dark3-color;

        .iEdit {
          color: $gray-color;
        }

        .dropdown-item {
          color: $dark3-color;
        }

        .dropdown-item:focus,
        .dropdown-item:hover {
          background-color: $light-color;
        }

        .dropdown-item.active,
        .dropdown-item:active {
          text-decoration: none;
          background-color: rgba(255, 231, 11, 0.2);
        }
      }

      .search-container {
        background-color: white;
        border-bottom: 1px solid;
        border-color: $light-color;
      }

      .contract-form {
        background-color: #f2f2f3;
        color: $dark3-color;
      }
    }
  }
}
