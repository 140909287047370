.competition {
  a.nav-link {
    &.active::after {
      display: none !important;
    }
  }

  &.nav-item {
    &:hover {
      background-color: unset !important;
    }
  }
}
.margin-competition {
  a.nav-link {
    &.active::after {
      display: none !important;
    }
  }

  &.nav-item {
    &:hover {
      background-color: unset !important;
    }
  }

  .general-intro {
    display: flex;
    justify-content: center;
    padding-bottom: 12px;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      min-width: 60%;
      justify-content: center;

      .banner {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        width: 100%;

        .organizer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &__logo {
            display: flex;
            align-items: end;
            gap: 15px;

            div {
              background-size: cover;
            }

            .pinetree {
              height: 32px;
              width: 85px;
            }

            .cafeF {
              height: 24px;
              width: 108px;
            }
          }
        }

        .title {
          display: flex;

          .iMrBanner {
            width: 627px;
            height: 67.8px;
            object-fit: contain;
            background-image: linear-gradient(338deg, #2db25b 0%, #f0ae03 100%);
          }
        }

        .overview {
          display: flex;
          gap: 24px;
          padding-bottom: 12px;

          .overview-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 214px;
            padding: 0px 24px;
            font-size: 13px;
            font-weight: 500;

            span {
              text-align: center;
            }

            .icon {
              width: 48px;
              height: 48px;
              background-color: $dark-blue3;
            }
          }
        }
      }

      .content {
        display: flex;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        width: 912px;
      }

      .round-infor {
        width: 100%;
        display: flex;
        gap: 24px;

        .round {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 24px;
          border: 1px solid $dark3-color;
          border-radius: 4px;
          color: $gray-color;

          #time-remain {
            color: $active-color;
          }

          &-title {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
          }

          &.current-round {
            width: 100%;
            .b-dashed {
              border: 1px dashed !important;
            }

            .b-gray {
              border-color: $gray-color;
            }
          }

          .btn {
            padding: 6px 24px;
            color: $light3-color;
          }
        }

        .badge {
          background-color: $dark3-color;
          border-radius: 4px;
          line-height: 20px;
          width: 100%;
          font-size: 13px;
          color: $light3-color;
        }
      }

      .form-register {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        padding: 12px;
        margin-top: 12px;
        background-color: $dark2-color;
        border-radius: 4px;

        .account-select {
          width: 288px;

          .filter-control-select {
            &__control {
              height: 32px !important;
            }

            &__indicators {
              height: 32px;
            }
          }
        }

        .btn {
          padding: 6px 12px;
        }

        .w-288 {
          width: 288px;
        }

        .btn--cancel {
          padding: 6px 24px;
        }
      }

      .btn {
        padding: 6px 12px;
      }
    }
  }

  .rule-award {
    padding: 12px auto;
    .award {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      .super-prize {
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
        background-color: $dark2-color;
        border-radius: 4px;
        padding: 24px;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 8px;
          width: 95%;
          left: auto;
          height: 1px;
          background: linear-gradient(
            to left,
            rgba(255, 231, 11, 0) 0%,
            #ffe70b 50.52%,
            rgba(255, 231, 11, 0) 100%
          );
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 8px;
          width: 95%;
          left: auto;
          height: 1px;
          background: linear-gradient(
            to left,
            rgba(255, 231, 11, 0) 0%,
            #ffe70b 50.52%,
            rgba(255, 231, 11, 0) 100%
          );
        }
        .icon {
          width: 48px;
          height: 48px;
          padding: 0px 48px;
        }

        .iPrize2 {
          background-color: $active-color;
        }
      }
      .groups-prize {
        display: flex;
        gap: 12px;
        padding: 12px 0px;
        align-items: center;
        justify-content: center;
        .group-prize {
          display: flex;
          flex-direction: column;
          gap: 24px;
          background-color: $dark2-color;
          border-radius: 4px;
          padding: 24px;

          .groupName {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 160% */
          }

          &.whale-group {
            .groupName {
              color: #3279af;
            }
            .icon {
              span {
                width: 64px;
                height: 64px;
              }
            }
          }

          &.koi-group {
            .groupName {
              color: #f47b2a;
            }
            .icon {
              span {
                width: 60px;
                height: 64px;
              }
            }
          }

          &.carp-group {
            .groupName {
              color: #a5c6b8;
            }
            .icon {
              span {
                width: 64px;
                height: 44.66px;
              }
            }
          }
          .group-infor {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            .icon {
              width: 88px;
              height: 88px;
              border-radius: 100%;
              background-color: $dark3-color;
              align-items: center;
              justify-content: center;
              span {
                background-size: cover;
              }
            }
          }
          .prize-infor {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 24px;
            div {
              position: relative;
              &:first-child() {
                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  top: -12px;
                  width: 95%;
                  left: auto;
                  height: 1px;
                  background: linear-gradient(
                    to left,
                    rgba(255, 231, 11, 0) 0%,
                    #ffe70b 50.52%,
                    rgba(255, 231, 11, 0) 100%
                  );
                }
              }
              &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: -12px;
                width: 95%;
                left: auto;
                height: 1px;
                background: linear-gradient(
                  to left,
                  rgba(255, 231, 11, 0) 0%,
                  #ffe70b 50.52%,
                  rgba(255, 231, 11, 0) 100%
                );
              }
            }
          }
        }
      }
    }
    .other-endow {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      width: 60%;
      padding-bottom: 24px;
      .endow-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $dark2-color;
        padding: 12px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $light3-color;
      }
    }
    .text-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 150% */
      color: $light-color;
    }
    .award-rule {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
    }
    .recept-award {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      padding-bottom: 24px;
    }
    li {
      font-weight: 400;
      margin-left: -20px;
    }

    .btn {
      padding: 6px 24px;
    }
  }

  .w-65 {
    width: 65% !important;
  }
  .w-70 {
    width: 70% !important;
  }

  .gap-12 {
    gap: 12px;
  }

  .gap-48 {
    gap: 48px;
  }

  .gap-24 {
    gap: 24px !important;
  }

  .lh-32 {
    line-height: 32px;
  }

  .lh-20 {
    line-height: 20px;
  }

  .lh-36 {
    line-height: 36px !important;
  }

  .lh-56 {
    line-height: 56px !important;
  }

  .gap-24 {
    gap: 24px !important;
  }
}
