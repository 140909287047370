.pinefolio-order-list {
  margin: 0px 12px;
  .table {
    thead {
      th {
        background-color: $dark1-color;
      }
    }
    tr {
      &:nth-child(even) {
        &.expand-row {
          background-color: $dark-color !important;
          border: 1px solid $dark2-color;
          .text--light {
            color: $light-color;
          }
          > td {
            padding: 12px 40px;
            color: $light3-color;
          }

          td {
            th {
              background-color: $dark1-color !important;
              border: 1px solid $dark2-color !important;
            }

            td {
              padding: 5px 6px;
              background-color: $dark1-color !important;
              border: 1px solid $dark2-color !important;
              th,
              td {
                background-color: $dark3-color !important;
                border: 1px solid $dark4-color !important;
              }
            }
          }
          .no-hover {
            &:hover {
              background-color: unset !important;
            }
          }
        }
      }
      &:nth-child(odd) {
        &.expand-row {
          background-color: $dark1-color !important;
          border: 1px solid $dark2-color;
          .text--light {
            color: $light-color;
          }
          > td {
            padding: 12px 40px;
            color: $light3-color;
          }

          td {
            th {
              background-color: $dark1-color !important;
              border: 1px solid $dark2-color !important;
            }

            td {
              padding: 5px 6px;
              background-color: $dark1-color !important;
              border: 1px solid $dark2-color !important;
              td,
              th {
                background-color: $dark3-color !important;
                border: 1px solid $dark4-color !important;
              }
            }
          }
          .no-hover {
            &:hover {
              background-color: unset !important;
            }
          }
        }
      }
    }
  }

  .expand-row--lv2 {
    background-color: $dark-color !important;
    .text--light {
      color: $light-color;
    }
    > td {
      padding: 12px 40px !important;
      color: $light3-color;
    }

    td {
      th {
        background-color: $dark3-color !important;
        border: 1px solid $dark4-color !important;
      }

      td {
        padding: 5px 6px;
        background-color: $dark3-color !important;
        border: 1px solid $dark4-color !important;
      }
    }
    .no-hover {
      &:hover {
        background-color: unset !important;
      }
    }
  }
}

.layout-page {
  font-size: 13px;
  line-height: 20px;
  height: calc(100vh - 160px);

  &__body {
    flex-direction: column;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 160px);
    padding: 0px 12px;
  }

  .layout {
    &__header {
      display: flex;
    }

    &__body {
      display: flex;
      padding-top: 12px;
    }

    &__filters {
      display: flex;
      gap: 10px;
      width: 100%;
    }

    &__filter {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      &.button-group {
        flex: 3;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
      }

      button {
        border: none;

        &:focus {
          outline: none;
        }
      }

      input {
        height: 28px;
        background-color: $dark-color;
        border: none;
        outline: none;
        border-radius: 4px;
        color: $light-color;
        padding: 0 8px;
        width: 100%;
      }
      .input-text-search {
        input {
          background-color: $dark-color;
          border-radius: 4px;
          border: 1px solid $dark-color;
          color: $light-color;
          padding: 1px 12px 1px 28px;
          &:focus-visible {
            outline: none;
          }
        }

        span {
          position: absolute;
          margin-left: 8px;
        }
      }

      .filter-control-select {
        font-size: 13px;
        font-weight: 500;

        &__control {
          background-color: $dark-color;
          height: 28px;
          min-height: 28px;
          outline: none;
          border: none;
          box-shadow: none;
        }

        &__menu {
          background-color: $dark-color;
          width: 100%;
          border-top-left-radius: 5;
          border-top-right-radius: 5;
          z-index: 2;
          position: absolute;
          margin-top: 5px;
        }

        &__single-value {
          font-size: 13px;
          padding: 2px;
          color: $light-color;
        }

        &__option {
          color: $light1-color;
          background-color: $dark-color;
          padding: 5px 8px;
          font-size: 13px;
          text-align: start;

          &--is-selected {
            color: #e2e2e3;
            background-color: $dark4-color;
          }

          &:hover {
            background-color: $dark3-color;
          }
        }

        &__value-container,
        &__indicators {
          height: 28px;
        }

        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: $light-color;
        }
      }

      .filter-control-calendar {
        position: relative;
        display: flex;
        align-items: center;

        .react-datepicker-wrapper {
          width: 100%;
          position: relative;
        }

        .iCalendar {
          position: absolute;
          right: 8px;
        }
      }

      .iBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-color;
        height: 28px;
        min-width: 48px;
        border-radius: 4px;
        cursor: pointer;

        &--primary {
          background-color: $dark-blue3;
          width: 100%;
        }

        &--secondary {
          background-color: $dark3-color;
        }
      }
    }
  }
  &__footer {
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 0px;
    margin-top: 12px;
    padding: 12px 0px;
    width: -webkit-fill-available;
  }
}
