@import '../../assets/scss/helpers/variables';
.trading {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 110px);
  margin-top: 12px;
  background-color: $dark1-color;

  .input-text-search {
    background-color: $dark1-color;
    border-radius: 24px;

    input {
      width: 120px;
      background-color: $dark1-color;
      border-radius: 24px;
      border: 1px solid $dark1-color;
      color: $light-color;
      padding: 2px 12px 2px 28px;
      height: 20px;

      &:focus-visible {
        outline: none;
      }
    }

    span {
      position: absolute;
      margin-left: 8px;
    }
  }

  &-main {
    height: 100%;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      gap: 8px;
      padding: 8px;
      background-color: $dark1-color;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      &__left {
        gap: 8px;
        display: flex;
        align-items: center;
        .btn {
          border-radius: 24px;
        }
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 12px;

        .btn {
          border-radius: 24px !important;
        }

        .icon {
          margin-right: 12px;
          background-color: $light1-color;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      color: $light-color;
      background-color: $dark1-color;
      padding: 12px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      align-items: stretch;
      height: calc(100% - 35px);
    }

    &-tab {
      color: $light3-color;
      height: 24px;
      padding: 2px 8px;

      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;

      &.active {
        color: $dark3-color;
        background-color: $active-color;
        border-radius: 16px;
      }

      &:not(.active):hover {
        color: $light-color;
      }
    }
    .btn {
      border: none;
    }
    table {
      .btn {
        padding: 5px 15px !important;
        .icon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .trading-filters {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    background-color: $dark2-color;
    padding: 4px 8px;

    &__select {
      min-width: 150px;
    }

    &--right {
      display: flex;
      gap: 8px;
    }
  }

  .filter-datePicker {
    height: 24px !important;
    border-radius: 24px;
    background-color: $dark1-color;
    color: $light-color;
  }

  .filter-control-select {
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    padding: 0px 12px;

    &__control {
      background-color: $dark1-color;
      height: 24px;
      min-height: 24px;
      outline: none;
      border: none;
      box-shadow: none;
      border-radius: 24px;
    }

    &__menu {
      background-color: $dark1-color;
      width: 100%;
      border-top-left-radius: 5;
      border-top-right-radius: 5;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
    }

    &__single-value {
      font-size: 13px;
      padding: 2px;
      color: $light-color;
    }

    &__option {
      color: $light1-color;
      background-color: $dark1-color;
      padding: 5px 8px;
      font-size: 13px;
      text-align: start;

      &--is-selected {
        color: #e2e2e3;
        background-color: $dark4-color;
      }

      &:hover {
        background-color: $dark3-color;
      }
    }

    &__value-container,
    &__indicators {
      height: 24px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $light-color;
    }
  }

  .expand-row {
    .text--light {
      color: $light-color;
    }
    > td {
      padding: 12px 40px;
      color: $light3-color;
    }

    td {
      th {
        background-color: $dark3-color;
        border: 1px solid $dark4-color !important;
      }

      td {
        padding: 5px 6px;
        border: 1px solid $dark4-color !important;
        background-color: $dark3-color;
      }
    }
    .no-hover {
      &:hover {
        background-color: unset !important;
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #1d2022;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    display: block;
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    right: 2px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 6px;
    background-color: #555;
    border-radius: 6px;
    position: absolute;
    top: 0px;
    height: 85px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  .text {
    &--light3 {
      color: $light3-color;
    }
  }

  .put-through-order {
    td {
      line-height: 22px;
    }
  }
}
