.margin-competition {
  .performance {
    .performance {
      &__body {
        display: flex;
        flex-direction: row;
        padding: 12px 0px;
        &--left {
          width: 75%;
          display: flex;
          gap: 24px;
          flex-direction: column;
          .performance-chart {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .highcharts-background {
              fill: transparent;
            }

            .highcharts-yaxis-grid {
              .highcharts-grid-line {
                stroke-width: 1px;
                stroke: $dark3-color;
                stroke-dasharray: 1, 1;
              }
            }

            .chart-explain {
              display: flex;
              justify-content: space-between;

              .chart-tab {
                display: flex;
                gap: 10px;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;

                .active {
                  color: $light-color;
                }
              }

              .chart-metrics {
                display: flex;
                align-items: center;
                gap: 24px;
                .metric__value {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  .circle {
                    border-radius: 100%;
                    width: 8px;
                    height: 8px;
                    display: block;
                  }

                  &.investment-effect {
                    color: $up-color;
                    .circle {
                      background-color: $up-color;
                    }
                  }
                  &.index {
                    color: $light3-color;
                    .circle {
                      background-color: $light3-color;
                    }
                  }
                }
              }
            }
          }
          .performance-warning {
            background-color: rgba($color: $down-color, $alpha: 0.2);
            color: $down-color;
            padding: 6px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            border-radius: 4px;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            .iWarning {
              background-color: $down-color;
              width: 12px;
              height: 12px;
            }
          }
          .performance-detail {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            &.whale-group,
            &.koi-group,
            &.carp-group {
              .group-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 100%;
                span {
                  width: 32px;
                  height: 32px;
                  background-size: cover;
                }
              }
            }

            &.whale-group {
              .brd-name {
                color: #3279af;
              }
            }

            &.koi-group {
              .brd-name {
                color: #f47b2a;
              }
            }

            &.carp-group {
              .brd-name {
                color: #a5c6b8;
              }
            }

            .detail-item {
              align-items: center;
              display: flex;
              font-size: 13px;
              font-weight: 500;
              line-height: 20px;
              width: calc(25% - 12px);
              gap: 4px;
              &__infor {
                padding: 2px 0px;
                display: flex;
                flex-direction: column;
                gap: 4px;
              }

              .iArrowUp {
                width: 8px;
                height: 8px;
                background-color: $up-color;
              }
              .iArrowDown {
                width: 8px;
                height: 8px;
                background-color: $down-color;
              }
            }
          }
        }
        .horizon-y {
          margin: 0px 12px;
          height: 100%;
          border-left: 1px solid $dark3-color;
        }
        &--right {
          width: 25%;
          padding-right: 12px;
          .allocation {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            gap: 24px;
            &-asset {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 12px;
              width: 100%;
              padding: 0 12px;
              height: calc(100% - 250px);

              table {
                width: 100%;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
                .w-30 {
                  width: 30%;
                }
                th,
                td {
                  padding: 12px 0px;
                  &:not(:first-child) {
                    padding-left: 12px;
                  }
                  .dot {
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    margin-right: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
