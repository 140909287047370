@import '../../../assets/scss/helpers/variables';

.confirm-order-modal-content {
  background-color: transparent;
  border: none;
  border-radius: none;
}

.confirm-order {
  &-header {
    height: 40px;
    background-color: $dark3-color;
    color: $light-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  &-body {
    background-color: $dark1-color;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $light-color;

    &__infor {
      display: flex;
      gap: 20px;

      &-title {
        color: $light2-color;
      }

      &-value {
        color: $light-color;
      }
    }

    &__detail {
      background-color: $dark2-color;
      border-radius: 4px;
      border: none;
      height: 32px;
      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;

      .order {
        padding: 4px 16px;

        & + .order {
          border-left: 1px solid $dark-color;
        }

        &-price,
        &-quantity {
          background-color: $dark3-color;
          color: $light-color;
        }
      }

      .order-type {
        .cbadge {
          padding: 2px 10px;
          border-radius: 2px;
          width: 40px;

          &.i {
            background-color: $up-color;
            color: $dark-color !important;
          }

          &.d {
            background-color: $down-color;
            color: $light-color !important;
          }
        }
      }
    }

    &__action {
      margin-top: 20px;

      button {
        & + button {
          margin-left: 8px;
        }

        color: $light-color;

        outline: none;
        border: none;
        box-shadow: none;
        border-radius: 4px;

        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        padding: 8px 16px;
      }

      .btn-confirm {
        background-color: $dark-blue3;
        color: $light-color;

        &--buy {
          background-color: $up-color;
          color: $dark-color;
          font-weight: 500;
        }

        &--sell {
          background-color: $down-color;
          color: $light-color;
          font-weight: 500;
        }

        &:focus {
          background-color: rgba($color: $dark-blue3, $alpha: 0.6);
        }
      }

      .btn-cancel {
        background-color: $gray-color;
        &:focus {
          background-color: rgba($color: $gray-color, $alpha: 0.6);
        }
      }
    }
  }
}

.light {
  .confirm-order {
    &-header {
      background-color: $light-color;
      color: $dark3-color;
    }

    &-body {
      background-color: $white-color;
      color: $dark3-color;

      &__infor {
        &-title {
          color: $gray-color;
        }

        &-value {
          color: $dark3-color;
        }
      }

      &__detail {
        background-color: $light-color;

        .order {
          & + .order {
            border-left: 1px solid $light1-color;
          }

          &-price,
          &-quantity {
            background-color: $light-color;
            color: $dark3-color;
          }
        }

        .order-type {
          .cbadge {
            &.i {
              background-color: $up1-color;
              color: $dark3-color !important;
            }

            &.d {
              background-color: $down1-color;
              color: $light-color !important;
            }
          }
        }
      }

      &__action {
        button {
          color: $dark3-color;
        }

        .btn-confirm {
          background-color: $dark-blue3;
          color: $light-color;

          &--buy {
            background-color: $up1-color;
            color: $dark3-color;

            &:hover {
              background-color: $up-color;
            }
          }

          &--sell {
            background-color: $down1-color;
            color: $light-color;
          }

          &:focus {
            background-color: rgba($color: $dark-blue3, $alpha: 0.6);
          }
        }

        .btn-cancel {
          background-color: rgba($color: $dark3-color, $alpha: 0.2);
          color: $dark3-color;

          &:focus {
            background-color: rgba($color: $dark3-color, $alpha: 0.4);
          }
        }
      }
    }
  }
}
