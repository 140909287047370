@import '../../helpers/variables';
@import '../../helpers/animation';

.stock-detail-modal {
  background-color: $dark1-color;
  color: $light-color;
  width: 100%;
  height: calc(100% - 40px);
  border: none;
  max-width: 100vw;
  margin-bottom: 0;
  margin-top: 30px;
  position: absolute;
  bottom: 40px;
  overflow: hidden;
  z-index: 3;
  animation: slideDown 100ms ease;

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-color: $gray-color;

    &:hover {
      background-color: $light-color;
    }

    & + .icon {
      margin-left: 0.75rem;
    }
  }

  .iStar {
    background-color: $light-blue3;
  }

  .iNotification {
    background-color: $light-blue3;
  }

  .iInfor {
    background-color: $light-blue3;
  }

  .iLine {
    background-color: $light-blue3;
  }
}

.stock-detail-modal-backdrop {
  background-color: rgba($color: $dark1-color, $alpha: 0.5);
  height: calc(100% - 40px);
}

.stock-detail-modal-content {
  border-radius: none;
  border: none;
  background-color: black;
  height: 100%;
}

.stock-detail-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  background-color: $dark-color;

  .desc {
    color: $light3-color;
  }
}

.stock-detail-modal-body {
  height: calc(100% - 36px);
  width: 100%;
  color: $light-color;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  // padding-bottom: 40px;
  background-color: black;
  margin-top: -8px;

  .left-panel {
    // flex: 3;
    // width: 25%;
    width: 20%;
    display: flex;
    flex-direction: column;
  }

  .center-panel {
    // flex: 5;
    // width: 42%;
    width: 50%;
    display: flex;
    flex-direction: column;
    // transition: width 0.6s ease-in-out;
  }

  .right-panel {
    // flex: 4;
    // width: 33%;
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .tabs-panel {
      align-items: stretch;
      height: calc(100% - 196px);
    }

    .price-list {
      height: 490px;
    }

    @media (min-height: 900px) {
      .price-list {
        height: 410px;

        &--small {
          height: 180px;
        }
      }
    }
  }
}

// Stock detail tabs

.tabs {
  align-items: stretch;
  height: 100%;

  .card-panel-body {
    height: calc(100% - 22px) !important;
    padding: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .tab:hover {
    color: $ref-color;
  }
}

.symbol-infor {
  height: 200px;
  min-height: auto !important;
  display: flex;
  flex-direction: column;
  color: $light3-color;
  z-index: 2 !important;

  .card-panel {
    min-height: 212px;
    z-index: 1;
    padding: 6px;
    background-color: transparent !important;
    font-size: 13px;
    font-style: normal;
    line-height: 16px;

    display: flex;
    flex-direction: column;
  }

  .icon {
    width: 12px;
    height: 12px;
    object-fit: contain;
    background-color: #6d6e71;
  }

  .card-panel {
    min-height: 212px;
    z-index: 1;
    padding: 6px;
    background-color: transparent !important;
    font-size: 13px;
    font-style: normal;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    min-height: auto;
  }

  .card-panel-body {
    padding: 8px 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    align-items: stretch;
    height: 100%;
  }

  .card-panel-header__left {
    width: 100%;

    .card-panel-header__title {
      width: 100%;
      background-color: $dark-color;
      margin-right: 0px;
    }

    .card-panel-header__input {
      width: 100%;
      padding: 1px 2px;
      background-color: $dark-color;
    }
  }

  .scroll {
    max-height: 200px;
    min-height: 150px;
  }

  .symbol-brand {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;

    &__name {
      color: $light-color;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      margin-right: 4px;
    }

    &__exchange {
      color: $light3-color;
      font-weight: 400;
    }
  }

  .symbol-name {
    margin-bottom: 8px;
    display: block;
  }

  .symbol-price {
    margin-bottom: 8px;

    &--big {
      font-size: 32px;
      font-weight: 500;
      line-height: 44px;
      animation: example 1s infinite;
    }

    span + span {
      margin-left: 12px;
    }

    /* The animation code */
    @keyframes example {
      from {
        color: blue !important;
      }

      to {
        color: yellow !important;
      }
    }
  }

  .symbol-open-price {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
  }

  .progress-bar {
    display: block;
    height: 8px;
    width: 100%;
    background-color: $dark2-color;
    border-radius: 4px;
    margin-top: 4px;
    overflow: false;

    .anchor {
      width: 2px;
      height: 8px;
      background-color: $light1-color;
      margin-left: var(--start);
    }

    .anchor-up {
      width: var(--width);
      height: 8px;
      background-color: $up-color;
      margin-left: var(--start);
    }

    .anchor-down {
      width: var(--width);
      height: 8px;
      background-color: $down-color;
      margin-left: var(--start);
    }

    &::after {
      content: '';
      position: absolute;
      opacity: var(--display, 0);
      left: calc(var(--current) - 4px);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
      background-color: transparent;
    }
  }
}

.center-tabs {
  display: flex;
  min-height: 100%;
  height: 100%;

  .icon {
    width: 14px;
    height: 14px;
    object-fit: contain;
    background-color: $gray-color;
  }

  .iZoomOut,
  .iZoomIn {
    margin: 0 4px;
  }

  .card-panel-body {
    // border: 1px solid #2f3134;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0px !important;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.event-tab {
  height: 100%;

  .scroll {
    height: 100%;
  }

  .events {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &.events {
      margin-top: 8px;
    }

    &__time {
      color: $gray-color;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }

    .event {
      background-color: black;
      min-height: 68px;
      border-radius: 4px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 8px;

      &__title {
        font-weight: 500;
        color: $light1-color;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 6px;
      }

      &__desc {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $light3-color;
      }
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-color: $gray-color;
  }
}

.finance {
  height: 100%;
  padding: 12px;

  .finance-header {
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    align-items: center;

    .btn-secondary {
      background-color: $dark2-color;
      border: 0px;
      color: $light3-color;
      height: 24px;
      font-size: 13px;
      line-height: 20px;
      align-items: center;
      padding: 0px 10px 0px 10px !important;
      margin: 0px;
    }

    .btn-secondary.active {
      background-color: $active-color !important;
      border-color: $active-color !important;
      z-index: 0 !important;
      color: $dark2-color !important;
    }
  }

  .select-button {
    text-align: right;
    display: flex;
  }

  .tabs {
    display: flex;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    float: left;
    width: 400px;

    text-align: center;
    color: $light4-color;
    font-weight: 500;
    font-size: 13px;
    font-style: normal;
    line-height: 16px;

    .tab {
      cursor: pointer;
      padding-bottom: 8px;

      & + .tab {
        margin-left: 1.5rem;
      }

      &.active {
        color: $active-color;
        border-bottom: 1px solid #ffe70b;
        padding-bottom: 8px;
      }
    }
  }

  .tab-panel {
    overflow: hidden;

    .scroll {
      height: 100%;
    }
  }

  .scroll {
    height: 100%;
    padding-bottom: 8px;

    .card {
      border: none;

      .card-header {
        background-color: transparent;
        padding: 0;
        border-bottom: 0;
      }

      .card-body {
        padding: 0;

        .table-borderless th {
          border-top: 1px solid #c4c9d0;
        }
      }
    }
  }
}

.news-tab {
  height: 100%;

  .news {
    min-height: 74px;

    &__left {
      height: 48px;
      width: 64px;
      min-width: 64px;
      border-radius: 4px;
      margin-right: 12px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__title {
      font-weight: 500;
      font-size: 13px;
      font-style: normal;
      line-height: 20px;
      color: $light1-color;
    }

    &__time {
      color: $gray-color;
      font-weight: 500;
      font-style: normal;
      font-size: 13px;
      line-height: 20px;
    }

    .scroll {
      height: 100%;
    }
  }
}

.profile-tab {
  height: calc(100%);
  padding-bottom: 8px;
  line-height: 20px;

  .scroll {
    height: 100% !important;
  }

  .profile-title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &__thumbnail {
      width: 64px !important;
      height: 64px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 56px auto;
      background-color: $light-color;
      // margin-right: 12px;
    }

    &__name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $light-color;
      margin-left: 12px;
      width: calc(100% - 76px);
    }
  }

  .profile-desc {
    color: $light4-color;
    margin-top: 1rem;

    .icon {
      background-color: $dark3-color;
      margin-right: 8px;
    }
  }

  .profile-intro {
    color: $light2-color;

    p {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-color: $gray-color;

    &:hover {
      background-color: $light-color;
    }
  }

  .mbadge {
    display: table;
    height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
    margin-top: 0.5rem;
    cursor: default;
  }

  .mbadge-primary {
    color: $light-blue3;
    border: 1px solid $light-blue3;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;

    &:hover {
      color: $white-color;
      border: 1px solid $white-color;
      background-color: $light-blue3;
    }
  }

  .listing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $dark3-color;

    &__metric {
      margin-right: 1rem;
      flex: 2;
      color: $light3-color;
      display: flex;
      align-items: center;
    }

    &__name {
      text-align: left;
      flex: 4;
      color: $light1-color;
    }

    &__color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 4px;
    }

    &__percent {
      color: $light-color;
    }

    &__head {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $light1-color;
    }
  }

  .cw-profile {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__items {
      display: flex;
      flex-direction: column;
    }

    &__item {
      display: flex;
      align-items: center;
      height: 32px;
      border-bottom: 1px solid $dark2-color;
    }

    &__chart {
      .highcharts-legend .highcharts-graph,
      .highcharts-legend .highcharts-point {
        display: none;
      }
    }
  }
}

.light {
  .profile-tab {
    .cw-profile {
      &__item {
        border-bottom: 1px solid $light-color;
      }
    }
  }
}
