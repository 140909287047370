.register-partnership {
  &__body {
    display: flex;
    justify-content: center;
  }

  .partnership-card {
    background-color: transparent;
    padding: 24px 12px;

    &__header {
      background-color: $dark2-color;
      border-radius: 4px;
      gap: 4px;
      padding: 12px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 324px;
    }

    &__body {
      margin-top: 12px;

      .register-form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .order-text {
          background-color: $dark-color;
          border-radius: 4px;
          height: 44px;
          display: flex;
          align-items: center;
          padding: 12px;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;

          & + .order-text {
            // margin-top: 12px;
          }

          input {
            height: 36px;
            padding: 12px;
            color: $light-color;
            background-color: $dark-color;
            border: none;
            outline: none;
            box-shadow: none;
            width: 100%;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            background-color: $dark-color !important;
            color: $light-color !important;
            -webkit-box-shadow: 0 0 0 30px $dark-color inset !important;
            -webkit-text-fill-color: $light-color !important;
          }
        }

        .btn {
          border-radius: 4px;
          height: 40px;
          padding: 10px 8px;
        }
      }
    }
  }
}

.light {
  .partnership-card {
    &__header {
      background-color: $light-color;
    }

    &__body {
      .register-form {
        .order-text {
          background-color: $light-color;
          input {
            color: $dark-color;
            background-color: $light-color;
          }
        }
      }
    }
  }
}

.register-partnership-success {
  .btn {
    height: 32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    width: auto !important;
    padding: 6px 16px !important;
    border-radius: 4px !important;
    margin-top: 32px;
  }
}
