@import '../../../assets/scss/helpers/variables';

.settings {
  .btn-reset {
    font-size: 12px;
    padding: 2px 8px;
    background-color: none;
    border: 1px solid $light-blue3;
    color: $light-blue3;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    box-shadow: none;

    &:hover {
      background-color: $light-blue3;
      color: $light-color;
    }
  }

  table {
    border: none;
  }
  tr {
    border-top: 1px solid $dark2-color;
  }

  .account-item {
    line-height: 32px;
  }
}
