@import '../../assets/scss/helpers/variables';

.floating-label {
  position: relative;
  margin-bottom: 20px;

  &__label {
    color: $light-color;
    font-size: 13px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 11px;
    padding: 0 5px;
    background: transparent;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  &__input {
    font-size: 12px;
    display: block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    background: black;
    color: $light-color;
    border: none;
    box-sizing: border-box;

    &:focus {
      outline: none;

      & ~ .floating-label__label {
        top: 0px;
        font-size: 13px;
      }
    }

    &:not(:placeholder-shown) {
      & ~ .floating-label__label {
        top: -18px;
        font-size: 13px;
        border: 1px solid red;
      }
    }
  }
}

.sit {
  &-input {
    min-height: 24px;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      height: 100%;
      min-height: 24px;
      border: none;
      border-radius: 4px;
      background-color: rgba($color: $light-color, $alpha: 0.2) !important;
      outline: none;
      box-shadow: none;
      color: $light-color;
      padding-left: 4px;
      padding-right: 60px;
      position: relative;

      &::placeholder {
        color: $light3-color;
      }

      &.hasExtend {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__value {
      position: relative;
      border-radius: 4px;

      &.input-error {
        box-shadow: 0 0 5px rgba($color: $down-color, $alpha: 1);
      }
    }

    &__error {
      margin-top: 4px;
      font-size: 11px;
    }

    &__overlay {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      color: $light-color;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .extend-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
