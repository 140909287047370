.margin-extending-modal {
  .popup-body {
    font-size: 13px;
    line-height: 20px;
    .subAccount {
      display: flex;
      gap: 4px;
      font-style: normal;
      font-weight: 400;
      align-items: center;
      &--label {
        width: 144px;
      }
      &--value {
        width: 100%;
        padding: 8px;
        background-color: $dark-color;
        border-radius: 4px;
      }
    }
    table {
      td,
      th {
        padding: 6px 8px !important;
      }
    }

    .actions {
      display: flex;
      gap: 8px;
      .btn {
        padding: 6px 16px;
      }
    }
  }
}
