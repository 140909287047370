.pinefolio-buy {
  @extend %text;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  // min-height: 712px;
  .order-quantity {
    margin-left: -8px;

    input {
      height: 28px;
      background-color: $dark-color;
      border: none;
      outline: none;
      border-radius: 4px;
      color: $light-color;
      padding: 0 8px;
      width: 50%;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__body {
    display: flex;

    .order-main {
      width: 100%;
    }

    .order-quantity {
      display: flex;
      gap: 8px;
    }

    .product-type {
      border-bottom: 1px dashed $dark3-color;
      width: 100%;
    }
  }

  &__action {
    display: flex;
    padding: 12px;
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 50%;

    &:hover {
      background-color: $light-color;

      .icon {
        background-color: $dark-color;
      }
    }
  }
}
